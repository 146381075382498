import "./style.css";
import Lottie from "lottie-react";
import loader from "../../assets/loader.json";

function Loader({ width, height }) {
  return (
    <Lottie
      style={{ width, height }}
      className="loader"
      animationData={loader}
    />
  );
}

export default Loader;
