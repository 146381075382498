import React, { memo } from "react";
import BracketItem from "../BracketItem";
import { useDrop } from "react-dnd";
import { patchPoolMember } from "../../../hook/Requests";
import { useLocation } from "react-router-dom";
import qs from "qs";

const BracketsSingleGroup = ({ setIsModalOpen, setRefetch, brackets }) => {
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const step_1 =
    brackets?.pools?.filter((item) => item?.step === "step_1") || [];
  const step_2 =
    brackets?.pools?.filter((item) => item?.step === "step_2") || [];
  const step_3 =
    brackets?.pools?.filter((item) => item?.step === "step_3") || [];
  const step_4 =
    brackets?.pools?.filter((item) => item?.step === "step_4") || [];
  const step_5 =
    brackets?.pools?.filter((item) => item?.step === "step_5") || [];
  const step_final =
    brackets?.pools?.filter((item) => item?.step === "step_final") || [];

  const handleDropWinMember = (poolId, playerIndex) => {
    const data1 = {
      player1: Number(query?.draggedPlayerId),
    };
    const data2 = {
      player2: Number(query?.draggedPlayerId),
    };
    const addMemberCompetition = patchPoolMember(
      playerIndex == 1 ? data1 : data2,
      poolId
    );
    addMemberCompetition
      .then((res) => {
        setRefetch((prev) => !prev);
        setIsModalOpen("");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const handleDropFinalWinner = (poolId) => {
    const addMemberCompetition = patchPoolMember(
      { winner: Number(query?.draggedPlayerId) },
      poolId
    );
    addMemberCompetition
      .then((res) => {
        setRefetch((prev) => !prev);
        setIsModalOpen("");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const robinClassName =
    brackets?.group?.includes("robin") ||
    brackets?.group?.includes("Robin") ||
    brackets?.group?.includes("Repachage") ||
    brackets?.group?.includes("repachage")
      ? "robin"
      : "";

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <p style={{ textTransform: "capitalize" }}>Group: {brackets?.group}</p>
      </div>
      <div className="tournament-brackets">
        {step_1?.length !== 0 && (
          <ul className="bracket bracket-1">
            {step_1?.map((item) => {
              return (
                <li className={`team-item ${robinClassName}`} key={item.id}>
                  <BracketItem
                    setRefetch={setRefetch}
                    step={item?.step}
                    poolId={item?.id}
                    playerIndex={1}
                    data={item?.player1}
                    setIsModalOpen={setIsModalOpen}
                  />
                  <BracketItem
                    setRefetch={setRefetch}
                    step={item?.step}
                    poolId={item?.id}
                    playerIndex={2}
                    data={item?.player2}
                    setIsModalOpen={setIsModalOpen}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {/*//This is for Repachage  Group*/}
        {step_2?.length !== 0 && brackets?.group === "Repachage" && (
          <>
            <ul className={"bracket bracket-2"}>
              {step_2?.map((item) => (
                <li className="team-item" key={item.id}>
                  <BracketItem
                    setRefetch={setRefetch}
                    playerIndex={1}
                    poolId={item?.id}
                    handleDropItem={handleDropWinMember}
                    data={item?.player1}
                    setIsModalOpen={setIsModalOpen}
                  />
                  <BracketItem
                    setRefetch={setRefetch}
                    step={item?.step}
                    poolId={item?.id}
                    playerIndex={2}
                    data={item?.player2}
                    setIsModalOpen={setIsModalOpen}
                  />
                </li>
              ))}
            </ul>
            <ul className="bracket bracket-7">
              {step_2?.map((item) => (
                <li className="team-item" key={item.id}>
                  <BracketItem
                    setRefetch={setRefetch}
                    data={item?.winner}
                    poolId={item?.id}
                    handleDropItem={handleDropFinalWinner}
                    playerIndex={3}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {/*//This is for Repachage Group*/}
        {step_2?.length !== 0 && (
          <ul className={"bracket bracket-2"}>
            {step_2?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  playerIndex={1}
                  poolId={item?.id}
                  handleDropItem={handleDropWinMember}
                  data={item?.player1}
                  setIsModalOpen={setIsModalOpen}
                />
                <BracketItem
                  setRefetch={setRefetch}
                  playerIndex={2}
                  poolId={item?.id}
                  handleDropItem={handleDropWinMember}
                  data={item?.player2}
                  setIsModalOpen={setIsModalOpen}
                />
              </li>
            ))}
          </ul>
        )}
        {step_3?.length !== 0 && (
          <ul className={"bracket bracket-2"}>
            {step_3?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={1}
                  poolId={item?.id}
                  data={item?.player1}
                  setIsModalOpen={setIsModalOpen}
                />
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={2}
                  poolId={item?.id}
                  data={item?.player2}
                  setIsModalOpen={setIsModalOpen}
                />
              </li>
            ))}
          </ul>
        )}
        {step_4?.length !== 0 && (
          <ul className="bracket bracket-4">
            {step_4?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={1}
                  poolId={item?.id}
                  data={item.player1}
                  setIsModalOpen={setIsModalOpen}
                />
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={2}
                  poolId={item?.id}
                  data={item.player2}
                  setIsModalOpen={setIsModalOpen}
                />
              </li>
            ))}
          </ul>
        )}
        {step_5?.length !== 0 && (
          <ul className="bracket bracket-5">
            {step_5?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={1}
                  poolId={item?.id}
                  data={item.player1}
                  setIsModalOpen={setIsModalOpen}
                />
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={2}
                  poolId={item?.id}
                  data={item.player2}
                  setIsModalOpen={setIsModalOpen}
                />
              </li>
            ))}
          </ul>
        )}
        {step_final?.length !== 0 && (
          <ul className="bracket bracket-6">
            {step_final?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  playerIndex={1}
                  poolId={item?.id}
                  step={step_1?.[0]?.step ? null : item?.step}
                  data={item.player1}
                  setIsModalOpen={setIsModalOpen}
                />
                <BracketItem
                  setRefetch={setRefetch}
                  handleDropItem={handleDropWinMember}
                  data={item.player2}
                  step={step_1?.[0]?.step ? null : item?.step}
                  playerIndex={2}
                  poolId={item?.id}
                  setIsModalOpen={setIsModalOpen}
                />
              </li>
            ))}
          </ul>
        )}
        {step_1?.length === 3 && (
          <ul className="bracket bracket-7">
            {step_1?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  data={item?.winner}
                  poolId={item?.id}
                  handleDropItem={handleDropFinalWinner}
                  playerIndex={3}
                />
              </li>
            ))}
          </ul>
        )}
        {(brackets?.group?.includes("robin") ||
          brackets?.group?.includes("Robin") ||
          brackets?.group?.includes("Repachage") ||
          brackets?.group?.includes("repachage")) &&
          step_1.length === 1 && (
            <ul className="bracket bracket-7">
              {step_1?.map((item) => (
                <li className={`team-item ${robinClassName}`} key={item.id}>
                  <BracketItem
                    setRefetch={setRefetch}
                    data={item?.winner}
                    poolId={item?.id}
                    handleDropItem={handleDropFinalWinner}
                    playerIndex={3}
                  />
                </li>
              ))}
            </ul>
          )}
        {step_final?.length !== 0 && (
          <ul className="bracket bracket-7">
            {step_final?.map((item) => (
              <li className="team-item" key={item.id}>
                <BracketItem
                  setRefetch={setRefetch}
                  data={item?.winner}
                  poolId={item?.id}
                  handleDropItem={handleDropFinalWinner}
                  playerIndex={3}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default memo(BracketsSingleGroup);
