import React, { useEffect } from "react";
import classes from "./style.module.css";
import { useNavigate } from "react-router-dom";

function NotAuthPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("isLogin") == "federationadmin") {
      navigate("all-members");
    }
  }, []);

  return (
    <div className={classes.not_found}>
      <div>
        {/* <h1>You do not have permission for this page</h1> */}
        <h4>You do not have permission for this page</h4>
      </div>
    </div>
  );
}

export default NotAuthPage;
