import React from "react";
import classes from "./style.module.css";
import { NationalTeamUserInfo } from "container";
import { ReactComponent as Icon1 } from "assets/images/icons/ic_Achievements Outline.svg";
import { ReactComponent as Icon2 } from "assets/images/icons/ic_like.svg";
import { ReactComponent as Icon3 } from "assets/images/icons/Global.svg";
import { useNationalTeamMember } from "hook/query";
import { Link, useParams } from "react-router-dom";
import FileSaver from "file-saver";

function NationalTeamDetail() {
  const { id } = useParams();
  const whois = localStorage.getItem("isLogin");
  const dataUser = useNationalTeamMember({
    enabled: true,
    id: id,
  });

  const handleDownload = () => {
    FileSaver.saveAs(
      `${dataUser?.data?.document}`,
      `${dataUser?.data?.document}`
    );
  };

  return (
    <div className={classes.national_team__detail}>
      <NationalTeamUserInfo data={dataUser.data} />
      <div className={classes.details}>
        <div className={classes.document} onClick={handleDownload}>
          <p>
            Document <br /> Download
          </p>
        </div>
        <div className={classes.flex}>
          <div className={classes.box}>
            <h5>IJF ID expired date</h5>
            <p>{dataUser?.data?.ijf_id_expired_date}</p>
          </div>
          <div className={classes.box}>
            <h5>Passport given date</h5>
            <p>{dataUser?.data?.passport_given}</p>
          </div>
          <div className={classes.box}>
            <h5>Passport series</h5>
            <p>{dataUser?.data?.passport_series}</p>
          </div>
          <div className={classes.box}>
            <h5>Passport expired date</h5>
            <p>{dataUser?.data?.passport_expired}</p>
          </div>
        </div>
      </div>
      <div className={classes.cards}>
        {whois == "trophies_manager" || whois == "federationadmin" ? (
          <div className={classes.card}>
            <p>
              <Icon1 />
              Trophies and results
            </p>
            <Link to={"trophies"}>
              <button>View</button>
            </Link>
          </div>
        ) : null}

        {whois == "doctor" || whois == "federationadmin" ? (
          <div className={classes.card}>
            <p>
              <Icon2 />
              Medical certificate
            </p>
            <Link to={"medical-certificate"}>
              <button>View</button>
            </Link>
          </div>
        ) : null}

        {whois == "visa_manager" || whois == "federationadmin" ? (
          <div className={classes.card}>
            <p>
              <Icon3 />
              Visa
            </p>
            <Link to={"visa-datas"}>
              <button>View</button>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default NationalTeamDetail;
