import classes from "./style.module.css";
import { CButton, CPopup } from "components";

function PopupPayment({ close, title, onClick }) {
  return (
    <CPopup onClick={close} title={title}>
      <div className={classes.payment}>
        <h4>Are you sure this order has been payed?</h4>
        <div className={classes.btn}>
          <CButton
            type="submit"
            className={"createBtn"}
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#0062FF"}
            fontSize={"12px"}
            lineHeight={"18px"}
            fontWeight={"600"}
            color={"#fAFAFB"}
            border={"1px solid #0062FF"}
            padding={"9px 16px"}
            borderRadius={"10px"}
            margin={"0 0 0 10px"}
            onClick={onClick}
          >
            Sure
          </CButton>
        </div>
      </div>
    </CPopup>
  );
}

export default PopupPayment;
