import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetContributionsQuery = ({ enabled }) => {
  return useQuery(
    ["get-contributions"],
    async () => {
      const { data } = await request.get(`/contribution/`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
