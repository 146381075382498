import "../bracket.css";
import { useEffect, useState } from "react";
import BracketModal from "../BracketModal";
import { getGridData, getGridResult } from "../../../hook/Requests";
import { useSearchParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import BracketsSingleGroup from "./BracketsSingleGroup";
import BracketTable from "./BracketTable";
import BracketModalResult from "./BracketModalResult";
import RobinRoundBracket from "./RobinRoundBracket";

function Bracket() {
  const [brackets, setBrackets] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState("");
  const [isModalOpenResult, setIsModalOpenResult] = useState(false);
  const [place, setPlace] = useState();
  const [resultMember, setResultMember] = useState([]);

  useEffect(() => {
    setLoading(true);
    const gridData = getGridData(searchParams);
    gridData
      .then((res) => {
        setLoading(false);
        setBrackets(res?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [
    refetch,
    searchParams.get("gender"),
    searchParams.get("competition_id"),
    searchParams.get("weight_id"),
    isModalOpen,
  ]);

  useEffect(() => {
    setLoading(true);
    const gridData = getGridResult(searchParams);
    gridData
      .then((res) => {
        setResultMember(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [
    refetch,
    searchParams.get("gender"),
    searchParams.get("competition_id"),
    searchParams.get("weight_id"),
    isModalOpenResult,
  ]);

  return (
    <div className="tournament-container">
      {brackets.map((item, index) => {
        return (
          <BracketsSingleGroup
            setRefetch={setRefetch}
            setIsModalOpen={setIsModalOpen}
            brackets={item}
            key={index}
          />
        );
      })}
      {isModalOpen && (
        <BracketModal
          setRefetch={setRefetch}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <div>
        <BracketTable
          resultMember={resultMember}
          isModalOpen={isModalOpenResult}
          setIsModalOpen={setIsModalOpenResult}
          setPlace={setPlace}
        />
        {isModalOpenResult ? (
          <BracketModalResult
            setRefetch={setRefetch}
            isModalOpen={isModalOpenResult}
            setIsModalOpen={setIsModalOpenResult}
            setPlace={setPlace}
            place={place}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Bracket;
