import React, { useState } from "react";
import classes from "./style.module.css";
import noImage from "assets/images/notfound/nopic.jpg";
import { ReactComponent as Icon1 } from "assets/images/icons/iconspace_Search_Zoom_25px Copy.svg";
import { useGetTrophiesDocumentDownloadQuery } from "hook/query";
import FileSaver from "file-saver";
import { CButton, CLoader } from "components";

function NationalTeamUserInfo({ data, title, location = false }) {
  const [active, setActive] = useState(false);
  const getFileDowload = useGetTrophiesDocumentDownloadQuery({
    enabled: true,
    id: data?.id,
  });

  const handleDownload = () => {
    setActive(true);
    function setLoader() {
      setActive(false);
    }

    setTimeout(setLoader, 2000);
    FileSaver.saveAs(
      `${getFileDowload?.data?.pdf_document}`,
      "pdf_document.pdf"
    );
  };

  return (
    <>
      <div className={classes.header}>
        <p>
          <span>National team</span> &gt; {data?.first_name} {data?.last_name}{" "}
          {!!title ? <>&gt; {title}</> : null}
        </p>
      </div>
      <div className={classes.user__info}>
        <div className={classes.flex}>
          <img src={!!data?.image ? data?.image : noImage} alt="" />
          <div className={classes.texts}>
            <h4>
              {data?.first_name} {data?.last_name}
            </h4>
            <div className={classes.flex}>
              <div className={classes.box}>
                <p>
                  Age: <span>26 years old</span>
                </p>
              </div>
              <div className={classes.box}>
                <p>
                  IJF ID: <span>{data?.ijf_id}</span>
                </p>
              </div>
              {location ? (
                <CButton
                  type="button"
                  onClick={handleDownload}
                  background={active ? "#ffffff" : "#0062FF"}
                >
                  {active ? (
                    <CLoader width={"80px"} height={"80px"} />
                  ) : (
                    <>
                      <Icon1 /> Download
                    </>
                  )}
                </CButton>
              ) : null}
            </div>
          </div>
        </div>

        <h3>{data?.weight} kg</h3>
      </div>
    </>
  );
}

export default NationalTeamUserInfo;
