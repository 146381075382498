import "./bracjettable.css";
import React from "react";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

function BracketTable({ resultMember, isModalOpen, setIsModalOpen, setPlace }) {
  return (
    <div className="bracket__table">
      <ul>
        <h5>Final results</h5>
        {resultMember?.map((result, index) => (
          <li key={index}>
            <div style={{ display: "flex" }}>
              <div className="number">{result.place}</div>
              {!!result.player ? (
                <div>
                  {result?.player?.first_name}, {result?.player?.last_name}
                </div>
              ) : null}
            </div>

            {!!result.player ? (
              <p>
                <EditOutlined
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    setPlace(result.id);
                  }}
                />
              </p>
            ) : (
              <p>
                <PlusCircleOutlined
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    setPlace(result.id);
                  }}
                />
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BracketTable;
