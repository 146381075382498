import { useMutation, useQueryClient } from "react-query";
import { request } from "services/api";

export const useDeleteRegisteredMembersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ ids, id }) => {
      return request.post(`/delete/registered/members/?id=${id}`, {
        id_list: ids,
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries("registered-members");
      },
    }
  );
};
