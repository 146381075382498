import { useMutation, useQueryClient } from "react-query";
import { request } from "services/api";

export const useDeleteMembersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ ids }) => {
      return request.delete(`/delete/members/`, { data: { ids } });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries("members");
      },
    }
  );
};
