import moment from "moment";
import classes from "./style.module.css";
import { Col, Row, DatePicker } from "antd";
import { useForm, Controller } from "react-hook-form";
import { CButton, CDatePicker, CInput, CPopup, CSelect } from "components";
import { usePostMemberMutation } from "hook/mutation";

function CreateHosts({ open, setOpen, memberList }) {
  const postMemberMutation = usePostMemberMutation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      phone_number: "+998",
      birth_date: "",
      role: "other",
    },
  });

  const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
  };

  const onSubmit = (e) => {
    const postMember = postMemberMutation.mutateAsync(e);
    postMember
      .then((res) => {
        reset();
        setOpen("");
        removeHiddenWindow();
        memberList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (open !== "others") return null;

  return (
    <CPopup
      onClick={() => {
        setOpen("");
        reset();
      }}
      title={"Create Hosts"}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.host}>
        <Row gutter={[40, 8]}>
          <Col span={11}>
            <CInput
              type="text"
              label="Last name"
              name="last_name"
              register={register}
              errors={errors?.last_name}
              required
            />
            <CInput
              type="text"
              label="First name"
              name="first_name"
              register={register}
              errors={errors?.first_name}
              required
            />
            <CInput
              type="text"
              label="Middle name"
              name="middle_name"
              register={register}
              errors={errors?.middle_name}
              required
            />
            <CDatePicker
              label={"Birth date"}
              control={control}
              name="birth_date"
              placeholder=""
              required={true}
              errors={errors.birth_date}
              value={
                watch().birth_date != ""
                  ? moment(watch().birth_date, `YYYY-MM-DD`)
                  : ""
              }
            />
          </Col>
          <Col style={{ paddingTop: "20px" }} span={13}>
            <CSelect
              label={"Gender"}
              name="gender"
              items={[
                { province: "Male", id: "male" },
                { province: "Female", id: "female" },
              ]}
              control={control}
              error={errors.gender}
              required={true}
            />
            <CInput
              type="tel"
              label="Contacts"
              name="phone_number"
              register={register}
              errors={errors?.phone_number}
              required
              pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
              maxLength={13}
              minLength={13}
            />
            <CInput
              type="text"
              label="Role Info"
              name="other_role_data"
              register={register}
              errors={errors?.other_role_data}
              required
            />
            <div className={classes.flex}>
              <CButton
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"none"}
                fontSize={"14px"}
                lineHeight={"18px"}
                fontWeight={"600"}
                color={"#0062FF"}
                border={"1px solid #0062FF"}
                padding={"9px 30px"}
                borderRadius={"10px"}
                onClick={() => reset()}
              >
                Clear
              </CButton>
              <CButton
                type={"submit"}
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 30px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
                disabled={postMemberMutation.isLoading}
              >
                Create
              </CButton>
            </div>
          </Col>
        </Row>
      </form>
    </CPopup>
  );
}

export default CreateHosts;
