import { saveAs } from "file-saver";
import { createSlice } from "@reduxjs/toolkit";
import {
  createCompetition,
  dowloadCompetition,
  editCompetition,
  getCompetition,
  getCompetitions,
  getRegionCompetition,
  getRegistredMember,
  getWeightsCompetition,
  getWeightsCompetitionFemale,
  getWeightsCompetitionMale,
} from "./fetcher";

const initialState = {
  id: "",
  status: null,
  error: null,
  maleWeights: [],
  femaleWeights: [],
  competitions: [],
  competitionDetail: {},
  floor: "male",
  image: {},
  quotas: [],
  points: [
    {
      place: "1",
      point: "",
    },
    {
      place: "2",
      point: "",
    },
    {
      place: "3",
      point: "",
    },
    {
      place: "3",
      point: "",
    },
    {
      place: "5",
      point: "",
    },
    {
      place: "5",
      point: "",
    },
    {
      place: "7",
      point: "",
    },
    {
      place: "for_participating",
      point: "",
    },
  ],
  title: "",
  short_name: "",
  location: "",
  start_day: "",
  end_day: "",
  registering: "",
  for_junior_male: false,
  for_cadet_male: false,
  for_senior_male: false,
  for_junior_female: false,
  for_cadet_female: false,
  for_senior_female: false,
  region: "",
  weight_category_male: [],
  weight_category_female: [],
  age_from: "",
  age_to: "",
  videos: [
    {
      link: "",
      competition: "",
    },
  ],
  congratulationPopup: false,
  year: null,
  registredMember: [],
  weight: {},
  type: {},
  file: "",
  pointPopup: false,
  weightPopup: false,
  loaderStatus: null,
  getStatus: null,
  quotasState: null,
};

const competitionSlice = createSlice({
  name: "competition",
  initialState,
  reducers: {
    setAgeFromPopup: (state, action) => {
      state.age_from = action.payload;
    },
    setAgeToPopup: (state, action) => {
      state.age_to = action.payload;
    },
    setWeightPopup: (state, action) => {
      state.weightPopup = action.payload;
    },
    setPointPopup: (state, action) => {
      state.pointPopup = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setShortName: (state, action) => {
      state.short_name = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setStartDay: (state, action) => {
      state.start_day = action.payload;
    },
    setEndDay: (state, action) => {
      state.end_day = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setQuotas: (state, action) => {
      state.quotas = JSON.parse(action.payload);
    },
    setAddSelectRegion: (state, action) => {
      state.selectRegion.push(action.payload);
    },
    setDeleteSelectRegion: (state, action) => {
      const deleteState = state.selectRegion.filter(
        (currentValue) => currentValue.id != action.payload
      );
      state.selectRegion = deleteState;
    },
    setForJuniorMale: (state, action) => {
      state.for_junior_male = action.payload;
    },
    setForCadetMale: (state, action) => {
      state.for_cadet_male = action.payload;
    },
    setForSeniorMale: (state, action) => {
      state.for_senior_male = action.payload;
    },
    setForJuniorFemale: (state, action) => {
      state.for_junior_female = action.payload;
    },
    setForCadetFemale: (state, action) => {
      state.for_cadet_female = action.payload;
    },
    setForSeniorFemale: (state, action) => {
      state.for_senior_female = action.payload;
    },
    setPoints: (state, action) => {
      state.points = JSON.parse(action.payload);
    },
    setVideo: (state, action) => {
      state.videos = JSON.parse(action.payload);
    },
    setWeightMale: (state, action) => {
      state.weight_category_male = action.payload;
    },
    removeWeightMale: (state, action) => {
      let newStateWeight = state.maleWeights.filter(
        (weigthMale) => weigthMale.id != action.payload
      );
      state.maleWeights = [...newStateWeight];
    },
    removeWeightFemale: (state, action) => {
      let newStateWeight = state.femaleWeights.filter(
        (weigthFemale) => weigthFemale.id != action.payload
      );
      state.femaleWeights = [...newStateWeight];
    },
    setWeightFemale: (state, action) => {
      state.weight_category_female = action.payload;
    },
    setCongratulationPopup: (state, action) => {
      state.congratulationPopup = action.payload;
    },
    setFloor: (state, action) => {
      state.floor = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setWeight: (state, action) => {
      state.weight = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegionCompetition.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getRegionCompetition.fulfilled, (state, action) => {
      state.selectRegion = action.payload;
      state.status = "success";
    });

    builder.addCase(getRegionCompetition.rejected, (state, action) => {
      state.status = "error";
      state.selectRegion = [];
    });
    builder.addCase(createCompetition.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(createCompetition.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.image = "";
      state.image = {};
      state.quotas = [
        {
          quota_for_male: "",
          quota_for_female: "",
          region: "",
          province: "",
        },
      ];
      state.points = [
        {
          place: "",
          point: "",
        },
      ];
      state.age_from = "";
      state.age_to = "";
      state.title = "";
      state.short_name = "";
      state.location = "";
      state.start_day = "";
      state.end_day = "";
      state.registering = "";
      state.for_junior_male = false;
      state.for_cadet_male = false;
      state.for_senior_male = false;
      state.for_junior_female = false;
      state.for_cadet_female = false;
      state.for_senior_female = false;
      state.region = "";
      state.weight_category_male = [];
      state.weight_category_female = [];
      state.videos = [
        {
          link: "",
          competition: "",
        },
      ];
      state.status = "success";
    });

    builder.addCase(createCompetition.rejected, (state, action) => {
      state.competitionDetail = {};
      state.status = "error";
    });

    builder.addCase(getCompetition.pending, (state, action) => {
      state.getStatus = "loading";
    });

    builder.addCase(getCompetition.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.competitionDetail = action.payload;
      let newQuotes = action.payload.quotas?.map((obj) => {
        return { ...obj, region: obj.region_id, region_id: obj.region };
      });
      state.quotasState = newQuotes;
      state.maleWeights = action.payload.weight_category_male;
      state.femaleWeights = action.payload.weight_category_female;

      action.payload.weight_category_male.map((weight) => {
        state.weight_category_male.push(weight.id);
      });

      action.payload.weight_category_female.map((weight) => {
        state.weight_category_female.push(weight.id);
      });
      state.getStatus = "success";
    });

    builder.addCase(getCompetition.rejected, (state, action) => {
      state.competitionDetail = {};
      state.getStatus = "error";
    });
    builder.addCase(getCompetitions.pending, (state, action) => {
      state.competitions = [];
      state.status = "loading";
    });

    builder.addCase(getCompetitions.fulfilled, (state, action) => {
      state.competitions = action.payload;
      state.status = "success";
    });

    builder.addCase(getCompetitions.rejected, (state, action) => {
      state.competitions = [];
      state.status = "error";
    });

    builder.addCase(getRegistredMember.pending, (state, action) => {
      state.competitions = [];
      state.status = "loading";
    });

    builder.addCase(getRegistredMember.fulfilled, (state, action) => {
      state.registredMember = action.payload;
      state.status = "success";
    });

    builder.addCase(getRegistredMember.rejected, (state, action) => {
      state.competitions = [];
      state.status = "error";
    });

    builder.addCase(dowloadCompetition.pending, (state, action) => {
      state.loaderStatus = "loading";
    });

    builder.addCase(dowloadCompetition.fulfilled, (state, action) => {
      state.file = action.payload.file;
      saveAs(
        action.payload.file,
        `competition_${state.competitionDetail.short_name}_names.txt`
      );
      state.loaderStatus = "success";
    });

    builder.addCase(dowloadCompetition.rejected, (state, action) => {
      state.loaderStatus = "error";
    });

    builder.addCase(getWeightsCompetition.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getWeightsCompetition.fulfilled, (state, action) => {
      state.femaleWeights = action.payload;
      state.maleWeights = action.payload;
      action.payload.map((weight) => {
        state.weight_category_male.push(weight.id);
        state.weight_category_female.push(weight.id);
      });
      state.status = "success";
    });

    builder.addCase(getWeightsCompetition.rejected, (state, action) => {
      state.status = "error";
      state.femaleWeights = [];
      state.maleWeights = [];
      state.weight_category_male = [];
      state.weight_category_female = [];
    });
    builder.addCase(getWeightsCompetitionMale.pending, (state, action) => {});

    builder.addCase(getWeightsCompetitionMale.fulfilled, (state, action) => {
      let newWeights = state.maleWeights.filter(
        (maleW) => maleW.id != action.payload[0].id
      );
      state.maleWeights = [...newWeights, action.payload[0]];
      action.payload.map((weight) => {
        state.weight_category_male.push(weight.id);
      });
    });

    builder.addCase(getWeightsCompetitionMale.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(getWeightsCompetitionFemale.pending, (state, action) => {});

    builder.addCase(getWeightsCompetitionFemale.fulfilled, (state, action) => {
      let newWeights = state.femaleWeights.filter(
        (maleW) => maleW.id != action.payload[0].id
      );
      state.femaleWeights = [...newWeights, action.payload[0]];
      action.payload.map((weight) => {
        state.weight_category_female.push(weight.id);
      });
    });

    builder.addCase(getWeightsCompetitionFemale.rejected, (state, action) => {
      state.status = "error";
    });

    builder.addCase(editCompetition.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(editCompetition.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.image = "";
      state.image = {};
      state.quotas = [
        {
          quota_for_male: "",
          quota_for_female: "",
          region: "",
          province: "",
        },
      ];
      state.points = [
        {
          place: "",
          point: "",
        },
      ];
      state.title = "";
      state.short_name = "";
      state.location = "";
      state.start_day = "";
      state.end_day = "";
      state.registering = "";
      state.age_from = "";
      state.age_to = "";
      state.for_junior_male = false;
      state.for_cadet_male = false;
      state.for_senior_male = false;
      state.for_junior_female = false;
      state.for_cadet_female = false;
      state.for_senior_female = false;
      state.region = "";
      state.weight_category_male = [];
      state.weight_category_female = [];
      state.videos = [
        {
          link: "",
          competition: "",
        },
      ];
      state.status = "success";
    });

    builder.addCase(editCompetition.rejected, (state, action) => {
      state.competitionDetail = {};
      state.status = "error";
    });
  },
});

export const {
  setAgeFromPopup,
  setAgeToPopup,
  removeWeightFemale,
  removeWeightMale,
  setPointPopup,
  setType,
  setWeight,
  setFloor,
  setCongratulationPopup,
  setWeightFemale,
  setWeightMale,
  setVideo,
  setPoints,
  setImage,
  setTitle,
  setShortName,
  setLocation,
  setStartDay,
  setEndDay,
  setRegion,
  setQuotas,
  setAddSelectRegion,
  setDeleteSelectRegion,
  setForSeniorFemale,
  setForCadetFemale,
  setForJuniorFemale,
  setForSeniorMale,
  setForCadetMale,
  setForJuniorMale,
  setYear,
} = competitionSlice.actions;
export default competitionSlice.reducer;
