import { useEffect, useState } from "react";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CButton } from "components";
import { Category, MainInfo, Points, Quotas } from "container";
import { usePatchCompetitionMutation } from "hook/mutation";
import { useCompetitionQuery } from "hook/query";
import toast from "react-hot-toast";

function CompetitionEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  // POINTS
  const [points, setPoints] = useState([
    {
      place: "1",
      point: "",
    },
    {
      place: "2",
      point: "",
    },
    {
      place: "3",
      point: "",
    },
    {
      place: "3",
      point: "",
    },
    {
      place: "5",
      point: "",
    },
    {
      place: "5",
      point: "",
    },
    {
      place: "7",
      point: "",
    },
    {
      place: "for_participating",
      point: "",
    },
  ]);
  // QUOTAS
  const [quotas, setQuotas] = useState([]);
  // MAIN INFO
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [startRegisterDay, setStartRegisterDay] = useState("");
  const [endRegisterDay, setEndRegisterDay] = useState("");
  // CATEGORY
  const [ageTo, setAgeTo] = useState("");
  const [ageFrom, setAgeFrom] = useState("");
  const [weightsAddListMale, setWeightsAddListMale] = useState([]);
  const [weightsAddListFemale, setWeightsAddListFemale] = useState([]);
  const [weightsAddListIDMale, setWeightsAddListIDMale] = useState([]);
  const [weightsAddListIDFemale, setWeightsAddListIDFemale] = useState([]);

  const [forJuniorMale, setForJuniorMale] = useState(false);
  const [forCadetMale, setForCadetMale] = useState(false);
  const [forSeniorMale, setForSeniorMale] = useState(false);
  const [forJuniorFemale, setForJuniorFemale] = useState(false);
  const [forCadetFemale, setForCadetFemale] = useState(false);
  const [forSeniorFemale, setForSeniorFemale] = useState(false);
  const patchCompetitionMutation = usePatchCompetitionMutation();
  const { isLoading, isSuccess, data } = useCompetitionQuery({ id: id });

  useEffect(() => {
    setAgeTo(data?.age_to);
    setAgeFrom(data?.age_from);
    setPoints(data?.point);
    setStartDay(data?.start_day);
    setStartRegisterDay(data?.starting_register);
    setEndRegisterDay(data?.end_register);
    setEndDay(data?.end_day);
    setForJuniorMale(data?.for_junior_male);
    setForCadetMale(data?.for_cadet_male);
    setForSeniorMale(data?.for_senior_male);
    setForJuniorFemale(data?.for_junior_female);
    setForCadetFemale(data?.for_cadet_female);
    setForSeniorFemale(data?.for_senior_female);
    setWeightsAddListMale(data?.weight_category_male);
    setWeightsAddListFemale(data?.weight_category_female);
    setValue("title", data?.title);
    setValue("short_name", data?.short_name);
    setValue("location", data?.short_name);
    setValue("region", data?.region);
    setValue("number_of_days_to_register", data?.number_of_days_to_register);
    setValue("logo", data?.logo);
    const arrayCopyMale = [...weightsAddListIDMale];
    data?.weight_category_male.map((elem) => {
      arrayCopyMale.push(elem?.id);
      setWeightsAddListIDMale(arrayCopyMale);
    });

    const arrayCopyFemale = [...weightsAddListIDMale];
    data?.weight_category_female.map((elem) => {
      arrayCopyFemale.push(elem?.id);
      setWeightsAddListIDFemale(arrayCopyFemale);
    });

    let arrayQuotas = [...quotas];
    data?.quotas?.map((el) =>
      arrayQuotas.push({
        competition: el.competition,
        id: el?.id,
        province: el?.region,
        quota_for_female: el?.quota_for_female,
        quota_for_male: el?.quota_for_male,
        region: el?.region_id,
        region_id: el?.region_id,
        remaining_quotas_count: {
          remaining_male_quota:
            el?.remaining_quotas_count?.remaining_male_quota,
          remaining_female_quota:
            el?.remaining_quotas_count?.remaining_female_quota,
        },
      })
    );
    setQuotas(arrayQuotas);
  }, [isLoading, isSuccess]);

  const onSubmit = () => {
    const initialData = {
      quotas: quotas,
      points: points,
      title: watch()?.title,
      short_name: watch()?.short_name,
      location: watch()?.location,
      region:
        typeof watch()?.region === "number" ? watch().region : data?.region_id,
      number_of_days_to_register: watch()?.number_of_days_to_register,
      start_day: startDay,
      end_day: endDay,
      starting_register: startRegisterDay,
      end_register: endRegisterDay,
      age_from: ageFrom,
      age_to: ageTo,

      for_junior_male: forJuniorMale,
      for_cadet_male: forCadetMale,
      for_senior_male: forSeniorMale,
      for_junior_female: forJuniorFemale,
      for_cadet_female: forCadetFemale,
      for_senior_female: forSeniorFemale,
      weight_category_male: weightsAddListIDMale,
      weight_category_female: weightsAddListIDFemale,
    };

    const formData = new FormData();
    formData.append("logo", watch()?.logo);

    const patchCompetition = patchCompetitionMutation.mutateAsync({
      data: initialData,
      id,
    });

    toast
      .promise(patchCompetition, {
        loading: "Loading...",
        success: "Competition is successfully created",
        error: "Please check every field again",
      })
      .then((res) => {
        navigate("/competitions", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
    if (watch()?.logo.uid) {
      patchCompetitionMutation.mutateAsync({
        data: formData,
        id,
      });
    }
  };

  return (
    <div className={classes.edit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainInfo
          control={control}
          errors={errors}
          register={register}
          startDay={startDay}
          setStartDay={setStartDay}
          endDay={endDay}
          endRegisterDay={endRegisterDay}
          setEndRegisterDay={setEndRegisterDay}
          startRegisterDay={startRegisterDay}
          setStartRegisterDay={setStartRegisterDay}
          setEndDay={setEndDay}
        />
        <Quotas quotas={quotas} setQuotas={setQuotas} />
        <Category
          ageFrom={ageFrom}
          ageTo={ageTo}
          setAgeTo={setAgeTo}
          setAgeFrom={setAgeFrom}
          weightsAddListMale={weightsAddListMale}
          setWeightsAddListMale={setWeightsAddListMale}
          weightsAddListFemale={weightsAddListFemale}
          setWeightsAddListFemale={setWeightsAddListFemale}
          forJuniorMale={forJuniorMale}
          setForJuniorMale={setForJuniorMale}
          forCadetMale={forCadetMale}
          setForCadetMale={setForCadetMale}
          forSeniorMale={forSeniorMale}
          setForSeniorMale={setForSeniorMale}
          forJuniorFemale={forJuniorFemale}
          setForJuniorFemale={setForJuniorFemale}
          forCadetFemale={forCadetFemale}
          setForCadetFemale={setForCadetFemale}
          forSeniorFemale={forSeniorFemale}
          setForSeniorFemale={setForSeniorFemale}
          weightsAddListIDMale={weightsAddListIDMale}
          setWeightsAddListIDMale={setWeightsAddListIDMale}
          weightsAddListIDFemale={weightsAddListIDFemale}
          setWeightsAddListIDFemale={setWeightsAddListIDFemale}
        />
        <Points points={points} setPoints={setPoints} />
        <div className={classes.btns}>
          <CButton
            padding={"8px 44px"}
            background={"#0062FF"}
            borderRadius={"34px"}
            type="submit"
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            fontWeight={"500"}
            fontSize={"14px"}
            lineHeight={"24px"}
            textAlign={"right"}
            letterSpacing={"0.2px"}
            color={"#FFFFFF"}
            border={"1px solid #0062FF"}
            disabled={patchCompetitionMutation.isLoading}
          >
            Edit Competition
          </CButton>
        </div>
      </form>
    </div>
  );
}

export default CompetitionEditPage;
