import { useQuery } from "react-query";
import { request } from "services/api";

export const useFlightsDetails = ({ enabled, page, athlete_id }) => {
  return useQuery(
    ["national-team-athlete-trophies", page, athlete_id],
    async () => {
      const { data } = await request.get(
        `/national/team/athlete/visas/flights/`,
        {
          params: { page, athlete_id },
        }
      );
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
