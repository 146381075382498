import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const getCompetition = createAsyncThunk(
  "competition/getCompetition",
  async function ({ id }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/competition/${id}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        //       localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCompetitions = createAsyncThunk(
  "competition/getCompetitions",
  async function (param, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/competition${param}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getRegionCompetition = createAsyncThunk(
  "competition/getRegion",
  async function (_, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/region`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createCompetition = createAsyncThunk(
  "competition/createCompetition",
  async function (resp, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/competition/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          quotas: getState().competitions.quotas,
          points: getState().competitions.points,
          videos: getState().competitions.videos,
          title: resp.title,
          short_name: resp.short_name,
          location: resp.location,
          region: resp.region,
          number_of_days_to_register: resp.number_of_days_to_register,
          start_day: getState().competitions.start_day,
          end_day: getState().competitions.end_day,
          age_from: getState().competitions.age_from,
          age_to: getState().competitions.age_to,

          for_junior_male: getState().competitions.for_junior_male,
          for_cadet_male: getState().competitions.for_cadet_male,
          for_senior_male: getState().competitions.for_senior_male,
          for_junior_female: getState().competitions.for_junior_female,
          for_cadet_female: getState().competitions.for_cadet_female,
          for_senior_female: getState().competitions.for_senior_female,
          weight_category_male: getState().competitions.weight_category_male,
          weight_category_female:
            getState().competitions.weight_category_female,
        }),
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const patchImgCompetition = createAsyncThunk(
  "competition/patchImgCompetition",
  async function (resp, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    const formData = new FormData();
    formData.append("logo", resp.img);

    try {
      const response = await fetch(
        `${URLBASE}/competition/${getState().competitions.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getRegistredMember = createAsyncThunk(
  "competition/getRegistredMember",
  async function (params, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(
        `${URLBASE}/registrsted/members/in/competition${params}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const dowloadCompetition = createAsyncThunk(
  "competition/dowloadCompetition",
  async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(
        `${URLBASE}/download/registered/members/in/competition/${id}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      } else {
        const data = await response.json();

        // Open a new window or tab
        const newWindow = window.open("", "_blank");

        // Create an anchor element in the new window
        const a = newWindow.document.createElement("a");
        a.href = data.file;
        a.download = data.file.split("/").pop();
        newWindow.document.body.appendChild(a);

        // Programmatically trigger a click on the anchor element
        a.click();

        // Close the new window after a short delay (you can adjust the delay as needed)
        // setTimeout(() => {
        //   newWindow.close();
        // }, 2000);

        // console.log(data.file);
        return { success: true, url: data.file };
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getWeightsCompetition = createAsyncThunk(
  "competition/getWeights",
  async function (_, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/weight/categories/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getWeightsCompetitionMale = createAsyncThunk(
  "competition/getWeightsMale",
  async function (weight, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(
        `${URLBASE}/weight/categories?weight=${weight} kg`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getWeightsCompetitionFemale = createAsyncThunk(
  "competition/getWeightsFemale",
  async function (weight, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(
        `${URLBASE}/weight/categories?weight=${weight} kg`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editCompetition = createAsyncThunk(
  "competition/editCompetition",
  async function (resp, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(
        `${URLBASE}/competition/${getState().competitions.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            quotas: resp.quotas,
            points: resp.points,
            videos: resp.videos,
            title: resp.title,
            short_name: resp.short_name,
            location: resp.location,
            region: resp.region,
            number_of_days_to_register: resp.number_of_days_to_register,
            start_day: resp.start_day,
            end_day: resp.end_day,
            starting_register: resp.starting_register,
            age_from: resp.age_from,
            age_to: resp.age_to,
            end_register: resp.end_register,
            for_junior_male: resp.for_junior_male,
            for_cadet_male: resp.for_cadet_male,
            for_senior_male: resp.for_senior_male,
            for_junior_female: resp.for_junior_female,
            for_cadet_female: resp.for_cadet_female,
            for_senior_female: resp.for_senior_female,
            weight_category_male: getState().competitions.weight_category_male,
            weight_category_female:
              getState().competitions.weight_category_female,
          }),
        }
      );
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
