import classes from "./style.module.css";
import CButton from "components/CButton";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic_Close.svg";

function CPopup({ children, title, onClick, padding }) {
  const handleClick = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
    onClick();
  };

  return (
    <div
      className={`${classes.fixed__overlay} ${classes.fixed__overlay__modal}`}
    >
      <div className={classes.modal}>
        <div className={classes.container} style={{ padding: padding }}>
          <div className={classes.header}>
            <h5>{title}</h5>
            <CButton onClick={handleClick} border={"none"} background={"none"}>
              <CloseIcon />
            </CButton>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CPopup;
