import { Col, Row } from "antd";
import { useState } from "react";
import classes from "./style.module.css";
import { Card, EditAdmin, LinkButton, CreateAdmin } from "container";
import { CButton, CDropdown, CLoader } from "components";
import { useAdminsQuery, useRegionsQuery } from "hook/query";
import { ReactComponent as IcPlus } from "assets/images/icons/ic_Plus.svg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";

function AdminsPage() {
  const [region, setRegion] = useState({});
  const [type, setType] = useState({});
  const [open, setOpen] = useState("");
  const [idAdmin, setIdAdmin] = useState(null);

  const adminsList = useAdminsQuery({
    region_id: region?.id,
    admin_type: type,
    page: 1,
  });

  const regionsList = useRegionsQuery({});

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const clearSearchParams = () => {
    setRegion({});
    setType({});
  };

  const listTypesMember = [
    {
      path: "all",
      title: "All",
    },
    {
      path: "regionadmin",
      title: "Region admins",
    },
    {
      path: "clubadmin",
      title: "Club admins",
    },
    {
      path: "federationadmin",
      title: "National federation",
    },
    {
      path: "accounters",
      title: "Accounters",
    },
  ];

  if (adminsList.isLoading) return <CLoader />;

  return (
    <div className={classes.admins}>
      <div className={classes.top}>
        <h4>Admins</h4>
        <div className={classes.right}>
          <CDropdown
            text={"Sort by:"}
            value={region?.province}
            setState={setRegion}
            items={regionsList.data}
            placeholder={"region"}
          />
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={clearSearchParams}
          >
            <IcTrash />
          </CButton>
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#0062FF"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"1px solid #0062FF"}
            padding={"9px 25px 9px 16px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={() => {
              // hiddenWindow();
              setOpen("create");
            }}
          >
            <IcPlus className={classes.plus__icon} />
            New Admin
          </CButton>
        </div>
      </div>
      <div className={classes.flex}>
        {listTypesMember.map((typemember) => (
          <LinkButton
            key={typemember?.path}
            pathName={typemember?.path}
            type={type}
            onClick={(e) => setType(e)}
          >
            {typemember?.title}
          </LinkButton>
        ))}
      </div>
      <Row gutter={[16, 8]}>
        {adminsList?.data?.results?.map((admin, index) => (
          <Col key={index} span={6}>
            <Card
              key={admin.key}
              card={admin}
              onClick={(e) => {
                hiddenWindow();
                setOpen("edit");
                setIdAdmin(e);
              }}
            />
          </Col>
        ))}
      </Row>

      {open === "create" && (
        <CreateAdmin setOpen={setOpen} dataDetails={adminsList} />
      )}
      {open === "edit" && (
        <EditAdmin setOpen={setOpen} adminsList={adminsList} id={idAdmin} setId={setIdAdmin}/>
      )}
    </div>
  );
}

export default AdminsPage;
