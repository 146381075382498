import classes from "./style.module.css";

const CInput = ({
  value,
  label,
  name,
  placeholder,
  type,
  margin,
  width,
  disabled,
  required,
  register,
  errors,
  pattern,
  maxLength,
  minLength,
  min,
  max,
  textTransformation,
}) => (
  <div className={classes.input} style={{ margin, width }}>
    {label && <label htmlFor="input-field">{label}</label>}
    <br />
    <input
      style={{ textTransform: textTransformation }}
      type={type}
      value={value}
      name={name}
      className={`${classes.form_control} ${errors ? classes.error : null}`}
      placeholder={placeholder}
      disabled={disabled}
      {...register(name, {
        required: required,
        maxLength: maxLength,
        minLength: minLength,
        pattern: pattern,
        min: min,
        max: max,
      })}
    />
  </div>
);

export default CInput;
