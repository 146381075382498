import React from "react";
import classes from "./style.module.css";
import { useParams } from "react-router-dom";
import {
  useCompetitionQuery,
  useGetWeightsQuery,
} from "hook/query";
import {
  DateDetail,
  Information,
  InformationLast,
  RegistretedMember,
  ViewInfo,
} from "container";
import { CLoader } from "components";

function CompetitionDetailPage() {
  const { id } = useParams();
  const listWeight = useGetWeightsQuery({});
  const competition = useCompetitionQuery({ id: id });


  if (competition?.isLoading) return <CLoader />;

  return (
    <div className={classes.competition}>
      <h1>
        Competitions <span>&gt; Detail &gt; {competition?.data?.title}</span>
      </h1>
      <ViewInfo competition={competition} />
      <DateDetail competition={competition} />
      <Information competition={competition} />
      <InformationLast competition={competition} />
      <RegistretedMember competition={competition} id={id} />
    </div>
  );
}

export default CompetitionDetailPage;
