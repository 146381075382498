import "./dropdown.css"
import {Button, Dropdown, Select, Space} from "antd";
import {useLocation, useSearchParams} from "react-router-dom";
import qs from "qs";
import {CaretDownOutlined} from "@ant-design/icons"

const AntDropdown = ({options, queryName, placeholder}) => {
    const {search} = useLocation();
    const query = qs.parse(search, {ignoreQueryPrefix: true});
    const [searchParams, setSearchParams] = useSearchParams();
    const handleChange = (value) => {
        setSearchParams({...query, [queryName]: value})
    };

    return (
        <Select
            suffixIcon={<CaretDownOutlined style={{fontSize: 18}}/>}
            size="large"
            className="antd__select"
            // value={options?.[searchParams.get(`${queryName}`)]}
            defaultValue={options[0]}
            placeholder={placeholder}
            onChange={handleChange}
            style={{width: 200}}
            options={options}
        />
    )
}

export default AntDropdown;
