import { memo } from "react";
import { Link, NavLink } from "react-router-dom";

const SiderbarItem = ({ name, icon, path, onClick }) => (
  // <li onClick={onClick} className="menuItem">
  //   <div className="menuIcon">{icon}</div>
  //   <span>
  //     <Link to={path}>{name}</Link>
  //   </span>
  // </li>
  <NavLink
    to={path}
    className={({ isActive }) => `${isActive ? `active` : null} menuItem`}
    onClick={onClick}
  >
    <div className={`menuIcon`}>{icon}</div>
    <span>{name}</span>
  </NavLink>
);
export default memo(SiderbarItem);
