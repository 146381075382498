import { createSlice } from "@reduxjs/toolkit";
import { getSearchCoachs } from "./fetcher";

const initialState = {
    coachs: [],
    status: null,
    error: null,
}

const caochSlice = createSlice({
    name: 'coach',
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.username = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchCoachs.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getSearchCoachs.fulfilled, (state, action) => {
            state.coachs = action.payload;
            state.status = "success";
        });

        builder.addCase(getSearchCoachs.rejected, (state, action) => {
            state.status = "error";
            state.coachs = [];
        });
    },

})

export const { setUserName } = caochSlice.actions;
export default caochSlice.reducer;