import { createSlice } from "@reduxjs/toolkit";
import { pullAllBy } from "lodash";
import { getMembersRegister, registerMember } from "./fetcher";

const initialState = {
    role: "athlete",
    weight: "",
    members: [],
    selectMembers: [],
    idMemberForWeight: [],
    sendDataMember: [],
    popup: false,
    popupWeight: false,
    status: null,
    error: null,
}

const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setPopupWeight: (state, action) => {
            state.popupWeight = action.payload
        },
        setPopup: (state, action) => {
            state.popup = !state.popup
        },
        setSendDataMember: (state, action) => {
            state.sendDataMember.push(action.payload)
        },
        addSelectMember: (state, action) => {
            const findMember = state.selectMembers?.filter((member) => member.id == action.payload.id)
            if (findMember?.length == 0) {
                state.selectMembers.push(action.payload);
                state.idMemberForWeight.push(action.payload.id)
                state.sendDataMember.push({
                    "member": action.payload.id,
                    "first_name": action.payload.first_name,
                    "last_name": action.payload.last_name,
                    "birth_date": action.payload.birth_date,
                    "gender": action.payload.gender,
                    "role": action.payload.role,
                    "region": action.payload.region,
                    "region_id": action.payload.region_id,
                    "status": action.payload.status
                })
            }
        },
        deleteMember: (state, action) => {
            const deleteMember = state.members?.results?.filter((member) => member.id != action.payload.id)
            state.members.results = deleteMember
        },
        deleteSelectMember: (state, action) => {
            const deleteMember = state.selectMembers?.filter((member) => member.id != action.payload.id)
            const deleteMemberId = state.idMemberForWeight?.filter((idWeight) => idWeight != action.payload.id)
            state.selectMembers = deleteMember
            state.idMemberForWeight = deleteMemberId
        },
        deleteMemberId: (state, action) => {
            const deleteMemberId = state.idMemberForWeight?.filter((idWeight) => idWeight != action.payload.id)
            state.idMemberForWeight = deleteMemberId
        },
        editObjSelectMember: (state, action) => {
            const itemIndex = state.sendDataMember.findIndex(member => member.member == action.payload.record.member);
            const item = state.sendDataMember[itemIndex];
            item.weight_category = action.payload.e.id;
            item.weight_category_name = action.payload.e.weight
            state.sendDataMember[itemIndex] = item
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMembersRegister.pending, (state, action) => {
            state.members = [];
            state.status = "loading";
        });

        builder.addCase(getMembersRegister.fulfilled, (state, action) => {
            const array = action.payload.results;
            pullAllBy(action.payload.results, state.selectMembers, 'id');
            state.members = {
                count: action.payload?.count,
                next: action.payload?.next,
                previous: action.payload?.previous,
                results: array,
                total_pages: action.payload?.total_pages,
            }
            state.status = "success";
        });

        builder.addCase(getMembersRegister.rejected, (state, action) => {
            state.members = [];
            state.status = "error";
        });

        builder.addCase(registerMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(registerMember.fulfilled, (state, action) => {
            state.sendDataMember = []
            state.status = "success";
        });

        builder.addCase(registerMember.rejected, (state, action) => {
            state.members = [];
            state.selectMembers = [];
            state.status = "error";
        });
    },
})

export const { setPopup, addSelectMember, deleteMember, deleteSelectMember, deleteMemberId, editObjSelectMember, setPopupWeight, setSendDataMember } = registrationSlice.actions;
export default registrationSlice.reducer;