import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, message, Row } from "antd";
import {
  CButton,
  CDatePicker,
  CInput,
  CPopup,
  CSearch,
  CSelect,
  CUpload,
} from "components";
import {
  useDeleteMemberMutation,
  usePatchMemberMutation,
  useRecoverMemberMutation,
} from "hook/mutation";
import {
  useGetCitiesQuery,
  useGetCoachesQuery,
  useGetMemberQuery,
  useRegionsQuery,
  useSearchClubsQuery,
} from "hook/query";
import toast from "react-hot-toast";

function EditSportsman({ disabled, open, setOpen, id, memberList, setId }) {
  const [listlCubs, setListClubs] = useState([]);
  const [listCoachesState, setListCoachesState] = useState([]);
  const whois = localStorage.getItem("isLogin");
  const patchMemberMutation = usePatchMemberMutation();
  const deleteMemberMutation = useDeleteMemberMutation();
  const recoverMemberMutation = useRecoverMemberMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  const userInfo = useGetMemberQuery({
    enabled: open === "athlete",
    id: id,
  });

  useEffect(() => {
    setValue("image", userInfo.data?.image);
    setValue("document", userInfo.data?.document);
    setValue("first_name", userInfo.data?.first_name);
    setValue("last_name", userInfo.data?.last_name);
    setValue("middle_name", userInfo.data?.middle_name);
    setValue("birth_date", userInfo.data?.birth_date);
    setValue("phone_number", userInfo.data?.phone_number);
    setValue("club", userInfo.data?.club);
    setValue("club_id", userInfo.data?.club_id);
    setValue("city", userInfo.data?.city);
    setValue("city_id", userInfo.data?.city_id);
    setValue("region", userInfo.data?.region);
    setValue("region_id", userInfo.data?.region_id);
    setValue("gender", userInfo.data?.gender);
    setValue("gender", userInfo.data?.gender);
    setValue("ranking", userInfo.data?.ranking);
    setValue("coach", userInfo.data?.coach);
    setValue("coach_id", userInfo.data?.coach_id);
    setValue("role", userInfo.data?.role);
    setValue("status", userInfo.data?.status);
  }, [userInfo.isSuccess]);

  const listClub = useSearchClubsQuery({
    enabled: open === "athlete",
    club: watch().club,
  });

  useEffect(() => {
    setListClubs([]);
    listClub.data?.results?.map((l) => {
      setListClubs((prevArray) => [
        ...prevArray,
        { label: l?.name, value: l.id },
      ]);
    });
  }, [listClub.isSuccess]);

  const listCoaches = useGetCoachesQuery({
    enabled: open === "athlete",
    club: watch().coach,
  });

  useEffect(() => {
    setListCoachesState([]);
    listCoaches.data?.results?.map((l) => {
      setListCoachesState((prevArray) => [
        ...prevArray,
        { label: `${l?.first_name} ${l?.last_name}`, value: l.id },
      ]);
    });
  }, [listCoaches.isSuccess]);

  const listRegions = useRegionsQuery({
    enabled: open === "coach",
  });

  const listCities = useGetCitiesQuery({
    enabled: !!watch().region,
    id:
      whois == "federationadmin"
        ? watch().region
        : JSON.parse(localStorage?.userInfo).region_id,
  });

  const beforeUpload = (file, field) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    if (isJpgOrPng) {
      field.onChange(file);
    }
  };

  const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
  };

  const onSubmit = (e) => {
    const formData = new FormData();
    typeof e?.image == "object" && formData.append("image", e?.image);
    typeof e?.document == "object" && formData.append("document", e?.document);

    formData.append("first_name", e?.first_name);
    formData.append("last_name", e?.last_name);
    formData.append("middle_name", e?.middle_name);
    formData.append("birth_date", e?.birth_date);
    formData.append("phone_number", e?.phone_number);
    typeof e?.club == "number"
      ? formData.append("club", e?.club)
      : formData.append("club", e?.club_id);

    typeof e?.region == "number"
      ? formData.append("region", e?.region)
      : formData.append("region", e?.region_id);

    typeof e?.city == "number"
      ? formData.append("city", e?.city)
      : formData.append("city", e?.city_id);

    typeof e?.ranking != "string" && formData.append("ranking", e?.ranking);

    typeof e?.coach == "number"
      ? formData.append("coach", e?.coach)
      : formData.append("coach", e?.coach_id);

    formData.append("gender", e?.gender);
    formData.append("role", e?.role);

    const patchMember = patchMemberMutation.mutateAsync({
      data: formData,
      id: id,
    });
    toast
      .promise(patchMember, {
        loading: "Loading...",
        success: "Athlete etited successfully",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        reset();
        removeHiddenWindow();
        setOpen("");
        setId("");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMemberFunc = () => {
    const deleteMember = deleteMemberMutation.mutateAsync(id);
    toast
      .promise(deleteMember, {
        loading: "Loading...",
        success: "Athlete deleted successfully",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        reset();
        setOpen("");
        setId("");
        removeHiddenWindow();
        memberList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const recoverMemberFunc = () => {
    const recoverMember = recoverMemberMutation.mutateAsync(id);
    toast
      .promise(recoverMember, {
        loading: "Loading...",
        success: "Athlete recovered successfully",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        reset();
        setOpen("");
        setId("");
        removeHiddenWindow();
        memberList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (open !== "athlete") return null;

  return (
    <CPopup
      onClick={() => {
        setOpen("");
        setId("");
        reset();
      }}
      title={disabled ? "Athlete's Info" : "Edit Athlete"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[33, 8]}>
          <Col span={10}>
            <div className="flexRow">
              <CUpload
                control={control}
                name="image"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Photo"}
                title={"Name Image"}
                type={true}
                error={errors.image}
              />
              <CUpload
                control={control}
                name="document"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Document"}
                title={"Name Document"}
                type={false}
                error={errors.document}
              />
            </div>
            <CInput
              type="text"
              label="Last name"
              name="last_name"
              register={register}
              errors={errors?.last_name}
              required
              disabled={disabled}
            />
            <CInput
              type="text"
              label="First name"
              name="first_name"
              register={register}
              errors={errors?.first_name}
              required
              disabled={disabled}
            />
            <CInput
              type="text"
              label="Middle name"
              name="middle_name"
              register={register}
              errors={errors?.middle_name}
              required
              disabled={disabled}
            />
            <CDatePicker
              label={"Birth date"}
              placeholder={""}
              control={control}
              name="birth_date"
              errors={errors.birth_date}
              value={
                watch().birth_date != ""
                  ? moment(watch().birth_date, `YYYY-MM-DD`)
                  : ""
              }
            />
          </Col>
          <Col style={{ paddingTop: "9px" }} span={7}>
            {whois == "federationadmin" ? (
              <div style={{ marginBottom: "30px" }}>
                <CSelect
                  label={"Region"}
                  name="region"
                  items={listRegions.data}
                  control={control}
                  error={errors.region}
                  required
                  disabled={disabled}
                />
              </div>
            ) : null}
            <CSelect
              label={"City"}
              name="city"
              items={listCities.data?.districts}
              control={control}
              error={errors.city}
              disabled={disabled}
            />

            <div style={{ margin: "30px 0" }}>
              <CSearch
                label={"Club"}
                name="club"
                items={listlCubs}
                control={control}
                error={errors.club}
                disabled={disabled}
              />
            </div>
            <div style={{ margin: "30px 0" }}>
              <CSelect
                label={"Gender"}
                name="gender"
                items={[
                  { province: "Male", id: "male" },
                  { province: "Female", id: "female" },
                ]}
                control={control}
                error={errors.gender}
                required
                disabled={disabled}
              />
            </div>
            <CInput
              type="tel"
              label="Contacts"
              name="phone_number"
              register={register}
              errors={errors?.phone_number}
              required
              pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
              maxLength={13}
              minLength={13}
              disabled={disabled}
            />
          </Col>
          <Col style={{ paddingTop: "9px" }} span={7}>
            <CSelect
              label={"Ranking"}
              name="ranking"
              items={[
                { province: "NOVICE", id: "white belt" },
                { province: "KYU6TH", id: "6th kyu" },
                { province: "KYU5TH", id: "5th kyu" },
                { province: "KYU4TH", id: "4th kyu" },
                { province: "KYU3RD", id: "3rd kyu" },
                { province: "KYU2ND", id: "2nd kyu" },
                { province: "KYU1ST", id: "1st kyu" },
                { province: "ILDAN", id: "il dan" },
                { province: "IDAN", id: "i dan" },
                { province: "SAMDAN", id: "sam dan" },
                { province: "SADAN", id: "sa dan" },
                { province: "ODAN", id: "o dan" },
                { province: "YUKDAN", id: "yuk dan" },
                { province: "CHILDAN", id: "chil dan" },
                { province: "PALDAN", id: "pal dan" },
                { province: "GUDAN", id: "gu dan" },
                { province: "SIPDAN", id: "sip dan" },
              ]}
              control={control}
              error={errors.ranking}
              disabled={disabled}
            />
            <div style={{ margin: "20px 0" }}>
              <CSearch
                label={"Coach"}
                name="coach"
                items={listCoachesState}
                control={control}
                error={errors.club}
                disabled={disabled}
              />
            </div>

            <div style={{ width: "210px" }}>
              {disabled ? null : (
                <>
                  {watch().status == "deleted" ? (
                    <CButton
                      type="button"
                      fontFamily={"Poppins"}
                      fontStyle={"normal"}
                      background={"#0062FF"}
                      fontSize={"14px"}
                      lineHeight={"21px"}
                      fontWeight={"500"}
                      color={"#fff"}
                      border={"1px solid #0062FF"}
                      padding={"9px 35px"}
                      borderRadius={"10px"}
                      margin={"0 0 0 85px"}
                      onClick={recoverMemberFunc}
                      disabled={disabled}
                    >
                      Recover
                    </CButton>
                  ) : (
                    <div className="flex-row">
                      <CButton
                        fontFamily={"Poppins"}
                        fontStyle={"normal"}
                        background={"#fff"}
                        fontSize={"14px"}
                        lineHeight={"18px"}
                        fontWeight={"600"}
                        color={"#FC5A5A"}
                        border={"1px solid #FC5A5A"}
                        padding={"9px 20px"}
                        borderRadius={"10px"}
                        onClick={deleteMemberFunc}
                        disabled={disabled}
                      >
                        Delete
                      </CButton>
                      <CButton
                        type="submit"
                        fontFamily={"Poppins"}
                        fontStyle={"normal"}
                        background={"#0062FF"}
                        fontSize={"14px"}
                        lineHeight={"21px"}
                        fontWeight={"500"}
                        color={"#fff"}
                        border={"1px solid #0062FF"}
                        padding={"9px 20px"}
                        borderRadius={"10px"}
                        margin={"0 0 0 15px"}
                        disabled={disabled}
                      >
                        Edit
                      </CButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </form>
    </CPopup>
  );
}

export default EditSportsman;
