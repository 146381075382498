import { Col, Row } from "antd";
import classes from "./style.module.css";
import { CAROUSEL_IMAGES } from "constants/applicationConstants";

function InformationLast({ competition }) {
  return (
    <div className={classes.information_last}>
      <Row gutter={[8, 8]}>
        <Col
          lg={competition.data?.quotas?.length > 5 ? 24 : 9}
          xxl={competition.data?.quotas?.length > 5 ? 12 : 8}
        >
          <div className={classes.block}>
            <h4>Quotas</h4>
            <Row gutter={[40, 8]}>
              <Col span={competition.data?.quotas?.length > 5 ? 12 : 24}>
                <div className={`${classes.quotas} ${classes.left}`}>
                  <div className={classes.regions}>
                    <h5>Regions</h5>
                    {competition.data?.quotas?.slice(0, 5)?.map((quota) => (
                      <p key={quota.id}>{quota.region}</p>
                    ))}
                  </div>
                  <div className={classes.quotaGrand}>
                    <div className={classes.male}>
                      <h5>Male</h5>
                      {competition?.data?.quotas?.slice(0, 5).map((quota) => (
                        <p key={quota.id}>
                          {quota?.remaining_quotas_count?.remaining_male_quota}/
                          {quota?.quota_for_male}
                        </p>
                      ))}
                    </div>
                    <div className={classes.female}>
                      <h5>Female</h5>
                      {competition.data?.quotas?.slice(0, 5).map((quota) => (
                        <p key={quota.id}>
                          {
                            quota?.remaining_quotas_count
                              ?.remaining_female_quota
                          }
                          /{quota.quota_for_female}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
              {competition.data?.quotas?.length > 5 ? (
                <Col span={12}>
                  <div className={`${classes.quotas} ${classes.right}`}>
                    <div className={classes.regions}>
                      <h5>Regions</h5>
                      {competition.data?.quotas?.slice(6, 10)?.map((quota) => (
                        <p key={quota.region}>{quota.region}</p>
                      ))}
                    </div>
                    <div className={classes.male}>
                      <h5>Male</h5>
                      {competition.data?.quotas?.slice(6, 10)?.map((quota) => (
                        <p key={quota.quota_for_male}>{quota.quota_for_male}</p>
                      ))}
                    </div>
                    <div className={classes.female}>
                      <h5>Female</h5>
                      {competition.data?.quotas?.slice(6, 10).map((quota) => (
                        <p key={quota.quota_for_female}>
                          {quota.quota_for_female}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        </Col>
        <Col
          lg={competition.data?.quotas?.length > 5 ? 24 : 15}
          xxl={competition.data?.quotas?.length > 5 ? 12 : 16}
        >
          <div className={classes.block}>
            <h4>Points</h4>

            <div className={classes.points}>
              <div className={classes.left}>
                <p>Place</p>
                <p>Points</p>
              </div>
              <div
                className={classes.right}
                style={{
                  width: competition.data?.point?.length
                    ? competition.data?.point?.length * 50
                    : 50,
                }}
              >
                <div className={classes.images}>
                  {CAROUSEL_IMAGES.images
                    .slice(0, competition.data?.point?.length)
                    ?.map((image) => (
                      <img
                        key={image.id}
                        src={image.imgPath}
                        alt={image.name}
                      />
                    ))}
                </div>
                <div className={classes.count}>
                  {competition.data?.point?.map((point) => (
                    <p key={point.id}>{point.point}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default InformationLast;
