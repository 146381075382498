import { useQuery } from "react-query";
import { request } from "services/api";

export const useNationalAthleteMedicalDatas = ({ enabled, athlete_id }) => {
  return useQuery(
    ["national-team-athlete-medical-datas", athlete_id],
    async () => {
      const { data } = await request.get(
        `/national/team/athlete/medical/datas/`,
        { params: { athlete_id } }
      );
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
