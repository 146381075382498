import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const getSearchCoachs = createAsyncThunk('coach/getSearchCoachs', async function (title, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/coach-search?coach=${title}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);