import "./header.css";
import { memo } from "react";
import UserViewComponent from "./UserViewComponent";

const HeaderComponent = () => {
  return (
    <div className="header">
      <div className="header__end" style={{ display: "flex" }}>
        <div className="header__profile__view">
          <UserViewComponent />
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderComponent);
