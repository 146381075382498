import classses from "./table.module.css";
import CImg from "assets/images/compotetion.png";
import { CButton } from "components";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import { Table } from "antd";
import { useState } from "react";

function CompetitionsTable({ text, data, ids, setIds }) {
  const whois = localStorage.getItem("isLogin");
  const todayDate = new Date().toJSON().slice(0, 10);
  const navigate = useNavigate();
  const getDay = (e) => {
    const date = new Date(e);

    const d =
      date.getDate()?.toString().length > 1
        ? date.getDate()
        : "0" + date.getDate();
    return d;
  };

  const getMonth = (e) => {
    const date = new Date(e);
    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "",
      render: (text) => {
        return (
          <p className={classses.text}>
            {getMonth(text.start_day)} <br /> {getDay(text.start_day)}-
            {getDay(text.end_day)}
          </p>
        );
      },
    },
    {
      title: "Logo",
      dataIndex: "logo",
      width: "50px",
      render: (logo) => (
        <img width={50} height={50} src={logo ? logo : CImg} alt="" />
      ),
    },
    {
      title: "Title",
      dataIndex: "",
      render: (item) => (
        <div>
          <p className={classses.title}>{item.short_name}</p>
          <p className={classses.people}>
            {item.athletes} athletes - {item.regions_count} regions
          </p>
        </div>
      ),
    },
    {
      title: "Region",
      dataIndex: "region",
      render: (region) => <p className={classses.city}>{region}</p>,
    },
    {
      title: "Detail",
      dataIndex: "id",
      render: (id) => (
        <Link
          to={`/competitions/detail/${id}`}
          style={{
            width: "100px",
          }}
        >
          <CButton
            background={"#ffffff"}
            color={"#0062FF"}
            border={"1px solid #0062FF"}
            padding={"5px 24px"}
            borderRadius={"34px"}
          >
            Detail
          </CButton>
        </Link>
      ),
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div onClick={() => {}}>
          <IcShape />
        </div>
      ),
    },
  ];

  if (text) {
    columns.splice(5, 0, {
      title: "Action",
      dataIndex: "",
      render: (competition) => {
        console.log(competition, todayDate >= competition.starting_register);
        return todayDate <= competition.end_register &&
          todayDate >= competition.starting_register &&
          whois !== "clubadmin" ? (
          <Link
            to={`/competitions/register?competition_id=${competition.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            <CButton
              background={"#0062FF"}
              color={"#ffffff"}
              border={"1px solid #0062FF"}
              padding={"5px 24px"}
              borderRadius={"34px"}
            >
              {text}
            </CButton>
          </Link>
        ) : null;
      },
    });
  }

  return (
    <Table
      rowKey={(record) => record.id}
      onRow={(record) => ({
        style: { cursor: "pointer" },
        onClick: (e) => {
          navigate(`/competitions/detail/${record.id}`);
        },
      })}
      rowSelection={{
        type: "checkbox",
        hideSelectAll: true,
        selectedRowKeys: ids,
        onSelect: ({ id }) => {
          setIds((items) => {
            const exists = items.includes(id);
            if (exists) {
              return items.filter((item) => item !== id);
            } else {
              return [...items, id];
            }
          });
        },
      }}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
}

export default CompetitionsTable;
