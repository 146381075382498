import React from "react";
import { Col, Row } from "antd";
import classes from "./style.module.css";

function DateDetail({ competition }) {
  const getHour = (e) => {
    const date = new Date(e);
    let Hour = date.getHours();
    return Hour;
  };

  const getMinute = (e) => {
    const date = new Date(e);
    let Minutes = date.getMinutes();
    return Minutes;
  };

  const getDay = (e) => {
    const date = new Date(e);
    const d =
      date.getDate()?.toString().length > 1
        ? date.getDate()
        : "0" + date.getDate();
    return d;
  };

  const getMonth = (e) => {
    const date = new Date(e);
    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  return (
    <div className={classes.date_detail}>
      <Row gutter={16}>
        <Col>
          <div className={classes.location}>
            <p>Date of the competition</p>
          </div>
        </Col>
        <Col span={6}>
          <div className={classes.date}>
            <h6>
              {getMonth(competition.data?.start_day)}{" "}
              {getDay(competition.data?.start_day)}-{" "}
              {getDay(competition.data?.end_day)} 09:00 - 18:00
            </h6>
          </div>
        </Col>
        <Col>
          <div
            className={classes.location}
            style={{ borderLeft: "1px solid #e1e1e1" }}
          >
            <p>Deadline to register</p>
          </div>
        </Col>
        <Col>
          <div className={classes.date}>
            <h6>
              {getMonth(competition.data?.starting_register)}{" "}
              {getDay(competition.data?.starting_register)}-{" "}
              {getDay(competition.data?.end_register)}{" "}
              {getHour(competition.data?.starting_register)}
              {":"}
              {getMinute(competition.data?.starting_register) == 0
                ? "00"
                : getMinute(competition.data?.end_register)}
              -{getHour(competition.data?.end_register)}
              {":"}
              {getMinute(competition.data?.end_register) == 0
                ? "00"
                : getMinute(competition.data?.end_register)}
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DateDetail;
