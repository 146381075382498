import moment from "moment";
import { useForm } from "react-hook-form";
import classes from "./style.module.css";
import {
  useDeleteFlightsMutation,
  usePatchFlightsMutation,
} from "hook/mutation";
import { useEffect, useState } from "react";
import {
  CLoader,
  CButton,
  CInput,
  CPopup,
  CSelect,
  CDatePicker,
} from "components";

function EditFlights({ title, setOpen, dataDetails, data }) {
  const patchFlightsMutation = usePatchFlightsMutation();
  const deleteFlightsMutation = useDeleteFlightsMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: { date: "" },
  });

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const found = dataDetails?.data?.visas_list.find(
      (element) => element?.id == data?.data?.visa
    );
    setValue("visa", found?.name);
    setValue("visa_id", found?.id);
    setValue("date", data.data?.date);
    setValue("reason", data.data?.flight);
    setValue("id", data.data?.id);

    dataDetails?.data?.visas_list.map((d) =>
      setCountries((current) => [...current, { province: d?.name, id: d?.id }])
    );
  }, [data.data]);

  const deleteFlightsSubmit = () => {
    const deleteFlights = deleteFlightsMutation.mutateAsync(data.data?.id);
    deleteFlights
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (e) => {
    const visaIn = typeof e.visa == "number" ? e.visa : e.visa_id;
    const data = {
      date: e.date,
      flight: e.reason,
      visa: visaIn,
    };

    const patchFlights = patchFlightsMutation.mutateAsync({
      data: data,
      id: e.id,
    });
    patchFlights
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
        setCountries([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.flights}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.flex}>
            <div style={{ margin: "22px 0", width: "50%" }}>
              <CSelect
                label={"Visa"}
                name="visa"
                items={countries}
                control={control}
                error={errors.visa}
                required
              />
            </div>
            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <CDatePicker
                label={"Date of Flight"}
                control={control}
                name="date"
                placeholder=""
                required={true}
                errors={errors}
                value={
                  watch().date != "" ? moment(watch().date, `YYYY-MM-DD`) : ""
                }
              />
            </div>
          </div>
          <CInput
            type="text"
            label="Reason"
            name="reason"
            register={register}
            errors={errors?.reason}
            required
          />

          <div className={classes.btns}>
            <CButton
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"none"}
              fontSize={"14px"}
              lineHeight={"18px"}
              fontWeight={"600"}
              color={"#fc5a5a"}
              border={"1px solid #fc5a5a"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              onClick={deleteFlightsSubmit}
            >
              Delete
            </CButton>
            <CButton
              type="submit"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={!!patchFlightsMutation.isLoading ? "#fff" : "#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              position={"relative"}
              height={"40px"}
            >
              {patchFlightsMutation.isLoading ? (
                <CLoader width={"80px"} height={"80px"} />
              ) : (
                "Save"
              )}
            </CButton>
          </div>
        </form>
      </CPopup>
    </div>
  );
}

export default EditFlights;
