import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ allowedRoles }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth.isLoggedIn);
  
  return allowedRoles?.includes(auth) ? (
    <Navigate to="/403" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
