import { useState } from "react";
import classes from "./style.module.css";
import { Table } from "antd";
import { Link } from "react-router-dom";
import noImage from "assets/images/notfound/noimage.jpg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { CButton, CDropdown, CLoader } from "components";
import { useGetDatasClubQuery, useRegionsQuery } from "hook/query";
import { PaginationNumber } from "container";

function ClubPage() {
  const [region, setRegion] = useState({});
  const [page, setPage] = useState(1);
  const whois = localStorage.getItem("isLogin") == "federationadmin";
  const listRegions = useRegionsQuery({
    enabled: true,
  });

  const listData = useGetDatasClubQuery({
    enabled: true,
    region_id: region.id,
    page: page,
  });

  const currentDate = () => new Date().toJSON().slice(0, 10);

  const clearSearchParams = () => {
    setRegion("");
    setPage(1);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          <img src={record?.logo ? record.logo : noImage} alt="logo" />
          {record.name}
        </div>
      ),
    },

    {
      title: "created at",
      dataIndex: "created_at",
      key: "created",
      render: (_, record) => <div>{currentDate(record?.created_at)}</div>,
    },
    {
      title: "#Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "members",
      dataIndex: "member_count",
      key: "member_count",
      render: (member_count) => (
        <div>{member_count > 0 ? member_count : "0"}</div>
      ),
    },
    {
      title: "Region",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Link to={`/clubs/edit/${record.id}`}>
          <div className={classes.detail}>Detail</div>
        </Link>
      ),
    },
  ];

  if (listData.isLoading) return <CLoader />;

  return (
    <div className={classes.club}>
      <div className={classes.header}>
        <h4>Clubs</h4>
        <div className={classes.flex}>
          {whois ? (
            <>
              <CDropdown
                text={"Sort by:"}
                value={region?.province}
                setState={setRegion}
                items={listRegions.data}
                margin={"0 0 0 20px"}
                placeholder={"region"}
              />
              <CButton
                type="button"
                className={classes.trash}
                onClick={clearSearchParams}
              >
                <IcTrash />
              </CButton>
            </>
          ) : null}

          {localStorage.getItem("isLogin") != "clubadmin" ? (
            <Link to="create">
              <CButton
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 25px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
              >
                New Club
              </CButton>
            </Link>
          ) : null}
        </div>
      </div>
      <div className={classes.table}>
        <Table
          dataSource={listData.data?.results}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id}
        />

        {listData.data?.results?.length > 0 ? (
          <div className={classes.pagination}>
            <PaginationNumber
              count={page}
              counts={listData.data?.count}
              pages={listData.data?.total_pages}
              defaultCurrent={1}
              total={listData?.data.total_pages * 10}
              showSizeChanger={false}
              onChange={(e) => setPage(e)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ClubPage;
