import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetMemberQuery = ({ enabled, id }) => {
  return useQuery(
    ["members", id],
    async () => {
      const { data } = await request.get(`/main/member/${id}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
