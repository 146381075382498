import "./header.css";
import { memo, useState } from "react";
import noImage from "../../assets/images/notfound/nopic.jpg";

function UserViewComponent() {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  return (
    <button>
      <img src={userInfo?.image ? userInfo?.image : noImage} alt="user" />
      <div className="user__view">
        <h5>
          {userInfo?.first_name} {userInfo?.last_name}
        </h5>
        <p>{userInfo?.admin_type}</p>
      </div>
    </button>
  );
}

export default memo(UserViewComponent);
