import classes from "./style.module.css";
import { Table } from "antd";
import { useEffect, useState } from "react";
import noImage from "assets/images/notfound/nopic.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { useForm } from "react-hook-form";
import {
  useClubForAddQuery,
  useGetCitiesQuery,
  useGetDataClubIDQuery,
  useGetDataClubQuery,
  useRegionsQuery,
} from "hook/query";
import { CDropdown, CLoader, CButton } from "components";
import { InfoClub, PaginationNumber } from "container";
import { usePatchClubMutation } from "hook/mutation";

function ClubEdit() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [region, setRegion] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMembersId, setSelectedMembersId] = useState([]);
  const patchClubMutation = usePatchClubMutation();
  const whois = localStorage.getItem("isLogin");

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  const navigate = useNavigate();

  const listRegions = useRegionsQuery({
    enabled: true,
  });

  const listMembers = useClubForAddQuery({
    enabled: true,
    page: page,
    region_id: region?.id,
  });

  const clubData = useGetDataClubQuery({
    enabled: true,
    id: id,
  });

  const clubDataID = useGetDataClubIDQuery({
    enabled: true,
    id: id,
  });

  const listCities = useGetCitiesQuery({
    enabled:
      whois == "federationadmin"
        ? !!watch().region
        : !!JSON.parse(localStorage?.getItem("userInfo")).region_id,
    id:
      whois == "federationadmin"
        ? watch().region
        : JSON.parse(localStorage?.getItem("userInfo")).region_id,
  });

  const resetData = () => {
    clubData.remove();
    reset();
    setSelectedMembersId("");
    setSelectedMembers("");
    setSelectedRow("");
  };

  const changeSelectedRow = (e) => {
    const findedId = selectedRow.find((item) => item?.id == e);
    if (!!findedId) {
      const deleteState = selectedRow.filter(
        (currentValue) => currentValue.id != e
      );
      setSelectedRow(deleteState);
    } else {
      setSelectedRow((prevArray) => [...prevArray, { id: parseInt(e) }]);
    }
  };

  const changeSelectedMembers = (e) => {
    const findMember = selectedMembers.find((item) => item.id == e?.id);
    if (!!findMember) {
      const deleteMember = selectedMembers.filter(
        (member) => member?.id != findMember.id
      );
      setSelectedMembers(deleteMember);
    } else {
      setSelectedMembers((prevArray) => [...prevArray, e]);
    }
  };

  const changeSelectedMembersId = (e) => {
    const findedId = selectedMembersId.find((item) => item == e);
    if (!!findedId) {
      const deleteState = selectedMembersId.filter(
        (currentValue) => currentValue != e
      );
      setSelectedMembersId(deleteState);
    } else {
      setSelectedMembersId((prevArray) => [...prevArray, e]);
    }
  };

  const addSelectedRow = (array) => {
    array?.map((d) => {
      setSelectedRow((prevArray) => [...prevArray, { id: parseInt(d.id) }]);
    });
  };

  const addSelectedMembersId = (array) => {
    array?.map((d) => {
      setSelectedMembersId((prevArray) => [...prevArray, d.id]);
    });
  };

  useEffect(() => {
    setSelectedMembers(clubData.data?.results);
    addSelectedRow(clubData.data?.results);
    addSelectedMembersId(clubData.data?.results);
  }, [clubData.isSuccess]);

  useEffect(() => {
    setValue("name", clubDataID.data?.name);
    setValue("image", clubDataID.data?.logo);
    setValue("region", clubDataID.data?.province_id);
    setValue("city", clubDataID.data?.district_id);
    listCities.refetch();
  }, [clubDataID.isSuccess]);

  const onSubmit = (e) => {
    const data = {
      name: e.name,
      province: e.region,
      district: e.city,
      members: selectedRow,
    };

    const formData = new FormData();
    formData.append("logo", e?.image);

    const firstPatchClub = patchClubMutation.mutateAsync({
      data: data,
      id: id,
    });
    firstPatchClub
      .then((res) => {
        const patchClub = patchClubMutation.mutateAsync({
          data: formData,
          id: id,
        });

        if (typeof e.image != "object") {
          resetData();

          return navigate("/clubs");
        }
        patchClub
          .then((resp) => {
            resetData();
            navigate("/clubs");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Members",
      dataIndex: "name",
      width: "30%",
      render: (_, record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          <img src={record?.image ? record?.image : noImage} alt="" />
          {record?.first_name} {record?.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
    },
    {
      title: "#id",
      dataIndex: "id",
    },

    {
      title: "role",
      dataIndex: "role",
    },
    {
      title: "status",
      dataIndex: "status",

      render: (status) => (
        <div
          className={status}
          style={{ fontFamily: "Poppins", fontWeight: "500" }}
        >
          {status}
        </div>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "id",
    //   width: "10%",
    //   render: (_, record) => (
    //     <div
    //       onClick={() => {
    //         setOpen(record.role);
    //         dispatch(getMember(record?.id));
    //         hiddenWindow();
    //       }}
    //     >
    //       <IcShape />
    //     </div>
    //   ),
    // },
  ];

  if (clubData.isLoading) return <CLoader />;

  return (
    <div className={classes.club}>
      <div className={classes.header}>
        <h4>Edit Club</h4>
        {whois == "federationadmin" ? (
          <div className={classes.flex}>
            <CDropdown
              text={"Sort by:"}
              value={region?.province}
              setState={setRegion}
              items={listRegions.data}
              placeholder={"region"}
            />
            <CButton
              type="button"
              className={classes.trash}
              onClick={() => setRegion({})}
            >
              <IcTrash />
            </CButton>
          </div>
        ) : null}
      </div>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <InfoClub
          control={control}
          errors={errors}
          register={register}
          regions={listRegions.data}
          watch={watch}
          cities={listCities.data?.districts}
        />
        <Table
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedMembersId,
            onSelect: (record) => {
              const textNumber = record.id.toString();
              changeSelectedRow(textNumber);
              changeSelectedMembers(record);
              changeSelectedMembersId(parseInt(textNumber));
            },
            hideSelectAll: true,
          }}
          scroll={{ y: 240 }}
          rowKey={(record) => record?.id}
          columns={columns}
          dataSource={selectedMembers}
          pagination={false}
        />

        <div className={classes.pagination}>
          <p>
            Number of members: <span>{selectedMembers?.length}</span>
          </p>
          <CButton
            background={"#0062FF"}
            color={"#ffffff"}
            padding={"4px 18px"}
            fontSize={"14px"}
            fontWeight={"500"}
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            lineHeight={"24px"}
            borderRadius={"10px"}
            border={"1px solid #ffffff"}
            type={"submit"}
          >
            Save
          </CButton>
        </div>
      </form>
      <div className={classes.form}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedMembersId,
            onSelect: (record) => {
              const textNumber = record.id.toString();
              changeSelectedRow(textNumber);
              changeSelectedMembers(record);
              changeSelectedMembersId(parseInt(textNumber));
            },
            hideSelectAll: true,
          }}
          columns={columns}
          dataSource={listMembers.data?.results}
          pagination={false}
        />
        {listMembers.data?.count ? (
          <PaginationNumber
            count={page}
            counts={listMembers.data.count}
            pages={listMembers.data.total_pages}
            defaultCurrent={page}
            total={
              !!listMembers.data?.total_pages
                ? listMembers.data?.total_pages * 10
                : 10
            }
            showSizeChanger={false}
            onChange={setPage}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ClubEdit;
