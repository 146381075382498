import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetVisaIdQuery = ({ enabled, page, id }) => {
  return useQuery(
    ["national-team-visa-id", page, id],
    async () => {
      const { data } = await request.get(`/national/team/athlete/visa/${id}/`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
