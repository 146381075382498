import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetDowloadFileQuery = ({ enabled, id }) => {
  return useQuery(
    ["get-dowload-file-query", id],
    async () => {
      const { data } = await request.get(`/re/download/chek/${id}/`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
