import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetDatasClubQuery = ({ enabled, region_id, page }) => {
  return useQuery(
    ["get-datas-club", region_id, page],
    async () => {
      const { data } = await request.get(`/club/`, {
        params: { region_id, page },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
