import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import LayoutComponent from "../components/Layot/LayotComponent";
import LoginPage from "../pages/LoginPage";
import { Navigate, Route, Routes } from "react-router-dom";
import CompetitionsDrawPage from "../pages/CompetitionsDrawPage";
import { AuthProvider } from "../context/AuthProvider";
import { useSelector } from "react-redux";
import {
  NationalTeam,
  AdminsPage,
  NationalTeamDetail,
  Trophies,
  MedicalCertificate,
  VisaDatas,
  SettingPage,
  NotFoundPage,
  ClubPage,
  ClubCreate,
  ClubEdit,
  MembersPage,
  RankingPage,
  ContributionPage,
  PaymentsPage,
  PaymentDetailPage,
  PaymentCreate,
  CompetetionCreatePage,
  CompetitionDetailPage,
  CompetitionEditPage,
  NotAuthPage,
  CompetitionsPage,
  CompetetionRegisterPage,
} from "pages";

function RotuesMain() {
  const auth = useSelector((state) => state.auth.isLoggedIn);

  const ROLES = {
    Region: "regionadmin",
    Federation: "federationadmin",
    Club: "clubadmin",
    VisaManager: "visa_manager",
    Doctor: "doctor",
    TrophiesManager: "trophies_manager",
    SportManager: "sport_manager",
  };

  return (
    <div>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LayoutComponent />}>
            <Route
              element={
                <PrivateRoute
                  allowedRoles={[
                    ROLES.VisaManager,
                    ROLES.Federation,
                    ROLES.Doctor,
                    ROLES.TrophiesManager,
                    ROLES.SportManager,
                  ]}
                />
              }
            >
              <Route index element={<Navigate to="national-team" replace />} />
              <Route path="national-team" element={<NationalTeam />} />
              <Route
                path="national-team/:id"
                element={<NationalTeamDetail />}
              />
              <Route path="national-team/:id/trophies" element={<Trophies />} />

              <Route
                path="national-team/:id/medical-certificate"
                element={<MedicalCertificate />}
              />
              <Route
                path="national-team/:id/visa-datas"
                element={<VisaDatas />}
              />
            </Route>
            <Route
              element={
                <PrivateRoute
                  allowedRoles={[ROLES.Region, ROLES.Federation, ROLES.Club]}
                />
              }
            >
              {ROLES.Club == auth ? (
                <Route index element={<Navigate to="ranking" replace />} />
              ) : (
                <Route index element={<Navigate to="all-members" replace />} />
              )}

              <Route
                element={
                  <PrivateRoute
                    allowedRoles={[ROLES.Region, ROLES.Federation]}
                  />
                }
              >
                <Route path="all-members" element={<MembersPage />} />{" "}
                <Route path="competitions" element={<CompetitionsPage />} />
                <Route
                  path="competitions/draw"
                  element={<CompetitionsDrawPage />}
                />
                <Route
                  path="competitions/detail/:id"
                  element={<CompetitionDetailPage />}
                />
                <Route
                  path="competitions/register/"
                  element={<CompetetionRegisterPage />}
                />
              </Route>

              <Route
                element={<PrivateRoute allowedRoles={[ROLES.Federation]} />}
              >
                <Route path="admins" element={<AdminsPage />} />
                <Route path="setting" element={<SettingPage />} />
                <Route path="Member_fee" element={<ContributionPage />} />
                <Route
                  path="competitions/edit/:id"
                  element={<CompetitionEditPage />}
                />
                <Route
                  path="competitions/create"
                  element={<CompetetionCreatePage />}
                />
              </Route>

              <Route path="payments" element={<PaymentsPage />} />
              <Route path="payments/create" element={<PaymentCreate />} />
              <Route path="payments/:id" element={<PaymentDetailPage />} />
              <Route path="clubs" element={<ClubPage />} />
              <Route path="clubs/edit/:id" element={<ClubEdit />} />
              <Route path="clubs/create" element={<ClubCreate />} />

              <Route path="ranking" element={<RankingPage />} />
            </Route>
          </Route>
          <Route
            element={
              <PublicRoute
                allowedRoles={[
                  ROLES.Region,
                  ROLES.Federation,
                  ROLES.Club,
                  ROLES.Doctor,
                  ROLES.TrophiesManager,
                  ROLES.SportManager,
                  ROLES.VisaManager,
                ]}
              />
            }
          >
            <Route path="login" element={<LoginPage />} />
          </Route>
          <Route path="/" element={<LayoutComponent />}>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="403" element={<NotAuthPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default RotuesMain;
