import { createSlice } from "@reduxjs/toolkit";
import { uniq, uniqBy } from "lodash";
import {
  createClub,
  getClub,
  getClubs,
  getMemberClub,
  getMembersAddClub,
  getSearchClubs,
  patchImgClub,
} from "./fetcher";

const initialState = {
  status: null,
  error: null,
  id: "",
  club: null,
  clubs: [],
  searchClubs: [],
  verificationClub: [],
  membersForAddingClub: [],

  selectMembers: [],
  selectedRow: [],
  selectMembersId: [],
  count: "",
  total_pages: "",
  page: null,

  statusLoading: null,
};

const clubSlice = createSlice({
  name: "club",
  initialState,
  reducers: {
    setSelectMembersId: (state, action) => {
      state.selectMembersId = [];
    },
    setSelectMembers: (state, action) => {
      state.selectMembers = [];
    },
    addSelectMember: (state, action) => {
      const findMember = state.selectMembers?.find(
        (item) => item.id == action.payload.id
      );
      if (findMember?.id == action.payload.id) {
        const deleteMember = state.selectMembers?.filter(
          (member) => member?.id != findMember.id
        );
        state.selectMembers = deleteMember;
      } else {
        state.selectMembers.push(action.payload);
      }
    },

    deleteOldMembers: (state, action) => {
      state.selectMembers = [];
      state.selectedRow = [];
      state.selectMembersId = [];
      state.count = "";
      state.total_pages = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClubs.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getClubs.fulfilled, (state, action) => {
      state.clubs = action.payload;
      state.status = "success";
    });

    builder.addCase(getClubs.rejected, (state, action) => {
      state.status = "error";
      state.clubs = [];
    });

    builder.addCase(getClub.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getClub.fulfilled, (state, action) => {
      state.club = action.payload;
      state.id = action.payload.id;
      state.status = "success";
    });

    builder.addCase(getClub.rejected, (state, action) => {
      state.status = "error";
      state.clubs = [];
    });

    builder.addCase(getSearchClubs.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getSearchClubs.fulfilled, (state, action) => {
      state.searchClubs = action.payload;
      state.status = "success";
    });

    builder.addCase(getSearchClubs.rejected, (state, action) => {
      state.status = "error";
      state.searchClubs = [];
    });

    builder.addCase(createClub.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(createClub.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.status = "success";
      state.selectMembers = [];
      state.selectedRow = [];
    });

    builder.addCase(createClub.rejected, (state, action) => {
      state.status = "error";
      state.clubs = [];
    });

    builder.addCase(getMemberClub.pending, (state, action) => {
      state.statusLoading = "loading";
    });

    builder.addCase(getMemberClub.fulfilled, (state, action) => {
      state.page = action.payload?.total_pages;
      state.selectMembers = [
        ...state?.selectMembers,
        ...action?.payload?.results,
      ];
      state.selectMembers = uniqBy(state.selectMembers, "id");
      state.verificationClub = [
        ...state.verificationClub,
        ...action.payload.results,
      ];
      state.verificationClub = uniqBy(state.verificationClub, "id");
      action.payload.results?.map((member, index) => {
        state.selectMembersId.push(member.id);
        state.selectMembersId = uniq(state.selectMembersId);
        state.selectedRow.push({ id: member.id });
        state.selectedRow = uniqBy(state.selectedRow, "id");
      });
      state.statusLoading = "success";
    });

    builder.addCase(getMemberClub.rejected, (state, action) => {
      state.statusLoading = "error";
      state.clubs = [];
    });

    builder.addCase(getMembersAddClub.pending, (state, action) => {
      state.status = "loading";
      state.membersForAddingClub = [];
    });

    builder.addCase(getMembersAddClub.fulfilled, (state, action) => {
      state.membersForAddingClub = action.payload.results;
      // state.verificationClub = action.payload.results;
      state.total_page = action.payload.total_pages;
      state.count = action.payload.count;
      state.status = "success";
    });

    builder.addCase(getMembersAddClub.rejected, (state, action) => {
      state.status = "error";
      state.membersForAddingClub = [];
    });

    builder.addCase(patchImgClub.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(patchImgClub.fulfilled, (state, action) => {
      state.status = "success";
    });

    builder.addCase(patchImgClub.rejected, (state, action) => {
      state.status = "error";
    });
  },
});

export const {
  addSelectMember,
  clearStatsClub,
  setSelectMembersId,
  setSelectMembers,
} = clubSlice.actions;
export default clubSlice.reducer;
