import { useQuery } from "react-query";
import { request } from "services/api";

export const useCompetitionQuery = ({ enabled, id }) => {
  return useQuery(
    ["competition-id-query", enabled],
    async () => {
      const { data } = await request.get(`/competition/${id}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
