import React, { useState } from "react";
import classes from "./style.module.css";
import { Pagination, Table } from "antd";
import {
  CreateCompetitionForNationalTeam,
  EditCompetitionForNationalTeam,
  NationalTeamUserInfo,
} from "container";
import { useParams } from "react-router-dom";
import {
  useNationalTeamMember,
  useTrophiesAndResults,
  useTrophiesId,
} from "hook/query";
import PaginationNumber from "components/Pagination/PaginationNumber";

function Trophies() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [eopen, setEOpen] = useState(false);
  const [competitionID, setCompetitionID] = useState(0);

  const dataUser = useNationalTeamMember({
    enabled: true,
    id: id,
  });

  const dataList = useTrophiesAndResults({
    enabled: true,
    athlete_id: id,
  });

  const trophiesData = useTrophiesId({
    enabled: eopen,
    id: competitionID,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "competition_name",

      render: (_, data) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
          onClick={() => {
            setEOpen(true);
            setCompetitionID(data?.id);
          }}
        >
          {data?.competition_name}
        </div>
      ),
    },
    {
      title: "From date",
      dataIndex: "start_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>{text}</div>
      ),
    },
    {
      title: "To date",
      dataIndex: "end_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>{text}</div>
      ),
    },
    {
      title: "Place",
      dataIndex: "palace",
      render: (text) => <>{text}</>,
    },
  ];

  return (
    <div className={classes.trophies}>
      <NationalTeamUserInfo
        data={dataUser.data}
        title={"Trophies and results"}
        location={true}
      />

      <div className={classes.table}>
        <div className={classes.flex}>
          <h3>Competitions</h3>
          <button type="button" onClick={() => setOpen(true)}>
            + Add Competitions
          </button>
        </div>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            hideSelectAll: true,
          }}
          columns={columns}
          dataSource={dataList.data?.results}
          pagination={false}
        />
        {dataList.data?.total_pages ? (
          <div className="paginationTable">
            <PaginationNumber
              count={page}
              counts={dataList.data?.count}
              pages={dataList.data?.total_pages}
            />

            <Pagination
              defaultCurrent={1}
              total={dataList.data?.total_pages * 10}
              showSizeChanger={false}
              onChange={(e) => setPage(e)}
            />
          </div>
        ) : null}
      </div>

      {open ? (
        <CreateCompetitionForNationalTeam
          title={"Create competition"}
          setOpen={setOpen}
          athleteID={id}
          dataList={dataList}
        />
      ) : null}

      {eopen ? (
        <EditCompetitionForNationalTeam
          title={"Edit competition"}
          setOpen={setEOpen}
          athleteID={id}
          dataList={dataList}
          data={trophiesData.data}
          id={competitionID}
        />
      ) : null}
    </div>
  );
}

export default Trophies;
