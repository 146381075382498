import React from "react";

export default function CButton({
  children,
  fontSize,
  onClick,
  className,
  width,
  height,
  padding,
  border,
  borderRadius,
  margin,
  background,
  cursor,
  color,
  type,
  disabled,
  lineHeight,
  fontWeight,
  fontFamily,
  fontStyle,
  position,
}) {
  return (
    <>
      <button
        style={{
          position,
          fontStyle,
          fontFamily,
          fontSize,
          width,
          height,
          padding,
          border,
          borderRadius,
          margin,
          background,
          cursor: "pointer",
          color,
          lineHeight,
          fontWeight,
        }}
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={className}
      >
        {children}
      </button>
    </>
  );
}
