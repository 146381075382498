import classes from "./style.module.css";
import React from "react";
import { Select } from "antd";
import { Controller } from "react-hook-form";
import { ReactComponent as IcDropdown } from "../../assets/images/icons/ic_Dropdown.svg";

function CSelect({ name, items, error, required, control, disabled, label }) {
  const { Option } = Select;
  return (
    <div className={classes.select__box}>
      {!!label && (
        <label className={classes.label} htmlFor="input">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (
          <Select
            status={error && "error"}
            autoFocus={false}
            className={classes.select}
            name={field.name}
            suffixIcon={<IcDropdown />}
            optionFilterProp="children"
            value={field.value}
            disabled={disabled}
            {...field}
          >
            {items?.map((item, index) => (
              <Option
                key={index}
                className={"dropdownItem"}
                value={item.id || item}
              >
                {item.province}
                {item.type}
                {typeof item === "number" ? item : null}
              </Option>
            ))}
          </Select>
        )}
      />
    </div>
  );
}

export default CSelect;
