import { useQuery } from "react-query";
import { request } from "services/api";

export const useCompetitionsQuery = ({
  enabled,
  region_id,
  page,
  year,
  action,
}) => {
  return useQuery(
    ["competitions", enabled, region_id, page, year, action],
    async () => {
      const { data } = await request.get(`/competition/`, {
        params: { region_id, page, year, action },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
