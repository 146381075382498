import moment from "moment";
import { Col, Row } from "antd";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useTrophiesMutation } from "hook/mutation";
import { CButton, CDatePicker, CInput, CLoader, CPopup } from "components";

function EditCompetitionForNationalTeam({
  title,
  setOpen,
  athleteID,
  dataList,
}) {
  const trophiesMutation = useTrophiesMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      from_date: "",
      to_date: "",
    },
  });

  const onSubmit = (e) => {
    let data = {
      competition_name: e.competition_name,
      start_date: e.from_date,
      end_date: e.to_date,
      palace: e.place,
      athlete: athleteID,
    };

    const createTrophies = trophiesMutation.mutateAsync(data);
    createTrophies
      .then((res) => {
        reset();
        setOpen(false);
        dataList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes?.competition__national_team}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[33, 8]}>
            <Col span={14}>
              <CInput
                type="text"
                label="Competition name"
                name="competition_name"
                register={register}
                errors={errors?.competition_name}
                required
              />
              <div style={{ marginBottom: 20 }}>
                <CDatePicker
                  label={"From date"}
                  control={control}
                  name="from_date"
                  placeholder=""
                  required={true}
                  errors={errors.from_date}
                  value={
                    watch().from_date != ""
                      ? moment(watch().from_date, `YYYY-MM-DD`)
                      : ""
                  }
                />
              </div>
              <CDatePicker
                label={"To date"}
                control={control}
                name="to_date"
                placeholder=""
                required={true}
                errors={errors.to_date}
                value={
                  watch().to_date != ""
                    ? moment(watch().to_date, `YYYY-MM-DD`)
                    : ""
                }
              />
            </Col>
            <Col span={10}>
              <CInput
                type="numbee"
                label="Place"
                name="place"
                register={register}
                errors={errors?.place}
                minLength={1}
                maxLength={7}
                required
              />
              <div className={classes.flex}>
                <CButton
                  type="button"
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={"none"}
                  fontSize={"14px"}
                  lineHeight={"18px"}
                  fontWeight={"600"}
                  color={"#0062FF"}
                  border={"1px solid #0062FF"}
                  padding={"9px 25px"}
                  borderRadius={"10px"}
                  onClick={() => reset()}
                >
                  Clear
                </CButton>
                <CButton
                  type="submit"
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={
                    trophiesMutation.isLoading ? "#ffffff" : "#0062FF"
                  }
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={"500"}
                  color={"#fff"}
                  border={"1px solid #0062FF"}
                  padding={"9px 25px"}
                  borderRadius={"10px"}
                  height={"40px"}
                  position={"relative"}
                >
                  {trophiesMutation.isLoading ? (
                    <CLoader width={"80px"} height={"80px"} />
                  ) : (
                    "Save"
                  )}
                </CButton>
              </div>
            </Col>
          </Row>
        </form>
      </CPopup>
    </div>
  );
}

export default EditCompetitionForNationalTeam;
