import "./popup.css";
import Button from "./Button";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic_Close.svg";

function Popup({ children, title, onClick, padding }) {
  const handleClick = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
    onClick();
  };

  return (
    <div className="fixed-overlay fixed-overlay__modal">
      <div className="modal">
        <div className="modal_container" style={{ padding: padding }}>
          <div className="popupTop">
            <h5>{title}</h5>
            <Button onClick={handleClick} border={"none"} background={"none"}>
              <CloseIcon />
            </Button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Popup;
