import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const getMember = createAsyncThunk(
  "member/getMember",
  async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/main/member/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getMembers = createAsyncThunk(
  "member/getMembers",
  async function ({ params }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/main/member${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const createMember = createAsyncThunk(
  "member/createMember",
  async function (resp, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    const formData = new FormData();
    if (resp.image) {
      formData.append("image", resp.image);
    }
    if (resp.document_image) {
      formData.append("document", resp.document_image);
    }
    if (resp.middle_name) {
      formData.append("middle_name", resp.middle_name);
    }
    if (resp.birth_date) {
      formData.append("birth_date", resp.birth_date);
    }
    if (resp.region) {
      formData.append("region", resp.region);
    }
    if (resp.club) {
      formData.append("club", resp.club);
    }
    if (resp.city) {
      formData.append("city", resp.city);
    }
    if (resp.ranking) {
      formData.append("ranking", resp.ranking);
    }
    if (resp.coach) {
      formData.append("coach", resp.coach);
    }

    if (resp.level) {
      formData.append("level", resp.level);
    }

    if (resp.other_role_data) {
      formData.append("other_role_data", resp.other_role_data);
    }

    formData.append("first_name", resp.first_name);
    formData.append("last_name", resp.last_name);
    formData.append("phone_number", resp.phone_number);
    formData.append("gender", resp.gender);
    formData.append("role", resp.role);

    try {
      const response = await fetch(`${URLBASE}/main/member/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const res = await response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const editMember = createAsyncThunk(
  "member/editMember",
  async function (resp, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    const formData = new FormData();
    if (resp.image?.name) {
      formData.append("image", resp.image);
    }
    if (resp.document?.name) {
      formData.append("document", resp.document);
    }
    if (resp.middle_name) {
      formData.append("middle_name", resp.middle_name);
    }
    if (resp.birth_date) {
      formData.append("birth_date", resp.birth_date);
    }

    if (typeof resp.region == "number") {
      formData.append("region", resp.region);
    }

    if (typeof resp.city == "number") {
      formData.append("city", resp.city);
    }

    if (typeof resp.club == "number") {
      formData.append("club", resp.club);
    }

    if (resp.ranking) {
      formData.append("ranking", resp.ranking);
    }
    if (typeof resp.coach == "id") {
      formData.append("coach", resp.coach);
    }

    if (resp.level) {
      formData.append("level", resp.level);
    }

    if (resp.other_role_data) {
      formData.append("other_role_data", resp.other_role_data);
    }

    formData.append("first_name", resp.first_name);
    formData.append("last_name", resp.last_name);
    formData.append("phone_number", resp.phone_number);
    formData.append("gender", resp.gender);
    formData.append("role", resp.role);

    try {
      const response = await fetch(`${URLBASE}/main/member/${resp.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const res = await response.json();

      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteMember = createAsyncThunk(
  "member/deleteMember",
  async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/main/member/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const recoverMember = createAsyncThunk(
  "member/recoverMember",
  async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/main/member/${id}/recover/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
