import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const registerMember = createAsyncThunk('registration/registerMember', async function ({ id }, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/members/registration/in/competition/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                "competition": id,
                "members": getState().registration.sendDataMember,
            })
        });
        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const getMembersRegister = createAsyncThunk('registration/getMembersRegister', async function ({ params }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
        const response = await fetch(`${URLBASE}/memberlist/for/registering/in/competition/${params}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);