import moment from "moment";
import { useForm } from "react-hook-form";
import { message, Upload } from "antd";
import classes from "./style.module.css";
import { useAthleteVisaMutation } from "hook/mutation";
import {
  CButton,
  CDatePicker,
  CInput,
  CLoader,
  CPopup,
  CUpload,
} from "components";

function CreateVisa({ title, setOpen, id, dataDetails }) {
  const athleteVisaMutation = useAthleteVisaMutation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: { given_date: "", expired_date: "" },
  });

  const beforeUpload = (file, field) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    if (isJpgOrPng) {
      field.onChange(file);
    }
  };

  const onSubmit = (e) => {
    let formData = new FormData();
    formData.append("name", e?.country);
    formData.append("expired_date", e?.expired_date);
    formData.append("given_date", e?.given_date);
    formData.append("athlete", id);
    formData.append("visa", e.file);

    const athleteVisa = athleteVisaMutation.mutateAsync(formData);
    athleteVisa
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.create__visa}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.flex}>
            <CUpload
              control={control}
              name="file"
              required={true}
              showUploadList={false}
              maxCount={1}
              text={"Upload File"}
              title={"Name File"}
              error={errors.file}
            />

            <div>
              <CDatePicker
                label={"Given date"}
                control={control}
                name="given_date"
                placeholder=""
                required={true}
                errors={errors}
                value={
                  watch().given_date != ""
                    ? moment(watch().given_date, `YYYY-MM-DD`)
                    : ""
                }
              />

              <div style={{ marginTop: 20 }}>
                <CDatePicker
                  label={"Expired date"}
                  control={control}
                  name="expired_date"
                  placeholder=""
                  required={true}
                  errors={errors}
                  value={
                    watch().expired_date != ""
                      ? moment(watch().expired_date, `YYYY-MM-DD`)
                      : ""
                  }
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <CInput
                  type="text"
                  label="Country"
                  name="country"
                  register={register}
                  errors={errors?.country}
                  required
                />
              </div>
            </div>
          </div>

          <div className={classes.btns}>
            <CButton
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"none"}
              fontSize={"14px"}
              lineHeight={"18px"}
              fontWeight={"600"}
              color={"#0062FF"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              onClick={() => reset()}
            >
              Clear
            </CButton>
            <CButton
              type="submit"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={athleteVisaMutation.isLoading ? "#ffffff" : "#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              height={"40px"}
              position={"relative"}
            >
              {athleteVisaMutation.isLoading ? (
                <CLoader width={"80px"} height={"80px"} />
              ) : (
                "Save"
              )}
            </CButton>
          </div>
        </form>
      </CPopup>
    </div>
  );
}

export default CreateVisa;
