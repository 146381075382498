import { Row, Col } from "antd";
import { CInput, CSelect, CUpload } from "components";

function InfoClub({ control, errors, register, cities, regions, onChange }) {
  return (
    <Row gutter={[40, 8]} style={{ marginBottom: "30px" }}>
      <Col span={4}>
        <CUpload
          control={control}
          name="image"
          required={true}
          showUploadList={false}
          maxCount={1}
          text={"Photo"}
          title={"Name Image"}
          type={true}
          error={errors.image}
          onChange={onChange}
        />
      </Col>
      <Col span={18}>
        <Row gutter={[32, 20]}>
          <Col span={12}>
            <CInput
              type="text"
              label="Name"
              name="name"
              register={register}
              errors={errors?.name}
              required
            />
          </Col>
          {localStorage.getItem("isLogin") == "federationadmin" ? (
            <Col span={12}>
              <div style={{ width: "368px", marginTop: "18px" }}>
                <CSelect
                  name="region"
                  items={regions}
                  control={control}
                  error={errors.region}
                  required={true}
                  label={"Region"}
                />
              </div>
            </Col>
          ) : null}

          <Col
            span={12}
            style={
              localStorage.getItem("isLogin") == "federationadmin"
                ? null
                : { marginTop: "20px" }
            }
          >
            <CSelect
              name="city"
              label="City"
              items={cities}
              control={control}
              error={errors.region}
              required={true}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InfoClub;
