import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const getAdmin = createAsyncThunk('admins/getAdmin', async function ({ id }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/admin/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const deleteAdmin = createAsyncThunk('admins/deleteAdmin', async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/admin/${id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const editAdmin = createAsyncThunk('admins/editAdmin', async function (resp, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    const formData = new FormData();
    formData.append("username", resp.username);
    formData.append("password", resp.password);
    formData.append("first_name", resp.first_name);
    formData.append("last_name", resp.last_name);
    formData.append("middle_name", resp.middle_name);
    formData.append("phone_number", resp.phone_number);
    formData.append("birth_date", resp.birth_date);

    if (resp.admin_type) {
        formData.append("admin_type", resp.admin_type);
    }
    if (resp.image?.name) {
        formData.append("image", resp.image);
    }
    if (resp.document_image?.name) {
        formData.append("document_image", resp.document_image);
    }
    if (resp.region) {
        formData.append("region", resp.region_id);
    }

    try {
        const response = await fetch(`${URLBASE}/admin/${resp.id}/`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData
        });

        const res = await response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const getAdmins = createAsyncThunk('admins/getAdmins', async function (location, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
        const response = await fetch(`${URLBASE}/admin${location}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const createAdmin = createAsyncThunk('admins/createAdmin', async function (resp, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    const formData = new FormData();
    formData.append("username", resp.username);
    formData.append("password", resp.password);
    formData.append("first_name", resp.first_name);
    formData.append("last_name", resp.last_name);
    formData.append("middle_name", resp.middle_name);
    formData.append("phone_number", resp.phone_number);
    formData.append("birth_date", resp.birth_date);
    formData.append("region", resp.region);
    formData.append("admin_type", resp.admin_type);
    formData.append("image", resp.image);
    formData.append("document_image", resp.document_image);

    try {
        const response = await fetch(`${URLBASE}/admin/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData
        });

        const res = await response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);