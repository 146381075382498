import moment from "moment";
import "moment/locale/zh-cn";
import { Col, Row, message } from "antd";
import { useEffect } from "react";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useAdminQuery, useRegionsQuery } from "hook/query";
import {
  CButton,
  CDatePicker,
  CInput,
  CPopup,
  CSelect,
  CUpload,
} from "components";
import { useDeleteAdminMutation, usePatchAdminMutation } from "hook/mutation";
import Loader from "components/Loader";

function EditAdmin({ setOpen, id, setId, adminsList }) {
  const regionsList = useRegionsQuery({});
  const deleteAdminMutation = useDeleteAdminMutation();
  const patchAdminMutation = usePatchAdminMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm();

  const { data, isFetching } = useAdminQuery({ id: id });
  useEffect(() => {
    setValue("image", data?.image);
    setValue("document_image", data?.document_image);
    setValue("first_name", data?.first_name);
    setValue("last_name", data?.last_name);
    setValue("middle_name", data?.middle_name);
    setValue("birth_date", data?.birth_date);
    setValue("phone_number", data?.phone_number);
    setValue("username", data?.username);
    setValue("password", data?.password);
    setValue("admin_type", data?.admin_type);
    setValue("region", data?.region);
    setValue("region_id", data?.region_id);
  }, [data]);

  const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("middle_name", data.middle_name);
    formData.append("phone_number", data.phone_number);
    formData.append("birth_date", data.birth_date);

    !!data.admin_type && formData.append("admin_type", data.admin_type);
    !!data.image?.name && formData.append("image", data.image);
    !!data.document_image?.name &&
      formData.append("document_image", data.document_image);
    !!data.region && formData.append("region", data.region_id);

    const patchAdmin = patchAdminMutation.mutateAsync({
      data: formData,
      id: id,
    });

    patchAdmin
      .then((res) => {
        message.success({
          content: `Changed Admin Information`,
          style: {
            right: "50%",
          },
        });
        reset();
        setId(null);
        adminsList.refetch();
        setOpen(false);
        removeHiddenWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAdmin = (e) => {
    const deleteAdmin = deleteAdminMutation.mutateAsync(id);
    deleteAdmin
      .then((res) => {
        message.success({
          content: `Delete Admin`,
          style: {
            right: "50%",
          },
        });
        reset();
        setId(null);
        adminsList.refetch();
        setOpen(false);
        removeHiddenWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPopup
      onClick={() => {
        setOpen(false);
        reset();
        setId(null);
        removeHiddenWindow();
      }}
      title={"Edit Admin"}
    >
      {isFetching ? (
        <div style={{ width: 400, height: 400 }}>
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.edit__admin}>
          <Row gutter={[50, 8]}>
            <Col span={13}>
              <div className={classes.flex}>
                <CUpload
                  control={control}
                  name="image"
                  required={true}
                  showUploadList={false}
                  maxCount={1}
                  text={"Photo"}
                  title={"Name Image"}
                  type={true}
                  error={errors.image}
                />
                <CUpload
                  control={control}
                  name="document_image"
                  required={true}
                  showUploadList={false}
                  maxCount={1}
                  text={"Document"}
                  title={"Name Document"}
                  type={false}
                  error={errors.document_image}
                />
              </div>
              <CInput
                type="text"
                label="Last name"
                name="last_name"
                register={register}
                errors={errors?.last_name}
                required
              />
              <CInput
                type="text"
                label="First name"
                name="first_name"
                register={register}
                errors={errors?.first_name}
                required
              />
              <CInput
                type="text"
                label="Middle name"
                name="middle_name"
                register={register}
                errors={errors?.middle_name}
                required
              />
              <CDatePicker
                label={"Birth date"}
                control={control}
                name="birth_date"
                placeholder=""
                required={true}
                errors={errors.birth_date}
                value={
                  watch().birth_date != ""
                    ? moment(watch().birth_date, `YYYY-MM-DD`)
                    : ""
                }
              />
            </Col>
            <Col style={{ paddingTop: "9px" }} span={11}>
              <CSelect
                label="Region/City"
                name="region"
                items={regionsList.data}
                control={control}
                error={errors.region}
                required={true}
              />

              <div style={{ margin: "30px 0" }}>
                <CSelect
                  label={"Admin Type"}
                  name="admin_type"
                  items={[
                    { province: "Federation Admin", id: "federationadmin" },
                    { province: "Region Admin", id: "regionadmin" },
                    { province: "Club Admin", id: "clubadmin" },
                    { province: "Accounters", id: "accounters" },
                  ]}
                  control={control}
                  error={errors.admin_type}
                  required={true}
                />
              </div>
              <CInput
                type="text"
                label="Login"
                name="username"
                register={register}
                errors={errors?.username}
                required
              />
              <CInput
                type="text"
                label="Password"
                name="password"
                register={register}
                errors={errors?.password}
                required
              />
              <CInput
                type="tel"
                label="Contacts"
                name="phone_number"
                register={register}
                errors={errors?.phone_number}
                required
                pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
                maxLength={13}
                minLength={13}
              />
              <div className={classes.flex}>
                <CButton
                  type="button"
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={"none"}
                  fontSize={"14px"}
                  lineHeight={"18px"}
                  fontWeight={"600"}
                  color={"#FC5A5A"}
                  border={"1px solid #FC5A5A"}
                  padding={"9px 35px"}
                  borderRadius={"10px"}
                  onClick={removeAdmin}
                >
                  Delete
                </CButton>
                <CButton
                  type="submit"
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={"#0062FF"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={"500"}
                  color={"#fff"}
                  border={"1px solid #0062FF"}
                  padding={"9px 35px"}
                  borderRadius={"10px"}
                  margin={"0 0 0 20px"}
                >
                  Edit
                </CButton>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </CPopup>
  );
}

export default EditAdmin;
