import { message } from "antd";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { authFech } from "store/slice/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import logoImage from "assets/images/logoLogin.png";
import { useLocation, useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (e) => {
    dispatch(authFech(e));
    navigate(location.state?.from?.pathname || "/", { replace: true });
  };

  return (
    <div className={classes.login}>
      <div className={classes.login__logo}>
        <img src={logoImage} alt="" />
        <p></p>
      </div>
      <h1>
        Добро пожаловать в <br /> Федерация Дзюдо Узбекистана
      </h1>
      <form className={classes.login__form} onSubmit={handleSubmit(onSubmit)}>
        <h3>
          Enter your login and <br /> password
        </h3>
        <Input
          type="text"
          label="Login"
          name="login"
          register={register}
          errors={errors?.login}
          required
        />
        <Input
          type="password"
          label="Password"
          name="password"
          register={register}
          errors={errors?.password}
          required
        />
        <Button
          fontFamily={"Poppins"}
          fontStyle={"normal"}
          background={"#0062FF"}
          fontSize={"14px"}
          lineHeight={"21px"}
          fontWeight={"500"}
          color={"#fff"}
          border={"1px solid #0062FF"}
          padding={"8px 99px"}
          borderRadius={"10px"}
          margin={"10px 0 0 0"}
          type={"submit"}
        >
          Login
        </Button>
      </form>
    </div>
  );
}

export default LoginPage;
