import { CButton } from "components";
import { Col, Row } from "antd";
import classes from "./style.module.css";
import { useEffect, useState } from "react";
import ImgView from "assets/images/compotetion.png";
import { getWeightCategories, gridCreate } from "hook/Requests";
import { Link, useNavigate, useParams } from "react-router-dom";

function ViewInfo({ competition }) {
  const todayDate = new Date().toJSON().slice(0, 10);
  const [weightCategory, setWightCategory] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getWeightCategories(params?.id, "male").then((res) => {
      setWightCategory(res?.data);
    });
  }, [params]);

  const handleClickDrawPage = () => {
    gridCreate(params?.id)
      .then((res) => {
        navigate(
          `/competitions/draw?competition_id=${params?.id}&gender=male&weight_id=${weightCategory?.[0]?.id}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.view_info}>
      <Row gutter={[8, 16]}>
        <Col span={8}>
          <div className={classes.title}>
            <img
              src={competition.data?.logo ? competition.data?.logo : ImgView}
              alt=""
            />
            <h5>{competition.data?.title}</h5>
          </div>
        </Col>
        <Col span={8}>
          <div className={classes.location}>
            <p>
              {competition.data?.region}
              {", "}
              {competition.data?.location}
            </p>
          </div>
        </Col>
        <Col span={8}>
          <div className={classes.date}>
            {todayDate >= competition.data?.starting_register &&
            todayDate <= competition.data?.end_register ? (
              <Link
                to={`/competitions/register?competition_id=${competition?.data?.id}`}
              >
                <CButton
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={"#0062FF"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={"500"}
                  color={"#fff"}
                  border={"1px solid #BABACA"}
                  padding={"9px 25px"}
                  borderRadius={"34px"}
                  margin={"0 16px 0 0"}
                >
                  Register
                </CButton>
              </Link>
            ) : null}

            {localStorage.getItem("isLogin") == "federationadmin" ? (
              <Link to={`/competitions/edit/${competition.data?.id}/`}>
                <CButton
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={"#0062FF"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={"500"}
                  color={"#fff"}
                  border={"1px solid #BABACA"}
                  padding={"9px 25px"}
                  borderRadius={"34px"}
                  margin={"0 16px 0 0"}
                >
                  Edit
                </CButton>
              </Link>
            ) : null}
            {todayDate >= competition.data?.start_day ? (
              <CButton
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #BABACA"}
                padding={"9px 25px"}
                borderRadius={"34px"}
                margin={"0 16px 0 0"}
                onClick={handleClickDrawPage}
              >
                Draw
              </CButton>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ViewInfo;
