import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetOrderMembersQuery = ({ enabled, page, id }) => {
  return useQuery(
    ["get-orders-members-query", page, id],
    async () => {
      const { data } = await request.get(`/members/in/order/`, {
        params: { page, id },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
