import "./inputfield.css";

const Input = ({
  value,
  label,
  name,
  placeholder,
  type,
  margin,
  width,
  disabled,
  required,
  register,
  errors,
  pattern,
  maxLength,
  minLength,
  min,
  max,
  textTransformation,
}) => (
  <div className="InputField" style={{ margin, width }}>
    {label && <label htmlFor="input-field">{label}</label>}
    <br />
    <input
      style={{ textTransform: textTransformation }}
      type={type}
      value={value}
      name={name}
      className={`form-control ${errors ? "error" : null}`}
      placeholder={placeholder}
      disabled={disabled}
      {...register(name, {
        required: required,
        maxLength: maxLength,
        minLength: minLength,
        pattern: pattern,
        min: min,
        max: max,
      })}
    />
  </div>
);

export default Input;
