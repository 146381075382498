import React, { useState } from "react";
import classes from "./style.module.css";
import { useParams } from "react-router-dom";
import {
  useNationalAthleteMedicalDatas,
  useNationalTeamMember,
} from "hook/query";
import {
  CreateBodyList,
  CreateDopingTest,
  CreateVitamins,
  NationalTeamUserInfo,
} from "container";
import FileSaver from "file-saver";

function MedicalCertificate() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [bopen, setBOpen] = useState(false);
  const [dopen, setDOpen] = useState(false);

  const dataUser = useNationalTeamMember({
    enabled: true,
    id: id,
  });

  const otherData = useNationalAthleteMedicalDatas({
    enabled: true,
    athlete_id: id,
  });

  const handleDownloadBodyCheckLit = () => {
    FileSaver.saveAs(
      `${otherData?.data?.body_check_list?.body_check_list}`,
      `${otherData?.data?.body_check_list?.body_check_list}`
    );
  };

  const handleDownloadDopingTest = () => {
    FileSaver.saveAs(
      `${otherData?.data?.dopping_test?.doping_test}`,
      `${otherData?.data?.dopping_test?.doping_test}`
    );
  };

  return (
    <div className={classes.medical__certificate}>
      <NationalTeamUserInfo
        data={dataUser.data}
        title={"Medical certificate"}
      />
      <div className={classes.flex} style={{ marginTop: "32px" }}>
        <div className={classes.body__list}>
          <div className={classes.flex}>
            <h4>Body checks list</h4>
            <button type="button" onClick={() => setBOpen(true)}>
              Edit
            </button>
          </div>
          <div className={classes.flex__s}>
            {!!otherData.data?.body_check_list?.body_check_list ? (
              <div
                className={classes.document}
                onClick={handleDownloadBodyCheckLit}
              >
                <p>Document</p>
              </div>
            ) : null}

            <div style={{ width: "100%" }}>
              {!!otherData.data?.body_check_list?.body_check_list_date ? (
                <div className={classes.date}>
                  <p>Date</p>
                  <h6>
                    {otherData.data?.body_check_list?.body_check_list_date}
                  </h6>
                </div>
              ) : null}
              {!!otherData.data?.body_check_list?.body_check_list_status ? (
                <div className={classes.result}>
                  <p>Result</p>
                  <h6>
                    {otherData.data?.body_check_list?.body_check_list_status}
                  </h6>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classes.doping__test}>
          <div className={classes.flex}>
            <h4>Doping test</h4>
            <button type="button" onClick={() => setDOpen(true)}>
              Edit
            </button>
          </div>
          <div className={classes.flex__s}>
            {!!otherData.data?.dopping_test?.doping_test ? (
              <div
                className={classes.document}
                onClick={handleDownloadDopingTest}
              >
                <p>Document</p>
              </div>
            ) : null}
            <div style={{ width: "100%" }}>
              {!!otherData.data?.dopping_test?.doping_test_date ? (
                <div className={classes.date}>
                  <p>Date</p>
                  <h6>{otherData.data?.dopping_test?.doping_test_date}</h6>
                </div>
              ) : null}

              {!!otherData.data?.dopping_test?.doping_test_status ? (
                <div className={classes.result}>
                  <p>Result</p>
                  <h6>{otherData.data?.dopping_test?.doping_test_status}</h6>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.vitamins}>
        <div className={classes.flex}>
          <h4>Medicines and vitamins</h4>
          <button type="button" onClick={() => setOpen(true)}>
            + Add
          </button>
        </div>
        <div className={classes.table}>
          {otherData.data?.tablets?.map((d) => (
            <div className={classes.tr} key={d?.id}>
              <h5>{d.tablet}</h5>
              <h5>{d.date}</h5>
            </div>
          ))}
        </div>
      </div>

      {open ? (
        <CreateVitamins
          title={"Medicines and vitamins"}
          setOpen={setOpen}
          id={id}
          otherData={otherData}
        />
      ) : null}

      {bopen ? (
        <CreateBodyList
          title={"Body check list"}
          id={id}
          otherData={otherData}
          setOpen={setBOpen}
        />
      ) : null}

      {dopen ? (
        <CreateDopingTest
          title={"Doping test"}
          id={id}
          otherData={otherData}
          setOpen={setDOpen}
        />
      ) : null}
    </div>
  );
}

export default MedicalCertificate;
