import { Col, Row } from "antd";
import classes from "./style.module.css";

function Information({ competition }) {
  return (
    <div className={classes.information}>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <div className={classes.block}>
            <h4>Age category (Male)</h4>
            <div className={classes.text}>
              {competition.data?.for_junior_male && <p>Juniors</p>}
              {competition.data?.for_cadet_male && <p>Cadet</p>}
              {competition.data?.for_senior_male && <p>Senior</p>}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={classes.block}>
            <h4>Age category (Female)</h4>
            <div className={classes.text}>
              {competition.data?.for_junior_female && <p>Juniors </p>}
              {competition.data?.for_cadet_female && <p>Cadet </p>}
              {competition.data?.for_senior_female && <p>Senior</p>}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={classes.block}>
            <h4>Weight Category (Male)</h4>
            <div className={classes.text}>
              <p>
                {competition.data?.weight_category_male?.map((weight) => (
                  <span key={weight.id}>{weight.weight}, </span>
                ))}
              </p>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={classes.block}>
            <h4>Weight Category (Female)</h4>
            <div className={classes.text}>
              <p>
                {competition.data?.weight_category_female?.map((weight) => (
                  <span key={weight.id}>{weight.weight}, </span>
                ))}
              </p>
            </div>
          </div>
        </Col>
        {/* <Col span={8}>
          <div className={classes.block}>
            <h4>Videos</h4>
            <div className={classes.text}>
              {competitions.competitionDetail.videos?.map((video, index) => (
                <p className="video" key={index}>
                  <a href={video.video}>{video.id}-video</a>
                </p>
              ))}
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  );
}

export default Information;
