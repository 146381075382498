import { useMutation, useQueryClient } from "react-query";
import { request } from "services/api";

export const usePatchMemberMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, id }) => {
      return request.patch(`/main/member/${id}/`, data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries("members");
      },
    }
  );
};
