import { message } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

const initialState = {
    isLoggedIn: localStorage.getItem("isLogin") != undefined
        ? localStorage.getItem("isLogin")
        : "no access",
    status: null,
    data: [],
}

export const authFech = createAsyncThunk('auth/post', async function (resp, { rejectWithValue }) {
    try {
        const response = await fetch(`${URLBASE}/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resp)
        });
        const res = response.json()

        if (!response.ok) {
            throw new Error(response.status);
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut: (state, action) => {
            state.isLoggedIn = "no access"
            localStorage.setItem("isLogin", "no access");
            localStorage.clear();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authFech.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(authFech.fulfilled, (state, action) => {
            state.isLoggedIn = action.payload.user_data.admin_type;
            state.data = action.payload;
            state.status = "success"
            localStorage.setItem("isLogin", action.payload.user_data.admin_type);
            localStorage.setItem("access", action.payload.access);
            localStorage.setItem("refresh", action.payload.refresh);
            localStorage.setItem("userInfo", JSON.stringify(action.payload.user_data));

        });
        builder.addCase(authFech.rejected, (state, action) => {
            message.error({
                content: 'Username or password is incorrect',
                style: {
                    right: "50%",
                    marginLeft: "80%",
                }
            });
            state.status = "error";
        });
    },
})

export const { logOut } = authSlice.actions;
export default authSlice.reducer;