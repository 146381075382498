import { Col, Row } from "antd";
import classes from "./style.module.css";
import { useEffect, useState } from "react";
import { useRegionsQuery } from "hook/query";
import { CButton, CDropdownInput, CInputField } from "components";

function Quotas({ quotas, setQuotas }) {
  const regionsList = useRegionsQuery({});
  const [regions, setRegions] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setRegions(regionsList.data);
  }, [regionsList.isSuccess]);

  const handleInputChangeRegion = (value, title, index, province, id) => {
    const region_id = "region";
    const region_name = "province";

    const list = [...quotas];
    list[index][region_id] = value;
    list[index][region_name] = title;
    setQuotas(list);

    if (!value || !title) {
      setErrors({ ...errors, [index]: "Dropdown values cannot be empty" });
      return;
    } else {
      setErrors({ ...errors, [index]: null });
    }

    let newRegions = regions.filter((region) => region.id !== value);
    setRegions(newRegions);

    if (province) {
      setRegions([...newRegions, { province: province, id: id }]);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...quotas];
    list[index][name] = value;
    setQuotas(list);
  };

  const handleRemoveClick = (index, province, id) => {
    const list = [...quotas];
    list.splice(index, 1);
    setQuotas(list);

    if (province) {
      setRegions([...regions, { province: province, id: id }]);
    }
  };

  const handleAddClick = () => {
    setQuotas([
      ...quotas,
      {
        quota_for_male: "",
        quota_for_female: "",
        region: "",
      },
    ]);
  };
  return (
    <div className={classes.quotas}>
      <h4>Quotas</h4>
      {quotas?.map((x, i) => (
        <Row style={{ marginBottom: "20px" }} key={i} gutter={[32, 8]}>
          <Col span={11}>
            <CDropdownInput
              label={"Region"}
              setState={(e) =>
                handleInputChangeRegion(
                  e.id,
                  e.province,
                  i,
                  x.province,
                  x.region
                )
              }
              value={x.province}
              items={regions}
              required
            />
          </Col>
          <Col span={11}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <CInputField
                  onChange={(e) => handleInputChange(e, i)}
                  type="number"
                  label="Male"
                  name={"quota_for_male"}
                  value={x?.quota_for_male}
                  margin={"0"}
                />
              </Col>
              <Col span={12}>
                <CInputField
                  onChange={(e) => handleInputChange(e, i)}
                  type="number"
                  label="Female"
                  name="quota_for_female"
                  value={x.quota_for_female}
                  margin={"0"}
                />
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <CButton
              type={"button"}
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#ffffff"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#babaca"}
              border={"1px dashed #babaca"}
              padding={"9px 16px"}
              borderRadius={"10px"}
              margin={"20px 0 0 0"}
              onClick={() => handleRemoveClick(i, x.province, x.region)}
            >
              x
            </CButton>
          </Col>
        </Row>
      ))}
      <Row gutter={[32, 8]}>
        <Col span={12}>
          <CButton
            type={"button"}
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#babaca"}
            border={"1px dashed #babaca"}
            padding={"9px 25px 9px 16px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={handleAddClick}
          >
            +Add exception
          </CButton>
        </Col>
      </Row>
    </div>
  );
}

export default Quotas;
