import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetFlightsIdQuery = ({ enabled, page, id }) => {
  return useQuery(
    ["national-team-flights-id", page, id],
    async () => {
      const { data } = await request.get(`/national/team/athlete/flight/${id}/`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
