import React from "react";
import classes from "./style.module.css";
import { CLoader } from "components";
import { ContributionCard } from "container";
import { useGetContributionsQuery } from "hook/query";

function ContributionPage() {
  const listContributions = useGetContributionsQuery({ enabled: true });
  if (listContributions.isLoading) return <CLoader />;

  return (
    <div className={classes.contribution}>
      <div className={classes.header}>
        <h4>Member fee</h4>
      </div>
      <div>
        {listContributions.data?.map((item, index) => (
          <ContributionCard key={index} contribution={item} />
        ))}
      </div>
    </div>
  );
}

export default ContributionPage;
