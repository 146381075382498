import { useForm } from "react-hook-form";
import classes from "./style.module.css";
import { CButton, CInput } from "components";
import { useContributionMutation } from "hook/mutation";
import { message } from "antd";

function ContributionCard({ contribution }) {
  const contributionMutation = useContributionMutation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: contribution });

  const onSubmit = (e) => {
    const data = { amount: e.amount };
    const contribution = contributionMutation.mutateAsync({
      data: data,
      id: e.id,
    });
    contribution
      .then((res) => {
        setValue("contribution", res.data);
        message.success({
          content: `Changed: ${res.data?.type}`,
          style: {
            right: "50%",
          },
        });
      })
      .catch((err) => {
        message.error({ content: `Error` });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.card}>
      <CInput
        type="number"
        label="Payment amount (sum)"
        name="amount"
        register={register}
        min={0}
        errors={errors?.amount}
      />
      <CInput
        type="text"
        label="Payment type"
        name="type"
        register={register}
        errors={errors?.type}
        disabled
      />
      <CButton
        type={"submit"}
        fontFamily={"Poppins"}
        fontStyle={"normal"}
        background={"#0062FF"}
        fontSize={"14px"}
        lineHeight={"21px"}
        fontWeight={"500"}
        color={"#fff"}
        border={"1px solid #0062FF"}
        padding={"9px 25px"}
        borderRadius={"10px"}
      >
        Edit
      </CButton>
    </form>
  );
}

export default ContributionCard;
