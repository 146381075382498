import React from "react";

export default function LinkButton({ children, pathName, onClick, type }) {
  const activeStyle = {
    textDecoration: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#ffffff",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "500",
    background: "#0062FF",
    border: "1px solid #BABACA",
    padding: "9px 25px",
    borderRadius: "10px",
    margin: "0 16px 10px 0",
    cursor: "default",
  };

  const inActiveStyle = {
    textDecoration: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#696974",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "500",
    background: "#fff",
    border: "1px solid #BABACA",
    padding: "9px 25px",
    borderRadius: "10px",
    margin: "0 16px 10px 0",
    cursor: "default",
  };
  return (
    <div
      onClick={() => onClick(pathName)}
      style={pathName == type ? activeStyle : inActiveStyle}
    >
      {children}
    </div>
  );
}
