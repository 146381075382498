import React, { useEffect, useState } from "react";
import { Avatar, List } from "antd";
import { useSearchParams } from "react-router-dom";
import {
  getCompetitionRegisteredMembers,
  patchPoolMember,
} from "../../hook/Requests";
import Popup from "./Popup";
import { PlusCircleOutlined } from "@ant-design/icons";

const BracketModal = ({ isModalOpen, setRefetch, setIsModalOpen }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    const getMembers = getCompetitionRegisteredMembers(searchParams, page);
    getMembers
      .then((res) => {
        setLoading(false);
        setLastPage(res?.data?.total_pages);
        setData((prev) => [...prev, ...res?.data?.results]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [page]);

  const handleFixMember = (id) => {
    const data1 = {
      player1: id.id,
    };
    const data2 = {
      player2: id.id,
    };
    const addMemberCompetition = patchPoolMember(
      isModalOpen?.playerIndex === 1 ? data1 : data2,
      isModalOpen?.poolId
    );
    addMemberCompetition
      .then((res) => {
        setRefetch((prev) => !prev);
        setIsModalOpen("");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  return (
    <>
      <Popup
        title="Members list"
        onClick={() => setIsModalOpen("")}
        destroyOnClose={true}
        padding={"20px"}
      >
        <div
          style={{
            height: "500px",
            overflowY: "scroll",
            padding: "0 15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <List
            className="list__result"
            loading={isLoading}
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item?.id}>
                <List.Item.Meta
                  avatar={<Avatar src={item?.image} />}
                  title={
                    <div>
                      {item?.first_name} {item?.last_name}
                    </div>
                  }
                  description={item?.birth_date}
                  style={{ display: "flex", alignItems: "center" }}
                />
                <div
                  onClick={() => handleFixMember(item)}
                  style={{ cursor: "pointer" }}
                >
                  Add
                </div>
              </List.Item>
            )}
          />
          {page !== lastPage ? (
            <PlusCircleOutlined
              onClick={() => {
                setPage((prev) => prev + 1);
              }}
              style={{ fontSize: "25px", fill: "rgba(0, 0, 0, 0.85)" }}
            />
          ) : null}
        </div>
      </Popup>
    </>
  );
};

export default BracketModal;
