import { useState } from "react";
import { Radio, Space } from "antd";
import classes from "./style.module.css";
import { CButton, CPopup } from "components";

function MemberProfModal({ open, setOpen }) {
  const [memberState, setMemberState] = useState("");

  if (open !== "choiceTypeMember") return null;

  return (
    <CPopup
      title={"What kind of member do you \n want to create?"}
      onClick={() => setOpen("")}
    >
      <Radio.Group
        className={classes.prof__modal}
        onChange={(e) => setMemberState(e.target.value)}
        value={memberState}
      >
        <Space direction="vertical">
          <Radio value={`sportsman`}>Athlete</Radio>
          <Radio value={`coach`}>Coach</Radio>
          <Radio value={`referee`}>Referee</Radio>
          <Radio value={`doctor`}>Doctor</Radio>
          <Radio value={`others`}>Others</Radio>
        </Space>
      </Radio.Group>

      <CButton
        onClick={() => setOpen(memberState)}
        width={`100%`}
        border={`1px solid #0062FF`}
        padding={`9px 0`}
        margin={`30px 0 10px 0`}
        color={`#fff`}
        background={`#0062FF`}
        borderRadius={`10px`}
        fontFmily={`Poppins`}
        fontStyle={`normal`}
        fontWeight={`600`}
        fontSize={`14px`}
        lineHeight={`21px`}
      >
        Next
      </CButton>
    </CPopup>
  );
}

export default MemberProfModal;
