import { useEffect, useState } from "react";
import classes from "./style.module.css";
import { Table, Pagination } from "antd";
import noImage from "assets/images/notfound/nopic.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import PaginationNumber from "components/Pagination/PaginationNumber";
import FileSaver from "file-saver";
import {
  useGetDowloadFileQuery,
  useGetOrderInfoQuery,
  useGetOrderMembersQuery,
} from "hook/query";
import { CButton, CLoader, CPopup } from "components";
import {
  EditCoach,
  EditDoctor,
  EditHost,
  EditReferee,
  EditSportsman,
  PopupPayment,
} from "container";
import { usePatchOrderMutation } from "hook/mutation";

function PaymentDetailPage() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [mamberId, setmamberId] = useState("");
  const [openEdit, setmamberEdit] = useState("");
  const [changeList, setChangeList] = useState([]);
  const [active, setActive] = useState(false);
  const [popup, setPopup] = useState(false);
  const whois = localStorage.getItem("isLogin");

  const patchOrderMutation = usePatchOrderMutation();

  const navigate = useNavigate();
  const [deletePayment, setDeletePayment] = useState(false);
  const listOrderMembers = useGetOrderMembersQuery({ enabled: true, id: id });
  const dataOrder = useGetOrderInfoQuery({ enabled: true, id: id });
  const fileDowload = useGetDowloadFileQuery({ enabled: true, id: id });

  useEffect(() => {
    setChangeList([dataOrder.data]);
  }, [dataOrder.isSuccess]);

  const SubStr = (e) => {
    const newStr = e?.substr(0, 10);
    return newStr;
  };

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      render: (_, record) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "42px",
              height: "42px",
              marginRight: "15px",
              objectFit: "cover",
            }}
            src={record.image ? record.image : noImage}
            alt=""
          />
          {record?.first_name} {record?.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "#id",
      dataIndex: "id",
    },
    {
      title: "role",
      dataIndex: "role",
      render: (status) => (
        <div
          className="role"
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div
          onClick={() => {
            setmamberId(record?.id);
            setmamberEdit(record?.role);
            hiddenWindow();
          }}
        >
          <IcShape />
        </div>
      ),
    },
  ];

  const columnsOrder = [
    {
      title: "#id",
      dataIndex: "id",
    },
    {
      title: "created at",
      dataIndex: "created_at",
      render: (record) => <div>{SubStr(record)}</div>,
    },

    {
      title: "Type",
      dataIndex: "type",
      render: (record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {record ? record : "-"}
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Number of members",
      dataIndex: "count_member",
    },
    {
      title: "",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{ fontFamily: "Poppins", fontWeight: "500" }}
        >
          {status}
        </div>
      ),
    },
  ];

  const handleDownload = () => {
    setActive(true);
    function setLoader() {
      setActive(false);
    }

    setTimeout(setLoader, 1000);
    FileSaver.saveAs(
      `${fileDowload?.data?.chek}`,
      `${fileDowload?.data?.chek}`
    );
  };

  const changePay = (e) => {
    const patchOrder = patchOrderMutation.mutateAsync({
      id: id,
      data: { status: e },
    });
    patchOrder
      .then((res) => {
        navigate("/payments", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (listOrderMembers.isLoading) return <CLoader />;

  return (
    <div className={classes.payment}>
      <div className={classes.header}>
        <h4>
          Payments <span> &gt; ID {id}</span>
        </h4>
        <div className={classes.flex}>
          {dataOrder.data?.status == "cancelled" ? null : (
            <CButton
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#fc5a5a"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #3DD598"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              onClick={() => setDeletePayment(true)}
            >
              Cancel
            </CButton>
          )}
          <CButton
            type={"button"}
            className={classes.file__btn}
            onClick={handleDownload}
            background={active ? "#ffffff" : "#0062FF"}
          >
            {active ? (
              <CLoader width={"80px"} height={"80px"} />
            ) : (
              "Print report"
            )}
          </CButton>
          {whois == "federationadmin" && dataOrder.data?.status != "paid" ? (
            <CButton
              type={"button"}
              className={"createBtn"}
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"rgb(61, 213, 152)"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid rgb(61, 213, 152)"}
              padding={"9px 16px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              onClick={() => setPopup(true)}
            >
              Paid
            </CButton>
          ) : null}
        </div>
      </div>

      <div className={classes.table}>
        <Table
          rowKey={(record) => record?.id}
          rowSelection={{
            type: "checkbox",
          }}
          columns={columnsOrder}
          dataSource={changeList}
          pagination={false}
        />
      </div>

      <div className={`${classes.table} ${classes.t2}`}>
        <Table
          rowKey={(record) => record?.first_name}
          rowSelection={{
            type: "checkbox",
          }}
          columns={columns}
          dataSource={listOrderMembers.data?.results}
          pagination={false}
        />
        {listOrderMembers.data?.results?.length > 0 ? (
          <div className={classes.pagination}>
            <PaginationNumber
              count={page}
              counts={listOrderMembers.data?.count}
              pages={listOrderMembers.data?.total_pages}
            />
            <Pagination
              defaultCurrent={page}
              total={listOrderMembers.data?.total_pages * 10}
              showSizeChanger={false}
              onChange={setPage}
            />
          </div>
        ) : null}
      </div>

      {popup ? (
        <PopupPayment
          title={""}
          close={() => setPopup(false)}
          onClick={() => changePay("paid")}
        />
      ) : null}
      {deletePayment ? (
        <CPopup onClick={() => setDeletePayment(false)}>
          <h3 style={{ textAlign: "center", marginBottom: "30px" }}>
            Are you sure to cancel the payment
          </h3>
          <div className={classes.flex} style={{ justifyContent: "center" }}>
            <CButton
              type={"button"}
              className={"createBtn"}
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 35px"}
              borderRadius={"10px"}
              margin={"0"}
              onClick={() => changePay("cancelled")}
            >
              Sure
            </CButton>
          </div>
        </CPopup>
      ) : null}

      <EditHost
        open={openEdit}
        setOpen={setmamberEdit}
        id={mamberId}
        setId={setmamberId}
      />
      <EditCoach
        open={openEdit}
        setOpen={setmamberEdit}
        id={mamberId}
        setId={setmamberId}
        disabled
      />
      <EditDoctor
        open={openEdit}
        setOpen={setmamberEdit}
        id={mamberId}
        setId={setmamberId}
        disabled
      />
      <EditReferee
        open={openEdit}
        setOpen={setmamberEdit}
        id={mamberId}
        setId={setmamberId}
        disabled
      />
      <EditSportsman
        open={openEdit}
        setOpen={setmamberEdit}
        id={mamberId}
        setId={setmamberId}
        disabled
      />
    </div>
  );
}

export default PaymentDetailPage;
