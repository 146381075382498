import React from "react";
import classes from "./style.module.css";
import { Controller } from "react-hook-form";
import { DatePicker } from "antd";

function CDatePicker({
  label,
  placeholder,
  control,
  defaultValue,
  value,
  name,
  errors,
  required,
  error,
}) {
  return (
    <div className={classes.date__picker}>
      <label className="label" htmlFor="input-field">
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field }) => (
          <DatePicker
            placeholder={placeholder}
            defaultValue={defaultValue}
            className={`${classes.date__input} ${errors && classes.error}`}
            onChange={(date, dateString) => {
              field.onChange(dateString);
            }}
            value={value}
          />
        )}
      />
    </div>
  );
}

export default CDatePicker;
