import moment from "moment";
import "moment/locale/zh-cn";
import { useState } from "react";
import { useSearchClubsQuery } from "hook/query";
import { usePostMemberMutation } from "hook/mutation";
import { useForm } from "react-hook-form";
import { Col, Row } from "antd";
import {
  CButton,
  CDatePicker,
  CInput,
  CPopup,
  CSearch,
  CSelect,
  CUpload,
} from "components";

function CreateReferee({ open, setOpen, memberList }) {
  const postMemberMutation = usePostMemberMutation();
  const [listClubs, setListClubs] = useState([]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      phone_number: "+998",
      birth_date: "",
      role: "referee",
    },
  });

  const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
  };

  // const listClub = useSearchClubsQuery({
  //   enabled: true,
  //   club: watch().club,
  // });

  // useEffect(() => {
  //   setListClubs([]);
  //   listClub.data?.results?.map((l) => {
  //     setListClubs((prevArray) => [
  //       ...prevArray,
  //       { label: l?.name, value: l.id },
  //     ]);
  //   });
  // }, [listClub.isSuccess]);

  const onSubmit = (e) => {
    const formData = new FormData();
    formData.append("image", e?.image);
    formData.append("document", e?.document_image);
    // formData.append("club", e?.club);
    formData.append("birth_date", e?.birth_date);
    formData.append("first_name", e?.first_name);
    formData.append("gender", e?.gender);
    formData.append("middle_name", e?.middle_name);
    formData.append("last_name", e?.last_name);
    formData.append("phone_number", e?.phone_number);
    formData.append("role", e?.role);

    const postMember = postMemberMutation.mutateAsync(formData);
    postMember
      .then((res) => {
        reset();
        setOpen("");
        removeHiddenWindow();
        memberList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (open !== "referee") return null;

  return (
    <CPopup
      onClick={() => {
        setOpen("");
        reset();
      }}
      title={"Create Referee"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[33, 8]}>
          <Col span={13}>
            <div className="flexRow">
              <CUpload
                control={control}
                name="image"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Photo"}
                title={"Name Image"}
                type={true}
                error={errors.image}
              />
              <CUpload
                control={control}
                name="document_image"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Document"}
                title={"Name Document"}
                type={false}
                error={errors.document_image}
              />
            </div>
            <CInput
              type="text"
              label="Last name"
              name="last_name"
              register={register}
              errors={errors?.last_name}
              required
            />
            <CInput
              type="text"
              label="First name"
              name="first_name"
              register={register}
              errors={errors?.first_name}
              required
            />
            <CInput
              type="text"
              label="Middle name"
              name="middle_name"
              register={register}
              errors={errors?.middle_name}
              required
            />
            <CDatePicker
              label={"Birth date"}
              control={control}
              name="birth_date"
              placeholder=""
              required={true}
              errors={errors.birth_date}
              value={
                watch().birth_date != ""
                  ? moment(watch().birth_date, `YYYY-MM-DD`)
                  : ""
              }
            />
          </Col>
          <Col style={{ paddingTop: "9px" }} span={11}>
            {/* <CSearch
              label={"Club"}
              name="club"
              items={listClubs}
              control={control}
              error={errors.club}
            /> */}
            <div style={{ marginBottom: "30px" }}>
              <CSelect
                label={"Gender"}
                name="gender"
                items={[
                  { province: "Male", id: "male" },
                  { province: "Female", id: "female" },
                ]}
                control={control}
                error={errors.gender}
                required={true}
              />
            </div>
            <CInput
              type="tel"
              label="Contacts"
              name="phone_number"
              register={register}
              errors={errors?.phone_number}
              required
              pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
              maxLength={13}
              minLength={13}
            />
            <div className="flex-row">
              <CButton
                type="button"
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"none"}
                fontSize={"14px"}
                lineHeight={"18px"}
                fontWeight={"600"}
                color={"#0062FF"}
                border={"1px solid #0062FF"}
                padding={"9px 35px"}
                borderRadius={"10px"}
                onClick={() => {
                  reset();
                }}
              >
                Clear
              </CButton>
              <CButton
                type="submit"
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 35px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
              >
                Create
              </CButton>
            </div>
          </Col>
        </Row>
      </form>
    </CPopup>
  );
}

export default CreateReferee;
