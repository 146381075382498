import { useEffect, useState } from "react";
import "../assets/style/competitions.css";
import Bracket from "../components/UI/Brackets/Bracket";
import { useLocation, useSearchParams } from "react-router-dom";
import { getWeightCategories } from "../hook/Requests";
import qs from "qs";
import AntDropdown from "../components/AntDropdown/AntDropdown";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const options = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

function CompetitionsDrawPage() {
  const [weightCategory, setWightCategory] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    Promise.all([
      getWeightCategories(
        searchParams.get("competition_id"),
        searchParams.get("gender")
      ),
    ])
      .then((results) => {
        setWightCategory(results?.[0]?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="competitionDetail">
        <div className="competitionsBtn">
          <h4>Draw</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
              marginLeft: "auto",
            }}
          >
            <AntDropdown options={options} queryName="gender" />
            <AntDropdown
              options={weightCategory.map((item) => ({
                label: item?.weight,
                value: item?.id,
              }))}
              queryName="weight_id"
              placeholder="Weight"
            />
          </div>
        </div>
        <div className="competitionsBorder">
          <Bracket />
        </div>
      </div>
    </DndProvider>
  );
}

export default CompetitionsDrawPage;
