import classes from "./style.module.css";
import { Col, DatePicker, Row } from "antd";
import { CInput, CSelect, CUpload } from "components";
import dayjs from "dayjs";
import { useRegionsQuery } from "hook/query";

function MainInfo({
  control,
  errors,
  register,
  startDay,
  setStartDay,
  endDay,
  setEndDay,
  endRegisterDay,
  setEndRegisterDay,
  startRegisterDay,
  setStartRegisterDay,
}) {
  const regionsList = useRegionsQuery({});
  const { RangePicker } = DatePicker;

  return (
    <div className={classes.main_info}>
      <h5>Main information</h5>
      <Row gutter={[0, 8]}>
        <Col span={5}>
          <CUpload
            control={control}
            name="logo"
            showUploadList={false}
            maxCount={1}
            text={"Photo"}
            title={"Name Image"}
            type={true}
            error={errors.logo}
          />
        </Col>
        <Col span={17}>
          <Row gutter={[48, 0]}>
            <Col span={12}>
              <CInput
                type="text"
                label="Title of the competition"
                name="title"
                register={register}
                errors={errors?.title}
                required
              />
            </Col>
            <Col span={12}>
              <CInput
                type="text"
                label="Short name"
                name="short_name"
                register={register}
                errors={errors?.short_name}
                required
              />
            </Col>
            <Col span={12}>
              <CSelect
                label="Region"
                name="region"
                items={regionsList?.data}
                control={control}
                error={errors.region}
                required
              />
            </Col>
            <Col span={12}>
              <CInput
                type="text"
                label="Location"
                name="location"
                register={register}
                errors={errors?.location}
                margin={0}
                required
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[48, 8]} style={{ marginTop: 10 }}>
        <Col span={6} className={classes.range}>
          <label htmlFor="input-field">Competition start and end day</label>
          <RangePicker
            value={
              startDay || endDay
                ? [dayjs(startDay), dayjs(endDay)]
                : [null, null]
            }
            className={classes.range_picker}
            name={"start_end_day"}
            format={"YYYY-MM-DD"}
            required
            onChange={(dates, dateStrings) => {
              setStartDay(dateStrings[0]);
              setEndDay(dateStrings[1]);
            }}
          />
        </Col>
        <Col span={6} className={classes.range}>
          <label htmlFor="input-field">Registration start and end day</label>
          <RangePicker
            value={
              startRegisterDay || endRegisterDay
                ? [dayjs(startRegisterDay), dayjs(endRegisterDay)]
                : [null, null]
            }
            className={classes.range_picker}
            name={"start_end_register_day"}
            format={"YYYY-MM-DD"}
            required
            onChange={(dates, dateStrings) => {
              setStartRegisterDay(dateStrings[0]);
              setEndRegisterDay(dateStrings[1]);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default MainInfo;
