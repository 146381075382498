import { useState } from "react";
import classes from "./style.module.css";
import { Table } from "antd";
import { CLoader, CButton, CDropdown } from "components";
import noImage from "assets/images/notfound/nopic.jpg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IcPlus } from "assets/images/icons/ic_Plus.svg";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { useNationalTeamMember, useNationalTeamMembers } from "hook/query";
import {
  CreateMemberForNationalTeam,
  EditMemberForNationalTeam,
  PaginationNumber,
} from "container";

function NationalTeam() {
  const navigate = useNavigate();
  const whois = localStorage.getItem("isLogin");

  const [page, setPage] = useState(1);
  const [gender, setGender] = useState({});
  const [open, setOpen] = useState(false);

  const [memberID, setMemberID] = useState(0);

  const nationalTeamMember = useNationalTeamMembers({
    enabled: true,
    page: page,
    gender: gender?.id,
  });

  const member = useNationalTeamMember({
    enabled: !!memberID,
    id: memberID,
  });

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      width: "500px",
      render: (_, record) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
        >
          <img src={record.image ? record.image : noImage} alt="" />
          {record?.first_name} {record?.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "#id",
      dataIndex: "id",
    },
    {
      title: "weight",
      dataIndex: "weight",
      render: (weight) => <>{weight} kg</>,
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div style={{ cursor: "pointer" }}>
          <IcShape
            onClick={() => {
              navigate(`/national-team/${record.id}`);
            }}
          />
        </div>
      ),
    },
  ];

  const clearSearchParams = () => {
    setGender({});
    setPage(1);
  };

  if (nationalTeamMember.isLoading) return <CLoader />;

  return (
    <div className={classes.national_team}>
      <div>
        <div className={classes.top}>
          <h4>National team</h4>
          <div className={classes.right}>
            <CDropdown
              text={"Sort by:"}
              value={gender?.province}
              setState={setGender}
              items={[
                { province: "Men", id: "male" },
                { province: "Women", id: "female" },
              ]}
              margin={"0 10px"}
              placeholder={"gender"}
            />

            <CButton
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#ffffff"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"none"}
              padding={"4px 9px 0px 10px"}
              borderRadius={"10px"}
              margin={"0 0 0 10px"}
              onClick={clearSearchParams}
            >
              <IcTrash />
            </CButton>
            {whois == "sport_manager" || whois == "federationadmin" ? (
              <CButton
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 25px 9px 16px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
                onClick={() => {
                  setOpen(true);
                  hiddenWindow();
                }}
              >
                <IcPlus
                  className={classes.icon}
                  style={{ marginBottom: "-5px" }}
                />
                New Member
              </CButton>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            hideSelectAll: true,
          }}
          onRow={(record) => ({
            onClick: () => {
              setMemberID(record?.id);
            },
            style: { cursor: "pointer" },
          })}
          columns={columns}
          dataSource={nationalTeamMember?.data?.results}
          pagination={false}
        />
        {nationalTeamMember.data?.total_pages ? (
          <div className={classes.pagination}>
            <PaginationNumber
              count={page}
              counts={nationalTeamMember.data?.count}
              pages={nationalTeamMember.data?.total_pages}
              defaultCurrent={page}
              total={nationalTeamMember.data?.total_pages * 10}
              showSizeChanger={false}
              onChange={setPage}
            />
          </div>
        ) : null}
      </div>

      {open ? (
        <CreateMemberForNationalTeam
          title={"New member of the national team"}
          setOpen={setOpen}
          nationalTeamMember={nationalTeamMember}
        />
      ) : null}

      {!!memberID &&
      (whois == "sport_manager" || whois == "federationadmin") ? (
        <EditMemberForNationalTeam
          title={""}
          setOpen={setMemberID}
          nationalTeamMember={nationalTeamMember}
          data={member.data}
        />
      ) : null}
    </div>
  );
}

export default NationalTeam;
