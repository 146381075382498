import React from "react";
import classes from "./style.module.css";
import { Pagination } from "antd";

function PaginationNumber({
  count,
  counts,
  pages,
  defaultCurrent,
  total,
  showSizeChanger,
  onChange,
}) {
  const firstCount = (count) => {
    return count < 2 ? 1 : (count - 1) * 16;
  };
  const secondCount = () => {
    if (count == pages) return counts;
    if (counts < 16) {
      return counts;
    } else {
      return 16 * (count ? count : 1);
    }
  };
  console.log(count);
  return (
    <div className={classes.pagination}>
      <p>
        {firstCount(count)}-{secondCount(counts)} of {counts} items
      </p>
      <Pagination
        defaultCurrent={defaultCurrent}
        total={total}
        showSizeChanger={showSizeChanger}
        onChange={onChange}
      />
    </div>
  );
}

export default PaginationNumber;
