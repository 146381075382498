import { useQuery } from "react-query";
import { request } from "services/api";

export const useAdminsQuery = ({ enabled, page, region_id, admin_type }) => {
  return useQuery(
    ["admins-list", page, region_id, admin_type],
    async () => {
      const { data } = await request.get(`/admin/`, {
        params: { page, region_id, admin_type },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
