import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetTrophiesDocumentDownloadQuery = ({ enabled, id }) => {
  return useQuery(
    ["trophies-document-download", id, enabled],
    async () => {
      const { data } = await request.get(
        `/national/team/athlete/tropy/document/download/${id}/`
      );
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
