import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetWeightsQuery = ({ enabled }) => {
  return useQuery(
    ["get-weights"],
    async () => {
      const { data } = await request.get(`/weight/categories/`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
