import { useMutation, useQueryClient } from "react-query";
import { request } from "services/api";

export const useDeleteCompetitionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ ids }) => {
      return request.delete(`/delete/competition/`, { data: { ids } });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries("competitions");
      },
    }
  );
};
