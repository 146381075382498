import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetMembersQuery = ({
  enabled,
  id,
  page,
  type,
  region_id,
  query,
  action,
  gender,
}) => {
  return useQuery(
    ["members", id, page, type, region_id, query, action, gender],
    async () => {
      const { data } = await request.get(`/main/member`, {
        params: { page, type, region_id, query, action, gender },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
