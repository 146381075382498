import "moment/locale/zh-cn";
import moment from "moment";
import classes from "./style.module.css";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CButton,
  CDatePicker,
  CInput,
  CLoader,
  CSelect,
  CUpload,
} from "components";
import { Col, message, Row } from "antd";
import { useRegionsQuery, useUserInfoQuery } from "hook/query";
import { useUserPatchMutation } from "hook/mutation";

function SettingPage() {
  const regions = useRegionsQuery({});
  const userData = useUserInfoQuery({ enabled: true });
  const userPatchMutation = useUserPatchMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    birth_date: "",
  });

  useEffect(() => {
    setValue("image", userData.data?.image);
    setValue("document_image", userData.data?.document_image);
    setValue("first_name", userData.data?.first_name);
    setValue("last_name", userData.data?.last_name);
    setValue("middle_name", userData.data?.middle_name);
    setValue("birth_date", userData.data?.birth_date);
    setValue("phone_number", userData.data?.phone_number);
    setValue("username", userData.data?.username);
    setValue("password", userData.data?.password);
    setValue("admin_type", userData.data?.admin_type);
  }, [userData.isSuccess]);

  const onSubmit = (e) => {
    const formData = new FormData();
    typeof e?.image == "object" && formData.append("image", e?.image);
    typeof e?.document == "object" && formData.append("document", e?.document);
    typeof e?.birth_date == null &&
      formData.append("birth_date", e?.birth_date);

    formData.append("middle_name", e?.middle_name);
    formData.append("first_name", e?.first_name);
    formData.append("last_name", e?.last_name);
    formData.append("phone_number", e?.phone_number);
    formData.append("username", e?.username);
    formData.append("password", e?.password);

    const userPatch = userPatchMutation.mutateAsync(formData);
    userPatch
      .then((res) => {
        message.success({
          content: `Changed Information`,
          style: {
            right: "50%",
          },
        });
        userData.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (userData.isLoading) return <CLoader />;

  return (
    <form className={classes.setting} onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[30, 8]}>
        <Col span={12}>
          <div className={classes.flex}>
            <CUpload
              control={control}
              name="image"
              required={true}
              showUploadList={false}
              maxCount={1}
              text={"Photo"}
              title={"Name Image"}
              type={true}
              error={errors.image}
            />
            <CUpload
              control={control}
              name="document_image"
              required={true}
              showUploadList={false}
              maxCount={1}
              text={"Document"}
              title={"Name Document"}
              type={false}
              error={errors.document_image}
            />
          </div>
          <CInput
            type="text"
            label="Last name"
            name="last_name"
            register={register}
            errors={errors?.last_name}
            required
          />
          <CInput
            type="text"
            label="First name"
            name="first_name"
            register={register}
            errors={errors?.first_name}
            required
          />
          <CInput
            type="text"
            label="Middle name"
            name="middle_name"
            register={register}
            errors={errors?.middle_name}
            required
          />
          <CDatePicker
            label={"Birth date"}
            control={control}
            name="birth_date"
            placeholder=""
            defaultValue=""
            errors={errors}
            value={
              watch().birth_date != null || ""
                ? moment(watch().birth_date, `YYYY-MM-DD`)
                : ""
            }
          />
        </Col>
        <Col span={12}>
          {watch()?.admin_type == "federationadmin" ? (
            <CSelect
              label={"Region/City"}
              name="region"
              items={regions.data}
              control={control}
              error={errors.region}
            />
          ) : null}

          <CInput
            type="text"
            label="Login"
            name="username"
            register={register}
            errors={errors?.username}
            textTransformation="lowercase"
            required
          />
          <CInput
            type="text"
            label="Password"
            name="password"
            register={register}
            errors={errors?.password}
            required
          />
          <CInput
            type="tel"
            label="Contact"
            name="phone_number"
            register={register}
            errors={errors?.phone_number}
            required
            pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
            maxLength={13}
            minLength={13}
          />

          <CButton
            type="submit"
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#0062FF"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"1px solid #0062FF"}
            padding={"9px 35px"}
            borderRadius={"10px"}
            margin={"0 0 0 80%"}
          >
            Save
          </CButton>
        </Col>
      </Row>
    </form>
  );
}

export default SettingPage;
