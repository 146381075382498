import moment from "moment";
import { Col, Row } from "antd";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useTabletsOrVitaminMutation } from "hook/mutation";
import { CButton, CDatePicker, CInput, CLoader, CPopup } from "components";

function CreateVitamins({ title, setOpen, id, otherData }) {
  const tabletsOrVitaminMutation = useTabletsOrVitaminMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      date: "",
    },
  });

  const onSubmit = (e) => {
    const data = {
      tablet: e?.name,
      date: e?.date,
      athlete: id,
    };
    const tabletsOrVitamin = tabletsOrVitaminMutation.mutateAsync(data);
    tabletsOrVitamin
      .then((res) => {
        console.log(res);
        reset();
        setOpen(false);
        otherData.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.vitamins}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[33, 8]}>
            <Col span={24}>
              <CInput
                type="text"
                label="Name"
                name="name"
                register={register}
                errors={errors?.name}
                required
              />
              <CDatePicker
                label={"Date"}
                control={control}
                name="date"
                placeholder=""
                required={true}
                errors={errors.date}
                value={
                  watch().date != "" ? moment(watch().date, `YYYY-MM-DD`) : ""
                }
              />
              <div className={classes.flex}>
                <CButton
                  type="button"
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={"none"}
                  fontSize={"14px"}
                  lineHeight={"18px"}
                  fontWeight={"600"}
                  color={"#0062FF"}
                  border={"1px solid #0062FF"}
                  padding={"9px 25px"}
                  borderRadius={"10px"}
                  onClick={() => reset()}
                >
                  Clear
                </CButton>
                <CButton
                  type="submit"
                  fontFamily={"Poppins"}
                  fontStyle={"normal"}
                  background={
                    tabletsOrVitaminMutation.isLoading ? "#ffffff" : "#0062FF"
                  }
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={"500"}
                  color={"#fff"}
                  border={"1px solid #0062FF"}
                  padding={"9px 25px"}
                  borderRadius={"10px"}
                  margin={"0 0 0 20px"}
                  height={"40px"}
                  position={"relative"}
                >
                  {tabletsOrVitaminMutation.isLoading ? (
                    <CLoader width={"80px"} height={"80px"} />
                  ) : (
                    "Save"
                  )}
                </CButton>
              </div>
            </Col>
          </Row>
        </form>
      </CPopup>
    </div>
  );
}

export default CreateVitamins;
