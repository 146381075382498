import { useQuery } from "react-query";
import { request } from "services/api";

export const useSearchClubsQuery = ({ enabled, club }) => {
  return useQuery(
    ["search-club-query", club],
    async () => {
      const { data } = await request.get(`/club-search/`, { params: { club } });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
