import moment from "moment";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useBodyListMutation } from "hook/mutation";
import {
  CButton,
  CDatePicker,
  CLoader,
  CPopup,
  CSelect,
  CUpload,
} from "components";

function CreateBodyList({ title, setOpen, id, otherData }) {
  const bodyListMutation = useBodyListMutation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    defaultValues: { date: "" },
  });

  const onSubmit = (e) => {
    let formData = new FormData();
    formData.append("body_check_list_date", e?.date);
    formData.append("body_check_list_status", e?.result);
    formData.append("body_check_list", e?.image);

    const bodyList = bodyListMutation.mutateAsync({ data: formData, id: id });
    bodyList
      .then((res) => {
        reset();
        setOpen(false);
        otherData.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.body__list}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.flex}>
            <CUpload
              control={control}
              name="image"
              required={true}
              showUploadList={false}
              maxCount={1}
              text={"Photo"}
              title={"Name Image"}
              type={true}
              error={errors.image}
            />
            <div>
              <CDatePicker
                label={"Date"}
                control={control}
                name="date"
                placeholder=""
                required
                errors={errors.date}
                value={
                  watch().date != "" ? moment(watch().date, `YYYY-MM-DD`) : ""
                }
              />
              <div style={{ margin: "30px 0" }}>
                <CSelect
                  label={"Result"}
                  name="result"
                  items={[
                    { province: "Positive", id: "positive" },
                    { province: "Negative", id: "negative" },
                  ]}
                  control={control}
                  error={errors.result}
                  required
                />
              </div>
            </div>
          </div>
          <div className={classes.btns}>
            <CButton
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"none"}
              fontSize={"14px"}
              lineHeight={"18px"}
              fontWeight={"600"}
              color={"#0062FF"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              onClick={() => reset()}
            >
              Clear
            </CButton>
            <CButton
              type="submit"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={bodyListMutation.isLoading ? "#ffffff" : "#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              height={"40px"}
              position={"relative"}
            >
              {bodyListMutation.isLoading ? (
                <CLoader width={"80px"} height={"80px"} />
              ) : (
                "Save"
              )}
            </CButton>
          </div>
        </form>
      </CPopup>
    </div>
  );
}

export default CreateBodyList;
