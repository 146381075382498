import { useState } from "react";
import classes from "./style.module.css";
import { Table } from "antd";
import noImage from "assets/images/notfound/nopic.jpg";
import { ReactComponent as IcPlus } from "assets/images/icons/ic_Plus.svg";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { CButton, CDropdown, CLoader, CSearchInput } from "components";
import { ReactComponent as IcClose } from "assets/images/icons/ic_Close.svg";
import { useGetMembersQuery, useRegionsQuery } from "hook/query";
import {
  CreateCoach,
  CreateDoctor,
  CreateHosts,
  CreateReferee,
  CreateSportsman,
  EditCoach,
  EditDoctor,
  EditHost,
  EditReferee,
  EditSportsman,
  MemberProfModal,
  PaginationNumber,
} from "container";
import { useDeleteMembersMutation } from "hook/mutation/Member/useDeleteMembersMutation";
import toast from "react-hot-toast";

function MembersPage() {
  const [ids, setIds] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [openEdit, setOpenEdit] = useState("");
  const [open, setOpen] = useState("");
  const [page, setPage] = useState(1);
  const [type, setType] = useState({});
  const [gender, setGender] = useState({});
  const [region, setRegion] = useState({});
  const [action, setAction] = useState({});
  const [query, setQuery] = useState("");
  const { mutateAsync } = useDeleteMembersMutation();
  const listMembers = useGetMembersQuery({
    enabled: true,
    query: query,
    page: page,
    type: type?.id,
    region_id: region?.id,
    action: action,
    gender: gender?.id,
  });

  const listRegions = useRegionsQuery({
    enabled: localStorage.getItem("isLogin") == "federationadmin",
  });

  function deleteMembers() {
    const mutation = mutateAsync({ ids });
    toast
      .promise(mutation, {
        loading: "Loading...",
        success: "Successfully deleted",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        setIds([]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "first_name",
      width: "500px",
      render: (_, record) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          <img src={record.image ? record.image : noImage} alt="" />
          {record?.first_name} {record?.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "#ID",
      dataIndex: "id",
    },
    {
      title: "ROLE",
      dataIndex: "role",
      render: (status) => (
        <div
          className="role"
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status === "restricted" ? "not paid" : "paid"}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div
          onClick={() => {
            setMemberId(record?.id);
            setOpenEdit(record?.role);
            hiddenWindow();
          }}
        >
          <IcShape />
        </div>
      ),
    },
  ];

  const clearSearchParams = () => {
    setType({});
    setGender({});
    setRegion({});
    setPage(1);
    setIds([]);
  };
  console.log(ids);
  if (listMembers.isLoading && query == "") return <CLoader />;

  return (
    <div className={classes.member}>
      <div className={classes.header}>
        <h4>Members</h4>
        <div className={classes.flex}>
          <CDropdown
            text={"Sort by:"}
            value={type?.province}
            setState={(e) => {
              setType(e);
              setPage(1);
            }}
            items={[
              { province: "Athlete", id: "athlete" },
              { province: "Coach", id: "coach" },
              { province: "Referee", id: "referee" },
              { province: "Doctor", id: "doctor" },
              { province: "Other", id: "other" },
            ]}
            placeholder={"member type"}
          />
          <CDropdown
            text={"Sort by:"}
            value={gender?.province}
            setState={(e) => {
              setGender(e);
              setPage(1);
            }}
            items={[
              { province: "Men", id: "male" },
              { province: "Women", id: "female" },
            ]}
            margin={"0 10px"}
            placeholder={"gender"}
          />
          {localStorage.getItem("isLogin") == "federationadmin" ? (
            <CDropdown
              text={"Sort by:"}
              value={region?.province}
              setState={(e) => {
                setRegion(e);
                setPage(1);
              }}
              items={listRegions.data}
              placeholder={"region"}
            />
          ) : null}
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={clearSearchParams}
          >
            <IcClose />
          </CButton>
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            className={ids.length > 0 ? classes.btnRed : classes.btnWhite}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={deleteMembers}
            disabled={!ids.length}
          >
            <IcTrash className={ids.length > 0 ? classes.svg1 : null} />
          </CButton>
          <CButton
            className={classes.create__btn}
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#0062FF"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"1px solid #0062FF"}
            padding={"9px 25px 9px 16px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={() => {
              setOpen("choiceTypeMember");
              hiddenWindow();
            }}
          >
            <IcPlus />
            New Member
          </CButton>
        </div>
      </div>
      <div className={classes.actions}>
        <CButton
          className={action === "all" ? classes.active : classes.disactive}
          type={"button"}
          onClick={() => setAction("all")}
        >
          All members
        </CButton>
        <CButton
          className={
            action === "new_joined" ? classes.active : classes.disactive
          }
          type={"button"}
          onClick={() => setAction("new_joined")}
        >
          New joined
        </CButton>
        <CButton
          className={action === "edited" ? classes.active : classes.disactive}
          type={"button"}
          onClick={() => setAction("edited")}
        >
          Edited
        </CButton>
      </div>
      <CSearchInput placeholder={"Search"} onChange={setQuery} value={query} />
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          onRow={(record) => ({
            onClick: () => {
              setMemberId(record?.id);
              setOpenEdit(record?.role);
              hiddenWindow();
            },
            style: { cursor: "pointer" },
          })}
          rowSelection={{
            type: "checkbox",
            hideSelectAll: true,
            selectedRowKeys: ids,
            onSelect: ({ id }) => {
              setIds((items) => {
                const exists = items.includes(id);
                if (exists) {
                  return items.filter((item) => item !== id);
                } else {
                  return [...items, id];
                }
              });
            },
          }}
          columns={columns}
          dataSource={listMembers.data?.results}
          pagination={false}
        />
        {listMembers.data?.total_pages ? (
          <div className={classes.pagination}>
            <PaginationNumber
              count={page}
              counts={listMembers.data?.count}
              pages={listMembers.data?.total_pages}
              defaultCurrent={page}
              total={listMembers.data?.total_pages * 10}
              showSizeChanger={false}
              onChange={setPage}
            />
          </div>
        ) : null}
      </div>
      <MemberProfModal open={open} setOpen={setOpen} />
      <CreateHosts open={open} setOpen={setOpen} memberList={listMembers} />
      <CreateReferee open={open} setOpen={setOpen} memberList={listMembers} />
      <CreateCoach open={open} setOpen={setOpen} memberList={listMembers} />
      <CreateDoctor open={open} setOpen={setOpen} memberList={listMembers} />
      <CreateSportsman open={open} setOpen={setOpen} memberList={listMembers} />
      <EditHost
        open={openEdit}
        setOpen={setOpenEdit}
        memberList={listMembers}
        id={memberId}
        setId={setMemberId}
      />
      <EditCoach
        open={openEdit}
        setOpen={setOpenEdit}
        memberList={listMembers}
        id={memberId}
        setId={setMemberId}
      />
      <EditDoctor
        open={openEdit}
        setOpen={setOpenEdit}
        memberList={listMembers}
        id={memberId}
        setId={setMemberId}
      />
      <EditReferee
        open={openEdit}
        setOpen={setOpenEdit}
        memberList={listMembers}
        id={memberId}
        setId={setMemberId}
      />
      <EditSportsman
        open={openEdit}
        setOpen={setOpenEdit}
        memberList={listMembers}
        id={memberId}
        setId={setMemberId}
      />
    </div>
  );
}

export default MembersPage;
