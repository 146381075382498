import "./sidebar.css";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "store/slice/auth/auth";
import SiderbarItem from "./SiderbarItemComponent";
import logoImage from "assets/images/UZBEKISTANJUDOFEDERATION.svg";
import { ReactComponent as IcLatest } from "assets/images/icons/ic_Latest Activity.svg";
import { ReactComponent as IcCardView } from "assets/images/icons/ic_CardView.svg";
import { ReactComponent as IcAdministration } from "assets/images/icons/ic_Administration.svg";
import { ReactComponent as IcAnalytics } from "assets/images/icons/ic_Analytics.svg";
import { ReactComponent as IcDashboard } from "assets/images/icons/ic_Dashboard.svg";
import { ReactComponent as IcAchievementsOutline } from "assets/images/icons/ic_Achievements Outline.svg";
import { ReactComponent as IcFavourite } from "assets/images/icons/ic_Favourite.svg";
import { ReactComponent as IcLogOut } from "assets/images/icons/ic_logout.svg";
import { ReactComponent as IcSettings } from "assets/images/icons/ic_Settings.svg";
import { ReactComponent as IcDashboard1 } from "assets/images/icons/Star Circle.svg";

function SidebarComponent() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className="sideBarMenu">
      <div className="sideBarTopSection">
        <div className="sidebarLogo">
          <img src={logoImage} alt="webscript" />
        </div>

        <div className="sidebarMenu">
          <ul>
            <SiderbarItem
              name={"All members"}
              path="all-members"
              icon={<IcCardView />}
            />
            {auth !== "regionadmin" && (
              <>
                <SiderbarItem
                  name={"National team"}
                  path="national-team"
                  icon={<IcDashboard1 />}
                />

                <SiderbarItem
                  name={"Member fee"}
                  path="Member_fee"
                  icon={<IcLatest />}
                />
              </>
            )}

            <SiderbarItem
              name={"Payments"}
              path="payments"
              icon={<IcAdministration />}
            />
            <SiderbarItem
              name={"Ranking"}
              path="ranking"
              icon={<IcAnalytics />}
            />
            <SiderbarItem
              name={"Competitions"}
              path="competitions"
              icon={<IcAchievementsOutline />}
            />

            <SiderbarItem name={"Clubs"} path="clubs" icon={<IcDashboard />} />

            {auth !== "regionadmin" && (
              <>
                <SiderbarItem
                  name={"Admins"}
                  path="admins"
                  icon={<IcFavourite />}
                />
                <SiderbarItem
                  name={"Setting"}
                  path="setting"
                  icon={<IcSettings />}
                />{" "}
              </>
            )}
            {/* <SiderbarItem
              name={"Support"}
              path="support"
              icon={<IcSupport />}
            /> */}
            <SiderbarItem
              onClick={() => dispatch(logOut())}
              name={"Logout"}
              path="login"
              icon={<IcLogOut />}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(SidebarComponent);
