import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./slice/auth/auth";
import adminsReducer from "./slice/admins/admins";
import regionsReducer from "./slice/region/region";
import clubsReducer from "./slice/club/club";
import membersReducer from "./slice/member/member";
import coachsReducer from "./slice/coach/coach";
import competitionsReducer from "./slice/competition/competition";
import weightReducer from "./slice/weight/weight";
import registrationReducer from "./slice/registration/registration";
import paymentReducer from "./slice/payment/payment";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    admins: adminsReducer,
    regions: regionsReducer,
    clubs: clubsReducer,
    members: membersReducer,
    coachs: coachsReducer,
    competitions: competitionsReducer,
    weights: weightReducer,
    registration: registrationReducer,
    payment: paymentReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
