import React from "react";
import { Upload, message } from "antd";
import classes from "./style.module.css";
import { Controller } from "react-hook-form";
import { ReactComponent as UploadCloud } from "assets/images/icons/bi_cloud-upload.svg";

function CUpload({
  control,
  name,
  required,
  showUploadList,
  maxCount,
  text,
  type,
  error,
  title,
  onChange,
}) {
  const beforeUpload = (file, field) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (type && !isJpgOrPng) {
      return message.error("You can only upload JPG/PNG file!");
    }
    field.onChange(file);
  };

  return (
    <div className={`upload ${classes.upload}`}>
      <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field }) => (
          <div>
            <Upload
              name={field.name}
              listType="picture-card"
              className={`${classes.uploader} ${error && "error"}`}
              showUploadList={showUploadList}
              beforeUpload={(file) => {
                beforeUpload(file, field);
                onChange(file);
              }}
              maxCount={maxCount}
            >
              <div>
                <UploadCloud />
                <p className={classes.text}>{text}</p>
              </div>
            </Upload>

            <p className={classes.link}>
              {field?.value?.name && field.value?.name}

              {typeof field?.value == "string" && (
                <a href={field.value} target="_blank" rel="noreferrer">
                  {field.value}
                </a>
              )}

              {!field.value ? title : null}
            </p>
          </div>
        )}
      />
    </div>
  );
}

export default CUpload;
