import axios from "axios";
import { getCookie, removeCookie } from "utils/cookie";

export const requestWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

export const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
  headers: {
    // common: {
    Authorization: "Bearer " + localStorage.getItem("access"),
    // }
  },
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeCookie("token");
      removeCookie("user");
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

request.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      // common: {
      Authorization: "Bearer " + localStorage.getItem("access"),
      // }
    };
    return config;
  },
  (error) => Promise.reject(error)
);
