import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetRankingQuery = ({
  enabled,
  id,
  level,
  gender,
  weight_id,
}) => {
  return useQuery(
    ["get-ranking", id, level, gender, weight_id],
    async () => {
      const { data } = await request.get(`/athletes/ranking/`, {
        params: { level, gender, weight_id },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
