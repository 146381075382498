import { createSlice } from "@reduxjs/toolkit";
import { createMember, deleteMember, editMember, getMember, getMembers, recoverMember } from "./fetcher";

const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
};

const initialState = {
    members: [],
    member: null,
    editPopupMember: "close",
    createPopupMember: "close",
    status: null,
    error: null,
    roleInfo: "",
    refreshCount: 1,
}

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setCreatePopupMember: (state, action) => {
            state.createPopupMember = action.payload
        },
        setEditMember: (state, action) => {
            state.editPopupMember = action.payload
        },
        clearStates: (state, action) => {
            state.member = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMembers.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getMembers.fulfilled, (state, action) => {
            state.members = action.payload;
            state.status = "success";
        });

        builder.addCase(getMembers.rejected, (state, action) => {
            state.status = "error";
            state.members = [];
        });

        builder.addCase(getMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getMember.fulfilled, (state, action) => {
            state.status = "success";
            state.member = action.payload;
        });

        builder.addCase(getMember.rejected, (state, action) => {
            state.status = "error";
        });

        builder.addCase(createMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(createMember.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1
            state.createPopupMember = "close"
            state.member = null;
            removeHiddenWindow()
            state.status = "success";
        });

        builder.addCase(createMember.rejected, (state, action) => {
            state.status = "error";
        });
        builder.addCase(recoverMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(recoverMember.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1
            state.member = null;
            removeHiddenWindow()
            state.status = "success";
        });

        builder.addCase(recoverMember.rejected, (state, action) => {
            state.status = "error";
        });
        builder.addCase(deleteMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(deleteMember.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1;
            state.member = null;
            removeHiddenWindow()
            state.status = "success";
        });

        builder.addCase(deleteMember.rejected, (state, action) => {
            state.status = "error";
        });
        builder.addCase(editMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(editMember.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1;
            state.member = null;
            removeHiddenWindow()
            state.status = "success";
        });

        builder.addCase(editMember.rejected, (state, action) => {
            state.status = "error";
        });
    },

})

export const { setCreatePopupMember, setEditMember, clearStates } = memberSlice.actions;
export default memberSlice.reducer;