import { useQuery } from "react-query";
import { request } from "services/api";

export const useTrophiesAndResults = ({ enabled, athlete_id, page }) => {
  return useQuery(
    ["national-team-athlete-trophies", athlete_id, page],
    async () => {
      const { data } = await request.get(`/national/team/athlete/trophies/`, {
        params: { page, athlete_id },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
