import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import RotuesMain from "./routes";
import { store } from "./store";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/accountAPI";
import { Toaster } from "react-hot-toast";
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { DndProvider } from 'react-dnd'
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-right" />

        <Provider store={store}>
          {/*<DndProvider backend={HTML5Backend}>*/}
          <div className="App">
            <RotuesMain />
          </div>
          {/*</DndProvider>*/}
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
