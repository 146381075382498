import { useState } from "react";
import { CButton } from "components";
import classes from "./style.module.css";
import { Checkbox, Col, Input, Row, message } from "antd";
import { useWeightMutation } from "hook/mutation";

function Category({
  ageTo,
  ageFrom,
  setAgeTo,
  setAgeFrom,
  weightsAddListMale,
  setWeightsAddListMale,
  weightsAddListFemale,
  setWeightsAddListFemale,
  forJuniorMale,
  setForJuniorMale,
  forCadetMale,
  setForCadetMale,
  forSeniorMale,
  setForSeniorMale,
  forJuniorFemale,
  setForJuniorFemale,
  forCadetFemale,
  setForCadetFemale,
  forSeniorFemale,
  setForSeniorFemale,
  weightsAddListIDMale,
  setWeightsAddListIDMale,
  weightsAddListIDFemale,
  setWeightsAddListIDFemale,
}) {
  const weightMutation = useWeightMutation();
  const [weightAddMale, setWeightAddMale] = useState(null);
  const [weightAddFemale, setWeightAddFemale] = useState(null);

  const addWeightMaleState = () => {
    if (
      weightAddMale.indexOf("+") == "-1" &&
      weightAddMale.indexOf("-") == "-1"
    ) {
      return message.warning("You dont have + or - in Female");
    }
    let data = { weight: weightAddMale };
    const weightData = weightMutation.mutateAsync(data);
    weightData
      .then((res) => {
        let findWeightId = weightsAddListMale.find(
          (element) => element.id === res?.data?.id
        );
        if (!!findWeightId) {
          message.success("Male Weight Added");
          return;
        }
        setWeightsAddListMale([...weightsAddListMale, res?.data]);
        setWeightsAddListIDMale([...weightsAddListIDMale, res?.data?.id]);
        message.success("Male Weight Added");
        setWeightAddMale(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addWeightFemaleState = () => {
    if (
      weightAddFemale.indexOf("+") == "-1" &&
      weightAddFemale.indexOf("-") == "-1"
    ) {
      return message.warning("You dont have + or - in Female");
    }
    let data = { weight: weightAddFemale };
    const weightData = weightMutation.mutateAsync(data);
    weightData
      .then((res) => {
        let findWeightId = weightsAddListFemale.find(
          (element) => element.id === res?.data?.id
        );
        if (!!findWeightId) {
          message.success("Male Weight Added");
          return;
        }
        setWeightsAddListFemale([...weightsAddListFemale, res?.data]);
        setWeightsAddListIDFemale([...weightsAddListIDFemale, res?.data?.id]);
        message.success("Male Weight Added");
        setWeightAddFemale(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeWeightMale = (e) => {
    let NewWeightList = weightsAddListMale.filter(
      (element) => element.id !== e
    );
    let NewWeightListID = weightsAddListIDMale.filter(
      (element) => element !== e
    );
    setWeightsAddListMale(NewWeightList);
    setWeightsAddListIDMale(NewWeightListID);
  };

  const removeWeightFemale = (e) => {
    let NewWeightList = weightsAddListFemale.filter(
      (element) => element.id !== e
    );
    let NewWeightListID = weightsAddListIDFemale.filter(
      (element) => element !== e
    );
    setWeightsAddListFemale(NewWeightList);
    setWeightsAddListIDFemale(NewWeightListID);
  };

  return (
    <div className={classes.category}>
      <h2>Categories</h2>
      <h3>Age range</h3>
      <Row gutter={[20, 8]}>
        <Col span={12}>
          <div style={{ marginRight: "30px", marginBottom: "20px" }}>
            <h5>From year</h5>
            <Input
              value={ageFrom}
              placeholder="1992"
              type={"text"}
              onChange={(e) => setAgeFrom(e.target.value)}
              maxLength={"4"}
              style={{
                width: "300px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
            />
          </div>
        </Col>
        <Col style={{ marginBottom: "20px" }} span={12}>
          <div>
            <h5>To year</h5>
            <Input
              value={ageTo}
              placeholder="1992"
              type={"text"}
              onChange={(e) => setAgeTo(e.target.value)}
              maxLength={"4"}
              style={{
                width: "300px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                marginRight: "20px",
              }}
            />
          </div>
        </Col>
      </Row>
      <h3 style={{ marginTop: "10px" }}>Age Category</h3>
      <Row gutter={[20, 8]}>
        <Col style={{ marginBottom: "20px" }} span={12}>
          <h5>Male</h5>
          <Row>
            {forCadetMale === true || forSeniorMale === true ? null : (
              <Col span={6}>
                <Checkbox
                  checked={forJuniorMale}
                  onChange={(e) => setForJuniorMale(e.target.checked)}
                >
                  Juniors
                </Checkbox>
              </Col>
            )}
            {forJuniorMale === true || forSeniorMale === true ? null : (
              <Col span={6}>
                <Checkbox
                  checked={forCadetMale}
                  onChange={(e) => setForCadetMale(e.target.checked)}
                >
                  Cadets
                </Checkbox>
              </Col>
            )}
            {forCadetMale === true || forJuniorMale === true ? null : (
              <Col span={6}>
                <Checkbox
                  checked={forSeniorMale}
                  onChange={(e) => setForSeniorMale(e.target.checked)}
                >
                  Seniors
                </Checkbox>
              </Col>
            )}
          </Row>
        </Col>
        <Col style={{ marginBottom: "20px" }} span={12}>
          <h5>Female</h5>
          <Row>
            {forCadetFemale === true || forSeniorFemale === true ? null : (
              <Col span={6}>
                <Checkbox
                  checked={forJuniorFemale}
                  onChange={(e) => setForJuniorFemale(e.target.checked)}
                >
                  Juniors
                </Checkbox>
              </Col>
            )}
            {forJuniorFemale === true || forSeniorFemale === true ? null : (
              <Col span={6}>
                <Checkbox
                  checked={forCadetFemale}
                  onChange={(e) => setForCadetFemale(e.target.checked)}
                >
                  Cadets
                </Checkbox>
              </Col>
            )}
            {forCadetFemale === true || forJuniorFemale === true ? null : (
              <Col span={6}>
                <Checkbox
                  checked={forSeniorFemale}
                  onChange={(e) => setForSeniorFemale(e.target.checked)}
                >
                  Seniors
                </Checkbox>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <h3 style={{ marginTop: "10px" }}>Weight Category</h3>
      <Row gutter={[20, 8]}>
        <Col span={12}>
          <div style={{ marginBottom: "20px" }}>
            <h5>Male</h5>
            <Input
              placeholder="*999"
              value={weightAddMale}
              type={"text"}
              onChange={(e) => setWeightAddMale(e.target.value)}
              maxLength={"4"}
              style={{
                width: "300px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
            />
            <CButton
              padding={"4px 24px"}
              background={"#0062FF"}
              borderRadius={"4px"}
              margin={"0 10px"}
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              fontWeight={"500"}
              fontSize={"14px"}
              lineHeight={"24px"}
              textAlign={"right"}
              letterSpacing={"0.2px"}
              color={"#FFFFFF"}
              border={"1px solid #0062FF"}
              onClick={addWeightMaleState}
            >
              Add
            </CButton>
          </div>

          <Checkbox.Group
            style={{
              width: "80%",
            }}
            value={weightsAddListIDMale}
          >
            <Row>
              {weightsAddListMale?.map((weight, index) => (
                <Col span={6} key={index}>
                  <Checkbox
                    onChange={(e) => removeWeightMale(e.target.value)}
                    value={weight.id}
                  >
                    {weight.weight}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Col>
        <Col span={12}>
          <div style={{ marginBottom: "20px" }}>
            <h5>Female</h5>
            <Input
              placeholder="*999"
              onChange={(e) => setWeightAddFemale(e.target.value)}
              maxLength={"4"}
              value={weightAddFemale}
              type={"text"}
              style={{
                width: "300px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
            />
            <CButton
              padding={"4px 24px"}
              background={"#0062FF"}
              borderRadius={"4px"}
              margin={"0 10px"}
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              fontWeight={"500"}
              fontSize={"14px"}
              lineHeight={"24px"}
              textAlign={"right"}
              letterSpacing={"0.2px"}
              color={"#FFFFFF"}
              border={"1px solid #0062FF"}
              onClick={addWeightFemaleState}
            >
              Add
            </CButton>
          </div>

          <Checkbox.Group
            style={{
              width: "80%",
            }}
            value={weightsAddListIDFemale}
            onChange={(res) => console.log(res)}
          >
            <Row>
              {weightsAddListFemale?.map((weight, index) => (
                <Col checked={true} span={6} key={index}>
                  <Checkbox
                    onChange={(e) => removeWeightFemale(e.target.value)}
                    value={weight.id}
                  >
                    {weight.weight}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
    </div>
  );
}

export default Category;
