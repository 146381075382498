import { Col, Row } from "antd";
import classes from "./style.module.css";
import CustomInputField from "components/CInputField/CInputField";

function Points({ points, setPoints }) {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...points];
    list[index][name] = value;
    setPoints(list);
  };

  return (
    <div className={classes.points}>
      <h5>Points</h5>
      <Row gutter={[40, 16]}>
        {points?.map((point, index) => (
          <Col key={index} span={4}>
            <CustomInputField
              onChange={(e) => handleInputChange(e, index)}
              type="number"
              label={`Points (${
                point.place == "for_participating" ? "*" : point.place
              } place)`}
              name={"point"}
              value={point.point}
              margin={"0"}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Points;
