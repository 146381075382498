import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { createAdmin, deleteAdmin, editAdmin, getAdmin, getAdmins } from "./fetchers";


const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
};

const initialState = {
    id: "",
    admins: [],
    admin: null,
    region: null,
    showPopup: "close",
    refreshCount: 1,
    status: null,
    error: null,
}

const adminSlice = createSlice({
    name: 'admins',
    initialState,
    reducers: {
        setRegion: (state, action) => {
            state.region = action.payload
        },
        showPopup: (state, action) => {
            state.showPopup = action.payload
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getAdmins.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getAdmins.fulfilled, (state, action) => {
            state.admins = action.payload;
            state.status = "success";
        });

        builder.addCase(getAdmins.rejected, (state, action) => {
            state.status = "error";
            state.admins = [];
        });

        builder.addCase(createAdmin.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(createAdmin.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1
            removeHiddenWindow()
            state.showPopup = "close"
            state.status = "success";
        });

        builder.addCase(createAdmin.rejected, (state, action) => {
            message.error({
                content: 'Login such with username exists',
                style: {
                    right: "50%",
                    marginLeft: "80%",
                }
            });
            state.status = "error";
        });

        builder.addCase(getAdmin.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getAdmin.fulfilled, (state, action) => {
            state.admin = action.payload;
            state.status = "success";
        });

        builder.addCase(getAdmin.rejected, (state, action) => {
            state.status = "error";
        });

        builder.addCase(editAdmin.pending, (state, action) => {
            state.showPopup = "editAdmin"
            state.status = "loading";
        });

        builder.addCase(editAdmin.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1
            removeHiddenWindow()
            state.showPopup = "close"
            state.status = "success";
        });

        builder.addCase(editAdmin.rejected, (state, action) => {
            message.error({
                content: 'Login such with username exists',
                style: {
                    right: "50%",
                    marginLeft: "80%",
                }
            });
            state.status = "error"
            state.admin = action.meta.arg
        });

        builder.addCase(deleteAdmin.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(deleteAdmin.fulfilled, (state, action) => {
            state.refreshCount = state.refreshCount + 1
            state.status = "success";
        });

        builder.addCase(deleteAdmin.rejected, (state, action) => {
            state.refreshCount = state.refreshCount + 1
            state.status = "error";
        });
    },

})

export const { showPopup, setRegion } = adminSlice.actions;
export default adminSlice.reducer;