import { useState } from "react";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CButton } from "components";
import { Category, MainInfo, Points, Quotas } from "container";
import {
  usePatchCompetitionMutation,
  usePostCompetitionMutation,
} from "hook/mutation";
import toast from "react-hot-toast";

function CompetetionCreatePage() {
  const navigate = useNavigate();
  // POINTS
  const [points, setPoints] = useState([
    {
      place: "1",
      point: "",
    },
    {
      place: "2",
      point: "",
    },
    {
      place: "3",
      point: "",
    },
    {
      place: "3",
      point: "",
    },
    {
      place: "5",
      point: "",
    },
    {
      place: "5",
      point: "",
    },
    {
      place: "7",
      point: "",
    },
    {
      place: "for_participating",
      point: "",
    },
  ]);
  // QUOTAS
  const [quotas, setQuotas] = useState([
    {
      quota_for_male: "",
      quota_for_female: "",
      region: "",
      province: "",
    },
  ]);
  // MAIN INFO
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [startRegisterDay, setStartRegisterDay] = useState("");
  const [endRegisterDay, setEndRegisterDay] = useState("");

  // CATEGORY
  const [ageTo, setAgeTo] = useState("");
  const [ageFrom, setAgeFrom] = useState("");
  const [weightsAddListMale, setWeightsAddListMale] = useState([]);
  const [weightsAddListFemale, setWeightsAddListFemale] = useState([]);
  const [weightsAddListIDMale, setWeightsAddListIDMale] = useState([]);
  const [weightsAddListIDFemale, setWeightsAddListIDFemale] = useState([]);

  const [forJuniorMale, setForJuniorMale] = useState(false);
  const [forCadetMale, setForCadetMale] = useState(false);
  const [forSeniorMale, setForSeniorMale] = useState(false);
  const [forJuniorFemale, setForJuniorFemale] = useState(false);
  const [forCadetFemale, setForCadetFemale] = useState(false);
  const [forSeniorFemale, setForSeniorFemale] = useState(false);

  const postCompetitionMutation = usePostCompetitionMutation();
  const patchCompetitionMutation = usePatchCompetitionMutation();

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    const data = {
      quotas: quotas,
      points: points,
      // videos: watch().competitions.videos,
      title: watch()?.title,
      short_name: watch()?.short_name,
      location: watch()?.location,
      region: watch()?.region,
      number_of_days_to_register: watch()?.number_of_days_to_register,
      start_day: startDay,
      end_day: endDay,
      starting_register: startDay,
      end_register: endRegisterDay,
      age_from: ageFrom,
      age_to: ageTo,

      for_junior_male: forJuniorMale,
      for_cadet_male: forCadetMale,
      for_senior_male: forSeniorMale,
      for_junior_female: forJuniorFemale,
      for_cadet_female: forCadetFemale,
      for_senior_female: forSeniorFemale,
      weight_category_male: weightsAddListIDMale,
      weight_category_female: weightsAddListIDFemale,
    };

    const formData = new FormData();
    formData.append("logo", watch()?.logo);

    const postCompetition = postCompetitionMutation.mutateAsync(data);
    toast
      .promise(postCompetition, {
        loading: "Loading...",
        success: "Competition is successfully created",
        error: "Please check every field again",
      })

      .then((res) => {
        patchCompetitionMutation
          .mutateAsync({
            data: formData,
            id: res.data?.id,
          })
          .then((res) => {
            navigate("/competitions", { replace: true });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.create}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainInfo
          control={control}
          errors={errors}
          register={register}
          startDay={startDay}
          setStartDay={setStartDay}
          endDay={endDay}
          setEndDay={setEndDay}
          endRegisterDay={endRegisterDay}
          setEndRegisterDay={setEndRegisterDay}
          startRegisterDay={startRegisterDay}
          setStartRegisterDay={setStartRegisterDay}
        />
        <Quotas quotas={quotas} setQuotas={setQuotas} />
        <Category
          setAgeTo={setAgeTo}
          setAgeFrom={setAgeFrom}
          weightsAddListMale={weightsAddListMale}
          setWeightsAddListMale={setWeightsAddListMale}
          weightsAddListFemale={weightsAddListFemale}
          setWeightsAddListFemale={setWeightsAddListFemale}
          forJuniorMale={forJuniorMale}
          setForJuniorMale={setForJuniorMale}
          forCadetMale={forCadetMale}
          setForCadetMale={setForCadetMale}
          forSeniorMale={forSeniorMale}
          setForSeniorMale={setForSeniorMale}
          forJuniorFemale={forJuniorFemale}
          setForJuniorFemale={setForJuniorFemale}
          forCadetFemale={forCadetFemale}
          setForCadetFemale={setForCadetFemale}
          forSeniorFemale={forSeniorFemale}
          setForSeniorFemale={setForSeniorFemale}
          weightsAddListIDMale={weightsAddListIDMale}
          setWeightsAddListIDMale={setWeightsAddListIDMale}
          weightsAddListIDFemale={weightsAddListIDFemale}
          setWeightsAddListIDFemale={setWeightsAddListIDFemale}
        />
        <Points points={points} setPoints={setPoints} />
        <div className={classes.btns}>
          <CButton
            padding={"8px 44px"}
            background={"#0062FF"}
            borderRadius={"34px"}
            type="submit"
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            fontWeight={"500"}
            fontSize={"14px"}
            lineHeight={"24px"}
            textAlign={"right"}
            letterSpacing={"0.2px"}
            color={"#FFFFFF"}
            border={"1px solid #0062FF"}
            disabled={postCompetitionMutation.isLoading}
          >
            Сreate Competition
          </CButton>
        </div>
      </form>
    </div>
  );
}

export default CompetetionCreatePage;
