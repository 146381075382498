import "./bracketitem.css";
import add_icon from "../../assets/images/icons/add_icon.svg";
import avatar from "../../assets/images/icons/avatar_admin.png";
import { DeleteOutlined } from "@ant-design/icons";
import { useLocation, useSearchParams } from "react-router-dom";
import qs from "qs";
import { patchPoolMember } from "../../hook/Requests";

function BracketItem({
  data,
  step,
  setIsModalOpen,
  poolId,
  playerIndex,
  handleDropItem,
  setRefetch,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const onDragItem = (id) => {
    if (id) {
      setSearchParams({ ...query, draggedPlayerId: id });
    }
  };
  const handleDeletePatch = () => {
    const addMemberCompetition = patchPoolMember(
      playerIndex === 1
        ? { player1: 0 }
        : playerIndex === 2
        ? { player2: 0 }
        : { winner: 0 },
      poolId
    );
    addMemberCompetition
      .then((res) => {
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  return (
    <div
      draggable
      onDrag={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onDragItem(data?.id);
      }}
      onDrop={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleDropItem(poolId, playerIndex);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      className="bracketItem"
    >
      {!!data ? (
        <div className="bracketItem-1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img src={data.image ? data.image : avatar} />
            <p style={{ margin: 0 }}>
              {data?.first_name} <br /> {data?.last_name}
            </p>
          </div>
          <div className="close_icon" onClick={handleDeletePatch}>
            <DeleteOutlined
              style={{ fontSize: 18, color: "red", margin: "10px 5px 0 0" }}
            />
          </div>
        </div>
      ) : !data && step ? (
        <div className="bracketItem-1">
          <img
            onClick={() => setIsModalOpen({ poolId, playerIndex })}
            src={add_icon}
            alt=""
          />
          <p>
            Name <br /> Surname
          </p>
        </div>
      ) : (
        <div
          className="bracketItem-1"
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Drop the winner
        </div>
      )}
    </div>
  );
}

export default BracketItem;
