import moment from "moment";
import classes from "./style.module.css";
import { useDeleteVisaMutation, usePatchVisaMutation } from "hook/mutation";
import {
  CLoader,
  CButton,
  CUpload,
  CDatePicker,
  CPopup,
  CInput,
} from "components";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

function EditVisa({ title, setOpen, id, dataDetails, data }) {
  const patchVisaMutation = usePatchVisaMutation();
  const deleteVisaMutation = useDeleteVisaMutation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: { given_date: "", expired_date: "" },
  });

  useEffect(() => {
    setValue("expired_date", data.data?.expired_date);
    setValue("given_date", data.data?.given_date);
    setValue("country", data.data?.name);
    setValue("visa", data.data?.visa);
    setValue("file", data.data?.visa);
  }, [data.data]);

  const deleteVisaSubmit = (e) => {
    const deleteVisa = deleteVisaMutation.mutateAsync(data.data?.id);
    deleteVisa
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (e) => {
    let formData = new FormData();
    formData.append("name", e?.country);
    formData.append("expired_date", e?.expired_date);
    formData.append("given_date", e?.given_date);
    formData.append("athlete", data.data?.athlete);
    if (typeof e.file?.uid == "string") {
      formData.append("visa", e.file);
    }

    const patchVisa = patchVisaMutation.mutateAsync({
      data: formData,
      id: data.data?.id,
    });
    patchVisa
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.edit__visa}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.flex}>
            <CUpload
              control={control}
              name="file"
              required={true}
              showUploadList={false}
              maxCount={1}
              text={"Upload File"}
              title={"Name File"}
              error={errors.file}
            />

            <div>
              <CDatePicker
                label={"Given date"}
                control={control}
                name="given_date"
                placeholder=""
                required={true}
                errors={errors}
                value={
                  watch().given_date != ""
                    ? moment(watch().given_date, `YYYY-MM-DD`)
                    : ""
                }
              />
              <div style={{ marginTop: 20 }}>
                <CDatePicker
                  label={"  Expired date"}
                  control={control}
                  name="expired_date"
                  placeholder=""
                  required={true}
                  errors={errors}
                  value={
                    watch().expired_date != ""
                      ? moment(watch().expired_date, `YYYY-MM-DD`)
                      : ""
                  }
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <CInput
                  type="text"
                  label="Country"
                  name="country"
                  register={register}
                  errors={errors?.country}
                  required
                />
              </div>
            </div>
          </div>

          <div className={classes.btns}>
            <CButton
              type="Cbutton"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"none"}
              fontSize={"14px"}
              lineHeight={"18px"}
              fontWeight={"600"}
              color={"#fc5a5a"}
              border={"1px solid #fc5a5a"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              onClick={deleteVisaSubmit}
            >
              Delete
            </CButton>
            <CButton
              type="submit"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={patchVisaMutation.isLoading ? "#ffffff" : "#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              height={"40px"}
              position={"relative"}
            >
              {patchVisaMutation.isLoading ? (
                <CLoader width={"80px"} height={"80px"} />
              ) : (
                "Save"
              )}
            </CButton>
          </div>
        </form>
      </CPopup>
    </div>
  );
}

export default EditVisa;
