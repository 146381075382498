import { useQuery } from "react-query";
import { request } from "services/api";

export const useClubForAddQuery = ({ enabled, page, region_id }) => {
  return useQuery(
    ["club-for-add", page, region_id],
    async () => {
      const { data } = await request.get(`/memberlist/for/adding/club/`, {
        params: { page, region_id },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
