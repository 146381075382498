import classses from "./style.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/loader.json";

const CLoader = ({ width, height }) => {
  return (
    <Lottie
      style={{ width, height }}
      className={classses.loader}
      animationData={loader}
    />
  );
};

export default CLoader;
