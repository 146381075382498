import React, { useState } from "react";
import { Select } from "antd";
import classes from "./style.module.css";
import { Controller } from "react-hook-form";
import { ReactComponent as IcDropdown } from "../../assets/images/icons/ic_Dropdown.svg";

const { Option } = Select;

const CSearch = ({
  name,
  items,
  error,
  required,
  control,
  disabled,
  label,
}) => {
  return (
    <div className={classes.csearch} style={{ position: "relative" }}>
      <label htmlFor="input">{label}</label>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field }) => {
          return (
            <Select
              showSearch
              onSearch={(e) => field.onChange(e)}
              name={field.name}
              suffixIcon={<IcDropdown />}
              optionFilterProp="children"
              disabled={disabled}
              status={error && "error"}
              className={classes.select}
              {...field}
            >
              {items?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                  {item.name}
                  {item.first_name} {item.last_name}
                </Option>
              ))}
            </Select>
          );
        }}
      />
    </div>
  );
};

export default CSearch;
