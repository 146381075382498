import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetOrdersQuery = ({ enabled, page, status }) => {
  return useQuery(
    ["get-orders-query", page, status],
    async () => {
      const { data } = await request.get(
        `/main/order/`,
        { params: { page, status } }
      );
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
