import { useState } from "react";
import "./registratedmember.css";
import { Col, Row } from "antd";
import { CButton, CDropdownInput, CPopup } from "components";

function PopupWeight({
  close,
  record,
  deleteMemberId,
  deleteMember,
  addMemberListAthlete,
  weights,
}) {
  const [member, setMember] = useState(null);

  const allFunction = () => {
    addMemberListAthlete(member);
    deleteMemberId(record);
    deleteMember(record);
    close();
  };
  return (
    <div className="popup__weight">
      <CPopup title={"Athlete Weight"} onClick={close}>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <CDropdownInput
              items={
                record.gender == "female"
                  ? weights?.weight_category_female
                  : weights?.weight_category_male
              }
              value={record.weight_category_name}
              setState={(e) => {
                console.log(record);
                setMember({
                  ...record,
                  member: record.id,
                  weight_category: e.id,
                  weight_category_name: e.weight,
                });
              }}
            />
          </Col>
        </Row>
        <div className="popup_buttons">
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#fff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#FC5A5A"}
            border={"1px solid #FC5A5A"}
            padding={"9px 25px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={close}
          >
            Cancel
          </CButton>
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#0062FF"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"1px solid #0062FF"}
            padding={"9px 25px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={allFunction}
          >
            Add
          </CButton>
        </div>
      </CPopup>
    </div>
  );
}

export default PopupWeight;
