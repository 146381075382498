import classes from "./style.module.css";
import { CButton } from "components";
import User from "assets/images/notfound/nopic.jpg";

function Card({ onClick, card }) {
  const handleClick = () => {
    onClick(card.id);
  };

  return (
    <div className={classes.card}>
      <img src={card?.image ? card.image : User} alt="user-card" />
      <h5>
        {card?.first_name} <br /> {card?.last_name}
      </h5>
      {card?.region ? <p>{card?.region}</p> : <br />}
      <p className={classes.number}>{card?.phone_number}</p>
      <CButton
        fontFamily={"Poppins"}
        fontStyle={"normal"}
        background={"#0062FF"}
        fontSize={"14px"}
        lineHeight={"21px"}
        fontWeight={"500"}
        color={"#fff"}
        border={"1px solid #0062FF"}
        padding={"9px 25px"}
        width={"100%"}
        borderRadius={"10px"}
        margin={"16px 0 0 0"}
        onClick={handleClick}
      >
        Detail
      </CButton>
    </div>
  );
}

export default Card;
