import { createSlice } from "@reduxjs/toolkit";
import { getWeights } from "./fetcher";

const initialState = {
    status: null,
    error: null,
    weights: [],
    weight: "",
}

const weightsSlice = createSlice({
    name: 'weight',
    initialState,
    reducers: {
        setWeight: (state, action) => {
            state.weight = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWeights.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getWeights.fulfilled, (state, action) => {
            state.weights = action.payload;
            state.status = "success";
        });

        builder.addCase(getWeights.rejected, (state, action) => {
            state.status = "error";
            state.weights = [];
        });
    },

})

export const { setWeight } = weightsSlice.actions;
export default weightsSlice.reducer;