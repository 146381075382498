import { useQuery } from "react-query";
import { request } from "services/api";

export const useAdminQuery = ({ enabled, id }) => {
  return useQuery(
    ["admin-detail"],
    async () => {
      const { data } = await request.get(`/admin/${id}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
