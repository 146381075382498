export const URLBASE = process.env.REACT_APP_BASE_URL;

export const CAROUSEL_IMAGES = {
  images: [
    {
      id: "product1",
      name: "images 1",
      imgPath: require("../assets/images/points/Ranking1.png"),
    },
    {
      id: "product2",
      name: "images 2",
      imgPath: require("../assets/images/points/Ranking2.png"),
    },
    {
      id: "product3",
      name: "images 3",
      imgPath: require("../assets/images/points/Ranking3.png"),
    },
    {
      id: "product4",
      name: "images 4",
      imgPath: require("../assets/images/points/Ranking4.png"),
    },
    {
      id: "product5",
      name: "images 5",
      imgPath: require("../assets/images/points/Ranking5.png"),
    },
    {
      id: "product6",
      name: "images 6",
      imgPath: require("../assets/images/points/Ranking6.png"),
    },
    {
      id: "product7",
      name: "images 7",
      imgPath: require("../assets/images/points/Ranking7.png"),
    },

    {
      id: "product8",
      name: "images 7",
      imgPath: require("../assets/images/points/Ranking7.png"),
    },
  ],
};
