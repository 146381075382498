import classes from "./style.module.css";
import { Input } from "antd";
import { ReactComponent as IcPlus } from "../../assets/images/icons/ic_Search.svg";

function CSearchInput({ children, placeholder, onChange, value }) {
  return (
    <div className={classes.searchInput}>
      <Input
        className={classes.search}
        placeholder={placeholder}
        prefix={<IcPlus />}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {children}
      </Input>
    </div>
  );
}

export default CSearchInput;
