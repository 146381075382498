import React, { useState } from "react";
import classes from "./style.module.css";
import { Table } from "antd";
import { useDispatch } from "react-redux";
import { getMember } from "store/slice/member/fetcher";
import { setEditMember } from "store/slice/member/member";
import imgUnsplash from "assets/images/users/unsplash.png";
import { dowloadCompetition } from "store/slice/competition/fetcher";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { CButton, CDropdown, CLoader } from "components";
import { PaginationNumber } from "container";
import { useRegisteredMemberQuery } from "hook/query";
import { ReactComponent as IcClose } from "assets/images/icons/ic_Close.svg";
import toast from "react-hot-toast";
import { useDeleteRegisteredMembersMutation } from "hook/mutation/Member/useDeleteRegisteredMembersMutation";

function RegistretedMember({ competition, id }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [type, setType] = useState({});
  const [weight, setWeight] = useState({});
  const [gender, setGender] = useState({});
  const [ids, setIds] = useState([]);
  const { mutateAsync } = useDeleteRegisteredMembersMutation();

  const members = useRegisteredMemberQuery({
    competition_id: id,
    page: page,
    member_type: type.id,
    weight_id: weight?.id,
  });

  const whois = localStorage.getItem("isLogin");
  const todayDate = new Date().toJSON().slice(0, 10);

  const downloadFile = () => {
    dispatch(dowloadCompetition(competition.data?.id));
  };

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const clearSearchParams = () => {
    setPage(1);
    setType({});
    setWeight({});
    setGender({});
  };

  function deleteRegisteredMembers() {
    const mutation = mutateAsync({ ids, id });
    toast
      .promise(mutation, {
        loading: "Loading...",
        success: "Successfully deleted",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        setIds([]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      render: (_, record) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          <img src={record.image ? record.image : imgUnsplash} alt="" />
          {record?.first_name} {record?.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "gender",
      dataIndex: "gender",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "region",
      dataIndex: "region",
      render: (status) => (
        <div
          className="role"
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "role",
      dataIndex: "role",
      render: (status) => (
        <div
          className="role"
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div
          onClick={() => {
            dispatch(setEditMember(record?.role));
            dispatch(getMember(record?.id));
            hiddenWindow();
          }}
        >
          <IcShape />
        </div>
      ),
    },
  ];

  const typePeople = [
    { id: "athlete", province: "Athlete" },
    { id: "coach", province: "Coach" },
    { id: "referee", province: "Referee" },
    { id: "doctor", province: "Doctor" },
    { id: "other", province: "Other" },
  ];

  const typeGender = [
    { id: "male", province: "Male" },
    { id: "female", province: "Female" },
  ];

  return (
    <div className={classes.member}>
      <div>
        <div className={classes.top}>
          <h4>Members</h4>
          <div className={classes.right}>
            <CDropdown
              text={"Sort by:"}
              value={type?.province}
              setState={setType}
              items={typePeople}
              border={"1px solid #92929D"}
              placeholder={"Member Type"}
            />
            <CDropdown
              text={"Sort by:"}
              value={gender?.province}
              setState={setGender}
              items={typeGender}
              border={"1px solid #92929D"}
              placeholder={"Gender Type"}
              margin={"0 0 0 20px"}
            />

            {gender?.id == "male" ? (
              <CDropdown
                text={"Sort by:"}
                value={weight?.province}
                setState={setWeight}
                items={competition?.data?.weight_category_male}
                border={"1px solid #92929D"}
                placeholder={"Weight Male"}
                margin={"0 0 0 20px"}
              />
            ) : null}

            {gender?.id == "female" ? (
              <CDropdown
                text={"Sort by:"}
                value={weight?.province}
                setState={setWeight}
                items={competition?.data?.weight_category_female}
                border={"1px solid #92929D"}
                placeholder={"Weight Female"}
                margin={"0 0 0 20px"}
              />
            ) : null}
            <CButton
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#ffffff"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"none"}
              padding={"4px 9px 0px 10px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              onClick={clearSearchParams}
            >
              <IcClose />
            </CButton>
            <CButton
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              className={ids.length > 0 ? classes.btnRed : classes.btnWhite}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"none"}
              padding={"4px 9px 0px 10px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              onClick={deleteRegisteredMembers}
              disabled={!ids.length}
            >
              <IcTrash className={ids.length > 0 ? classes.svg1 : null} />
            </CButton>
            {todayDate > competition.data?.end_register &&
            whois != "clubadmin" ? (
              <CButton
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={
                  competition?.loaderStatus != "loading" ? "#0062FF" : "#fff"
                }
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 16px"}
                borderRadius={"10px"}
                onClick={downloadFile}
                position={"relative"}
              >
                {competition?.loaderStatus == "loading" ? (
                  <CLoader width={"80px"} height={"80px"} />
                ) : (
                  "Print file"
                )}
              </CButton>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            hideSelectAll: true,
            selectedRowKeys: ids,
            onSelect: ({ id }) => {
              setIds((items) => {
                const exists = items.includes(id);
                if (exists) {
                  return items.filter((item) => item !== id);
                } else {
                  return [...items, id];
                }
              });
            },
          }}
          columns={columns}
          dataSource={members.data?.results}
          pagination={false}
        />
        {!!members.data?.count && (
          <PaginationNumber
            count={page}
            counts={members.data?.count}
            pages={members.data?.total_pages}
            defaultCurrent={page}
            total={members.data?.total_pages * 10}
            showSizeChanger={false}
            onChange={(e) => setPage(e)}
          />
        )}
      </div>
      {/* {member.member?.role == "athlete" && member.member ? (
        <EditMemberComponent disabled={"disabled"} />
      ) : member.member?.role == "doctor" && member.member ? (
        <EditDoctorComponent disabled={"disabled"} />
      ) : (member.member?.role == "refere" ||
          member.member?.role == "referee") &&
        member.member ? (
        <EditRefereeComponent disabled={"disabled"} />
      ) : member.member?.role == "coach" && member.member ? (
        <EditCoachComponent disabled={"disabled"} />
      ) : member.member?.role == "other" && member.member ? (
        <EditHostsComponent disabled={"disabled"} />
      ) : null} */}
    </div>
  );
}

export default RegistretedMember;
