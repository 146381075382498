import React, { useEffect } from "react";
import classes from "./style.module.css";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("isLogin") == "federationadmin") {
      navigate("all-members");
    }
  }, []);
  return (
    <div className={classes.not_found}>
      <div>
        <h1>404</h1>
        <h4>Ooops, page not found</h4>
      </div>
    </div>
  );
}

export default NotFoundPage;
