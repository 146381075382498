import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "antd";
import classes from "./style.module.css";
import { useFlightsMutation } from "hook/mutation";
import { useEffect, useState } from "react";
import {
  CButton,
  CDatePicker,
  CInput,
  CLoader,
  CPopup,
  CSelect,
} from "components";

function CreateFlights({ title, setOpen, dataDetails }) {
  const flightsMutation = useFlightsMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: { date: "" },
  });

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    dataDetails?.data?.visas_list.map((d) =>
      setCountries((current) => [...current, { province: d?.name, id: d?.id }])
    );
  }, []);

  const onSubmit = (e) => {
    const data = {
      date: e.date,
      flight: e.reason,
      visa: e.visa,
    };

    const flights = flightsMutation.mutateAsync(data);

    flights
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
        setCountries([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.flights}>
      <CPopup
        onClick={() => {
          setOpen(false);
        }}
        title={title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.flex}>
            <div style={{ margin: "22px 0", width: "50%" }}>
              <CSelect
                label={"Visa"}
                name="visa"
                items={countries}
                control={control}
                error={errors.visa}
                required
              />
            </div>
            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <CDatePicker
                label={"Date of Flight"}
                control={control}
                name="date"
                placeholder=""
                required={true}
                errors={errors}
                value={
                  watch().date != "" ? moment(watch().date, `YYYY-MM-DD`) : ""
                }
              />
            </div>
          </div>

          <CInput
            type="text"
            label="Reason"
            name="reason"
            register={register}
            errors={errors?.reason}
            required
          />

          <div className={classes.btns}>
            <CButton
              type="button"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"none"}
              fontSize={"14px"}
              lineHeight={"18px"}
              fontWeight={"600"}
              color={"#0062FF"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              onClick={() => reset()}
            >
              Clear
            </CButton>
            <CButton
              type="submit"
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={!!flightsMutation.isLoading ? "#fff" : "#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 25px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
              position={"relative"}
              height={"40px"}
            >
              {flightsMutation.isLoading ? (
                <CLoader width={"80px"} height={"80px"} />
              ) : (
                "Save"
              )}
            </CButton>
          </div>
        </form>
      </CPopup>
    </div>
  );
}

export default CreateFlights;
