import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import HeaderComponent from "../Header/HeaderComponent";
import SidebarComponent from "../Sidebar/SidebarComponent";
import "./layout.css";

function LayoutComponent() {
  const location = useLocation();
  const [inactive, setInactive] = useState(false);

  return (
    <>
      <SidebarComponent inactive={inactive} setInactive={setInactive} />
      <div id="main" className={`main ${inactive ? "inactive" : ""}`}>
        <HeaderComponent inactive={inactive} setInactive={setInactive} />
        <div
          className={`${
            location.pathname == "/restaurants-detail" ? null : "aside"
          } `}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default LayoutComponent;
