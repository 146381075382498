import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCitys } from "./fetcher";
import { URLBASE } from "../../../constants/applicationConstants";

const initialState = {
    regions: [],
    region: {},
    citys: [],
    status: null,
    error: null,
}

export const getRegion = createAsyncThunk('towns/getTowns', async function (_, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
        const response = await fetch(`${URLBASE}/region`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegion: (state, action) => {
            state.region = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRegion.pending, (state, action) => {
            state.status = "loading";
            state.regions = [];
        });

        builder.addCase(getRegion.fulfilled, (state, action) => {
            state.regions = action.payload;
            state.status = "success";
        });

        builder.addCase(getRegion.rejected, (state, action) => {
            state.status = "error";
            state.regions = [];
        });

        builder.addCase(getCitys.pending, (state, action) => {
            state.status = "loading";
            state.citys = [];
        });

        builder.addCase(getCitys.fulfilled, (state, action) => {
            state.citys = action.payload;
            state.status = "success";
        });

        builder.addCase(getCitys.rejected, (state, action) => {
            state.status = "error";
            state.citys = [];
        });
    },
})

export const { adminCreate, setRegion } = regionSlice.actions;
export default regionSlice.reducer;