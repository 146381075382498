import moment from "moment";
import { useEffect } from "react";
import { Col, Row } from "antd";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { useGetMemberQuery } from "hook/query";
import { CButton, CDatePicker, CInput, CPopup, CSelect } from "components";
import {
  useDeleteMemberMutation,
  usePatchMemberMutation,
  useRecoverMemberMutation,
} from "hook/mutation";
import toast from "react-hot-toast";

function EditHost({ disabled, open, setOpen, id, memberList, setId }) {
  const patchMemberMutation = usePatchMemberMutation();
  const deleteMemberMutation = useDeleteMemberMutation();
  const recoverMemberMutation = useRecoverMemberMutation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm();

  const userInfo = useGetMemberQuery({
    enabled: !!id,
    id: id,
  });

  useEffect(() => {
    setValue("first_name", userInfo.data?.first_name);
    setValue("last_name", userInfo.data?.last_name);
    setValue("middle_name", userInfo.data?.middle_name);
    setValue("birth_date", userInfo.data?.birth_date);
    setValue("gender", userInfo.data?.gender);
    setValue("phone_number", userInfo.data?.phone_number);
    setValue("other_role_data", userInfo.data?.other_role_data);
    setValue("status", userInfo.data?.status);
  }, [userInfo.isSuccess]);

  const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
  };

  const onSubmit = (e) => {
    const patchMember = patchMemberMutation.mutateAsync({ data: e, id: id });
    toast
      .promise(patchMember, {
        loading: "Loading...",
        success: "Member edited successfully",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        reset();
        setOpen("");
        setId("");
        removeHiddenWindow();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMemberFunc = () => {
    const deleteMember = deleteMemberMutation.mutateAsync(id);
    toast
      .promise(deleteMember, {
        loading: "Loading...",
        success: "Member deleted successfully",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        reset();
        setOpen("");
        setId("");
        removeHiddenWindow();
        memberList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const recoverMemberFunc = () => {
    const recoverMember = recoverMemberMutation.mutateAsync(id);
    recoverMember
      .then((res) => {
        reset();
        setOpen("");
        setId("");
        removeHiddenWindow();
        memberList.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (open !== "other") return null;

  return (
    <CPopup
      onClick={() => {
        setOpen("");
        setId("");
        reset();
      }}
      title={disabled ? "Host's Info" : "Edit Host"}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.host}>
        <Row gutter={[40, 8]}>
          <Col span={11}>
            <CInput
              type="text"
              label="Last name"
              name="last_name"
              register={register}
              errors={errors?.last_name}
              required
              disabled={disabled}
            />
            <CInput
              type="text"
              label="First name"
              name="first_name"
              register={register}
              errors={errors?.first_name}
              required
              disabled={disabled}
            />
            <CInput
              type="text"
              label="Middle name"
              name="middle_name"
              register={register}
              errors={errors?.middle_name}
              required
              disabled={disabled}
            />
            <CDatePicker
              label={"Birth date"}
              control={control}
              name="birth_date"
              placeholder=""
              required={true}
              errors={errors.birth_date}
              value={
                watch().birth_date != ""
                  ? moment(watch().birth_date, `YYYY-MM-DD`)
                  : ""
              }
            />
          </Col>
          <Col style={{ paddingTop: "9px" }} span={13}>
            <div style={{ margin: "10px 0" }}>
              <CSelect
                label={"Gender"}
                name="gender"
                items={[
                  { province: "Male", id: "male" },
                  { province: "Female", id: "female" },
                ]}
                control={control}
                error={errors.gender}
                required={true}
                disabled={disabled}
              />
            </div>
            <CInput
              type="tel"
              label="Contacts"
              name="phone_number"
              register={register}
              errors={errors?.phone_number}
              required
              pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
              maxLength={13}
              minLength={13}
              disabled={disabled}
            />

            <CInput
              type="text"
              label="Role Info"
              name="other_role_data"
              register={register}
              errors={errors?.other_role_data}
              required
              disabled={disabled}
            />
            <div style={{ wigth: "210px" }}>
              {disabled ? null : (
                <div className="flex-row">
                  <CButton
                    type="button"
                    fontFamily={"Poppins"}
                    fontStyle={"normal"}
                    background={"#fff"}
                    fontSize={"14px"}
                    lineHeight={"18px"}
                    fontWeight={"600"}
                    color={"#FC5A5A"}
                    border={"1px solid #FC5A5A"}
                    padding={"9px 35px"}
                    borderRadius={"10px"}
                    onClick={deleteMemberFunc}
                    disabled={disabled}
                  >
                    Delete
                  </CButton>
                  <CButton
                    fontFamily={"Poppins"}
                    fontStyle={"normal"}
                    background={"#0062FF"}
                    fontSize={"14px"}
                    lineHeight={"21px"}
                    fontWeight={"500"}
                    color={"#fff"}
                    border={"1px solid #0062FF"}
                    padding={"9px 25px"}
                    borderRadius={"10px"}
                    margin={"0 0 0 20px"}
                    disabled={disabled}
                  >
                    Edit
                  </CButton>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </form>
    </CPopup>
  );
}

export default EditHost;
