import moment from "moment";
import { Col, Row } from "antd";
import classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { usePostMemberForNationalTeamMutation } from "hook/mutation";
import {
  CButton,
  CDatePicker,
  CInput,
  CLoader,
  CPopup,
  CSelect,
  CUpload,
} from "components";

function CreateMemberForNationalTeam({ title, setOpen, nationalTeamMember }) {
  const postMemberForNationalTeamMutation =
    usePostMemberForNationalTeamMutation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      ijf_id_exp: "",
      birth_date: "",
      given_date: "",
      expired_date: "",
    },
  });

  const onSubmit = (e) => {
    let formData = new FormData();
    formData.append("first_name", e?.first_name);
    formData.append("last_name", e?.last_name);
    formData.append("birth_date", e?.birth_date);
    formData.append("ijf_id", e?.ijf_id);
    formData.append("ijf_id_expired_date", e?.ijf_id_exp);
    formData.append("passport_series", e?.passport_series);
    formData.append("passport_given", e?.given_date);
    formData.append("passport_expired", e?.expired_date);
    formData.append("gender", e?.gender);
    formData.append("weight", e?.weight);
    formData.append("document", e?.document);
    formData.append("image", e?.image);

    const postMemberForNationalTeam =
      postMemberForNationalTeamMutation.mutateAsync(formData);
    postMemberForNationalTeam
      .then((res) => {
        console.log(res);
        reset();
        setOpen(0);
        nationalTeamMember.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPopup
      onClick={() => {
        setOpen(0);
      }}
      title={title}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.create_admin}>
        <Row gutter={[33, 8]}>
          <Col span={10}>
            <div className={classes.flex}>
              <CUpload
                control={control}
                name="image"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Photo"}
                title={"Name Image"}
                type={true}
                error={errors.image}
              />
              <CUpload
                control={control}
                name="document"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Document"}
                title={"Name Document"}
                type={false}
                error={errors.document}
              />
            </div>
            <CInput
              type="text"
              label="Last name"
              name="last_name"
              register={register}
              errors={errors?.last_name}
              required
            />
            <CInput
              type="text"
              label="First name"
              name="first_name"
              register={register}
              errors={errors?.first_name}
              required
            />
            <CSelect
              label={"Gender"}
              name="gender"
              items={[
                { province: "Male", id: "male" },
                { province: "Female", id: "female" },
              ]}
              control={control}
              error={errors.gender}
              required
            />
          </Col>
          <Col style={{ paddingTop: "9px" }} span={7}>
            <CDatePicker
              label={"Date of birth"}
              control={control}
              name="birth_date"
              placeholder=""
              required={true}
              errors={errors.birth_date}
              value={
                watch().birth_date != ""
                  ? moment(watch().birth_date, `YYYY-MM-DD`)
                  : ""
              }
            />
            <CInput
              type="number"
              label="Weight"
              name="weight"
              register={register}
              errors={errors?.weight}
              required
            />
            <CInput
              type="text"
              label="Passport series"
              name="passport_series"
              register={register}
              errors={errors?.passport_series}
              required
            />
            <div style={{ marginBottom: 20 }}>
              <CDatePicker
                label={"Passport given date"}
                control={control}
                name="given_date"
                placeholder=""
                required={true}
                errors={errors.given_date}
                value={
                  watch().given_date != ""
                    ? moment(watch().given_date, `YYYY-MM-DD`)
                    : ""
                }
              />
            </div>
            <CDatePicker
              label={"Passport expired date"}
              control={control}
              name="expired_date"
              placeholder=""
              required={true}
              errors={errors.expired_date}
              value={
                watch().expired_date != ""
                  ? moment(watch().expired_date, `YYYY-MM-DD`)
                  : ""
              }
            />
          </Col>
          <Col span={7}>
            <CInput
              type="number"
              label="IJF ID"
              name="ijf_id"
              register={register}
              errors={errors?.ijf_id}
              placeholder={errors?.ijf_id && "123456"}
              required
              margin={"10px 0 20px 0"}
            />

            <div style={{ marginBottom: 20 }}>
              <CDatePicker
                label={"IJF ID expired date"}
                control={control}
                name="ijf_id_exp"
                placeholder=""
                required
                errors={errors.ijf_id_exp}
                value={
                  watch().ijf_id_exp != ""
                    ? moment(watch().ijf_id_exp, `YYYY-MM-DD`)
                    : ""
                }
              />
            </div>
            <div className={classes.flex}>
              <CButton
                type="button"
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"none"}
                fontSize={"14px"}
                lineHeight={"18px"}
                fontWeight={"600"}
                color={"#0062FF"}
                border={"1px solid #0062FF"}
                padding={"9px 25px"}
                borderRadius={"10px"}
                onClick={() => reset()}
              >
                Clear
              </CButton>
              <CButton
                type="submit"
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={
                  postMemberForNationalTeamMutation.isLoading
                    ? "#fff"
                    : "#0062FF"
                }
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 25px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
                height={"40px"}
                position={"relative"}
                disabled={postMemberForNationalTeamMutation.isLoading}
              >
                {postMemberForNationalTeamMutation.isLoading ? (
                  <CLoader width={"80px"} height={"80px"} />
                ) : (
                  "Create"
                )}
              </CButton>
            </div>
          </Col>
        </Row>
      </form>
    </CPopup>
  );
}

export default CreateMemberForNationalTeam;
