import React, { useState } from "react";
import classes from "./style.module.css";
import { ReactComponent as IcDropdown } from "assets/images/icons/ic_Dropdown.svg";

const CDropdownInput = ({
  items,
  value,
  setState,
  clickInput,
  label,
  required,
}) => {
  const [title, setTitle] = useState(value);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      {!!label && (
        <label className={classes.label} htmlFor="input">
          {label}
        </label>
      )}
      <div
        className={classes.dropdown_input}
        onClick={() => {
          setShowDropdown(!showDropdown);
          clickInput();
        }}
      >
        <div className={classes.title}>{title}</div>
        <div
          className={classes.icon}
          onClick={() => {
            setShowDropdown(!showDropdown);
            clickInput();
          }}
        >
          <IcDropdown />
        </div>
        <div
          style={{
            display: showDropdown ? "block" : "none",
            height: items?.length > 3 ? "118px" : "auto",
          }}
          className={classes.items}
        >
          {items?.map((item) => (
            <div
              className={classes.item}
              key={item.id}
              onClick={() => {
                setTitle(item.weight);
                setShowDropdown(!showDropdown);
                setState(item);
                setTitle(item.province ? item.province : item.weight);
                // setTitle(item)
              }}
            >
              {item.province}
              {item.weight}
            </div>
          ))}
        </div>
      </div>
      {showDropdown ? (
        <div
          className={classes.close_back}
          onClick={() => setShowDropdown(!showDropdown)}
        ></div>
      ) : null}
    </div>
  );
};

export default CDropdownInput;
