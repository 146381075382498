import React from "react";

function PaginationNumber({ count, counts, pages }) {
  const firstCount = (count) => {
    return count < 2 ? 1 : (count - 1) * 16;
  };
  const secondCount = () => {
    if (count == pages) return counts;
    if (counts < 16) {
      return counts;
    } else {
      return 16 * (count ? count : 1);
    }
  };
  return (
    <>
      <p className="paginationCount">
        {firstCount(count)}-{secondCount(counts)} of {counts} items
      </p>
    </>
  );
}

export default PaginationNumber;
