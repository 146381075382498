import axios from "axios";

const accessToken = localStorage.getItem("access");

export const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + accessToken,
  },
});
export const gridCreate = (id) => {
  return request.post(
    "/grid/create/",
    {},
    {
      params: {
        competition_id: id,
      },
    }
  );
};

export const getWeightCategories = (competition_id, gender) => {
  return request.get("/competition/weight/categories/for/grid/", {
    params: {
      competition_id: competition_id,
      gender: gender,
    },
  });
};

export const getCompetitionRegisteredMembers = (searchParams, page) => {
  return request.get("/registrsted/members/in/competition/", {
    params: {
      competition_id: searchParams.get("competition_id"),
      weight_id: searchParams.get("weight_id"),
      gender: searchParams.get("gender"),
      page: page,
      member_type: "athlete",
    },
  });
};

export const getGridData = (searchParams) => {
  return request.get("grid/get/", {
    params: {
      competition_id: searchParams.get("competition_id"),
      weight_id: searchParams.get("weight_id"),
      gender: searchParams.get("gender"),
    },
  });
};

export const patchPoolMember = (data, id) => {
  return request.patch(`/patch/pool/${id}`, data);
};

export const getGridResult = (searchParams) => {
  return request.get("grid/final/results/", {
    params: {
      competition_id: searchParams.get("competition_id"),
      weight_id: searchParams.get("weight_id"),
      gender: searchParams.get("gender"),
    },
  });
};

export const patchResultMember = (data, id) => {
  return request.patch(`/final/result/pleace/patch/${id}`, data);
};
