import { saveAs } from "file-saver";
import { createSlice } from "@reduxjs/toolkit";
import { createOrder, getFile, getMemberForPayment, getOrderInfo, getOrderMember, getOrders, orderPatch } from "./fetcher";

const initialState = {
    orders: [],
    statusOrder: "",
    membersPayment: {},
    selectMembers: [],
    selectMembersId: [],
    order: {},
    member: [],
    orderInfo: [],
    status: null,
    error: null,
    popup: false,
    popupMember: "close"
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPopup: (state, action) => {
            state.popup = action.payload
        },
        setStatusOrder: (state, action) => {
            state.statusOrder = action.payload
        },
        setSelectMember: (state, action) => {
            const findMember = state.selectMembers?.find(item => item.id == action.payload.id);
            if (findMember?.id == action.payload.id) {
                const deleteMember = state.selectMembers?.filter((member) => member?.id != findMember.id)
                state.selectMembers = deleteMember
            } else {
                state.selectMembers.push(action.payload)
            }
        },

        setSelectMembersId: (state, action) => {
            const id = state.selectMembersId.find(item => item == action.payload);
            if (id == action.payload) {
                const deleteState = state.selectMembersId.filter((currentValue) => currentValue != action.payload)

                state.selectMembersId = deleteState
            } else {
                state.selectMembersId.push(parseInt(action.payload));
            }

        },
        setPopupMember: (state, action) => {
            state.popupMember = action.payload

        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrders.pending, (state, action) => {
            state.status = "loading";
            state.orders = [];
        });

        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.status = "success";
        });

        builder.addCase(getOrders.rejected, (state, action) => {
            state.status = "error";
            state.orders = [];
        });

        builder.addCase(getMemberForPayment.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getMemberForPayment.fulfilled, (state, action) => {
            state.membersPayment = action.payload;
            state.selectMembers = [];
            state.selectMembersId = [];
            state.status = "success";
        });

        builder.addCase(getMemberForPayment.rejected, (state, action) => {
            state.status = "error";
            state.membersPayment = [];
        });

        builder.addCase(createOrder.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(createOrder.fulfilled, (state, action) => {
            saveAs(action.payload.chek, `chek_for_order_${action.payload.id}.pdf`);
            // state.order = action.payload
            state.status = "success";
        });

        builder.addCase(createOrder.rejected, (state, action) => {
            state.status = "error";
        });

        builder.addCase(orderPatch.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(orderPatch.fulfilled, (state, action) => {
            state.order = action.payload
            state.popup = false
            state.membersPayment = {}
            state.selectMembers = []
            state.selectMembersId = []
            state.status = "success";
        });

        builder.addCase(orderPatch.rejected, (state, action) => {
            state.status = "error";
        });

        builder.addCase(getOrderMember.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getOrderMember.fulfilled, (state, action) => {
            state.member = action.payload
            state.status = "success";
        });

        builder.addCase(getOrderMember.rejected, (state, action) => {
            state.status = "error";
            state.member = [];
        });
        builder.addCase(getOrderInfo.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getOrderInfo.fulfilled, (state, action) => {
            state.orderInfo = [action.payload]
            state.status = "success";
        });

        builder.addCase(getOrderInfo.rejected, (state, action) => {
            state.status = "error";
            state.orderInfo = [];
        });
        builder.addCase(getFile.pending, (state, action) => {
            state.status = "loading";
        });

        builder.addCase(getFile.fulfilled, (state, action) => {
            saveAs(action.payload.chek, `chek_for_order_${action.payload.id}.pdf`);
            state.status = "success";
        });

        builder.addCase(getFile.rejected, (state, action) => {
            state.status = "error";
        });

    },
})

export const { setStatusOrder, setSelectMember, setSelectMembersId, setPopupMember, setPopup } = paymentSlice.actions;
export default paymentSlice.reducer;