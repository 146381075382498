import { useEffect, useState } from "react";
import { Table } from "antd";
import classes from "./style.module.css";
import { useSelector } from "react-redux";
import imgUnsplash from "assets/images/notfound/nopic.jpg";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/ic_Close.svg";
import PopupWeight from "components/Сompetitions/Registration/PopupWeightComponent";
import { CButton, CDropdownInput, CLoader, CSearchInput } from "components";
import { useCompetitionQuery, useCompetitionRegisterQuery } from "hook/query";
import { LinkButton, PaginationNumber } from "container";
import { usePostRegisterCompetitionMutation } from "hook/mutation";

function CompetetionRegisterPage() {
  const postRegisterCompetitionMutation = usePostRegisterCompetitionMutation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [member, setMember] = useState([]);
  const [members, setMembers] = useState([]);

  const [newMembers, setNewMembers] = useState([]);
  const [idMemberForWeight, setIdMemberForWeight] = useState([]);

  const [type, setType] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const listMembers = useCompetitionRegisterQuery({
    competition_id: searchParams.get("competition_id"),
    page: page,
    member_type: type?.path,
    member: member,
  });

  useEffect(() => {
    if (listMembers.data?.results) {
      const filteredMembers = listMembers.data.results.filter(
        (member) => !newMembers.some((newMember) => newMember.id === member.id)
      );
      setMembers(filteredMembers);
    }
  }, [listMembers, newMembers]);

  const competitionDetailDetail = useCompetitionQuery({
    id: searchParams.get("competition_id"),
  });

  const registration = useSelector((state) => state.registration);
  const competitions = useSelector((state) => state.competitions);
  const [weigthMember, setWeigthMember] = useState(null);

  const onSubmit = (e) => {
    let data = {
      competition: Number(e?.id),
      members: newMembers,
    };

    const postRegisterCompetition =
      postRegisterCompetitionMutation.mutateAsync(data);
    postRegisterCompetition
      .then((res) => {
        navigate(`/competitions/detail/${e?.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMemberList = (e) => {
    const deleteMembers = members?.filter((member) => member.id != e.id);
    setMembers(deleteMembers);
  };

  const addMemberListAthlete = (e) => {
    setNewMembers([...newMembers, e]);
  };

  const editWeightInList = ({ e, record }) => {
    const itemIndex = newMembers.findIndex(
      (member) => member.member == record.member
    );

    const item = newMembers[itemIndex];
    item.weight_category = e.id;
    item.weight_category_name = e.weight;

    const newListMember = newMembers.filter(
      (member) => member.member != record.member
    );

    setNewMembers([...newListMember, item]);
  };

  const deleteMemberId = (e) => {
    const deleteIdMember = idMemberForWeight?.filter(
      (idWeight) => idWeight != e.id
    );
    setIdMemberForWeight(deleteIdMember);
  };

  const addMemberList = (e) => {
    const findMember = members.data?.results.filter(
      (member) => member.id == e.id
    );
    // if (findMember?.length == 0) {
    //   state.selectMembers.push(action.payload);
    //   state.idMemberForWeight.push(action.payload.id);
    //   state.sendDataMember.push({
    //     member: action.payload.id,
    //     first_name: action.payload.first_name,
    //     last_name: action.payload.last_name,
    //     birth_date: action.payload.birth_date,
    //     gender: action.payload.gender,
    //     role: action.payload.role,
    //     region: action.payload.region,
    //     region_id: action.payload.region_id,
    //     status: action.payload.status,
    //   });
    // }
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
    },
    {
      title: "Name",
      dataIndex: "first_name",
      render: (_, record) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          <img src={record.image ? record.image : imgUnsplash} alt="" />
          {record?.first_name} {record?.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "region",
      dataIndex: "region",
      render: (region) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>{region}</div>
      ),
    },
    {
      title: "role",
      dataIndex: "role",
      render: (status) => (
        <div
          className="role"
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div>
          {record.status == "available" ? (
            <CButton
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={record.role != "athlete" ? "#5e636a" : "#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={
                record.role != "athlete" ? "#5e636a" : "1px solid #0062FF"
              }
              padding={"4px 12px"}
              borderRadius={"6px"}
              disabled={record.role != "athlete"}
              onClick={() => {
                if (record.role != "athlete") {
                  addMemberList(record);
                  deleteMemberList(record);
                }
                if (record.role == "athlete") {
                  setOpen(true);
                  setWeigthMember(record);
                }
              }}
            >
              Register
            </CButton>
          ) : null}
        </div>
      ),
    },
  ];

  const secondColumns = [
    {
      title: "",
      dataIndex: "",
    },
    {
      title: "Name",
      dataIndex: "first_name",
      render: (_, record) => {
        console.log(record);
        return (
          <div
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              textTransform: "capitalize",
            }}
          >
            <img src={record.image ? record.image : imgUnsplash} alt="" />
            {record?.first_name} {record?.last_name}
          </div>
        );
      },
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "region",
      dataIndex: "region",
      render: (region) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>{region}</div>
      ),
    },
    {
      title: "role",
      dataIndex: "role",
      render: (status) => (
        <div
          className="role"
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "weight",
      dataIndex: "weight",
      width: "200px",
      render: (_, record) => (
        <div>
          {record.role == "athlete" ? (
            <CDropdownInput
              items={
                record.gender == "female"
                  ? competitionDetailDetail.data?.weight_category_female
                  : competitionDetailDetail.data?.weight_category_male
              }
              value={record.weight_category_name}
              setState={(e) => {
                editWeightInList({ e: e, record: record });
                deleteMemberId(record);
              }}
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "role",
      render: (_, record) => (
        <div>
          {record.status == "restricted" ? (
            <CButton
              background={"none"}
              padding={"10px 0"}
              color={"#fff"}
              margin={"8px 0 0 0"}
              border={"none"}
              onClick={() => deleteMemberList(record)}
            >
              <CloseIcon />
            </CButton>
          ) : null}
        </div>
      ),
    },
  ];

  const clearSearchParams = () => {
    setType();
    setPage(1);
    setMember();
  };

  const listTypesMembers = [
    {
      path: "athlete",
      title: "Athlete",
    },
    {
      path: "referee",
      title: "Referee",
    },
    {
      path: "doctor",
      title: "Doctor",
    },
    {
      path: "coach",
      title: "Coaches",
    },
    {
      path: "other",
      title: "Others",
    },
  ];

  if (listMembers.isLoading) return <CLoader />;
  return (
    <div className={classes.registration}>
      <div className={classes.flex}>
        <div className={classes.flex}>
          {listTypesMembers.map((member) => (
            <LinkButton
              key={member?.path}
              pathName={member?.path}
              type={type}
              onClick={(e) => setType(e)}
            >
              {member?.title}
            </LinkButton>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 20px 10px 0"}
            onClick={clearSearchParams}
          >
            <IcTrash />
          </CButton>
          <CButton
            type="button"
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={registration.status != "loading" ? "#0062FF" : "#fff"}
            fontSize={"12px"}
            lineHeight={"18px"}
            fontWeight={"600"}
            color={"#fff"}
            border={"1px solid #0062FF"}
            padding={"10px 35px"}
            borderRadius={"10px"}
            margin={"0 0 10px 0"}
            onClick={() => {
              onSubmit({ id: searchParams.get("competition_id") });
            }}
          >
            {registration.status == "loading" ? (
              <CLoader width={"80px"} height={"80px"} />
            ) : (
              "Register"
            )}
          </CButton>
        </div>
      </div>
      <CSearchInput placeholder={"search"} onChange={(e) => setMember(e)} />
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={members}
          pagination={false}
        />
        {listMembers.data?.results?.length > 0 ? (
          <div className={classes.pagination}>
            <PaginationNumber
              count={page}
              counts={listMembers.data?.count}
              pages={listMembers.data?.total_pages}
              defaultCurrent={page}
              total={listMembers.data?.total_pages * 10}
              showSizeChanger={false}
              onChange={setPage}
            />
          </div>
        ) : null}
      </div>
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          columns={secondColumns}
          dataSource={newMembers}
          pagination={false}
        />
      </div>
      {open && (
        <PopupWeight
          close={() => setOpen(false)}
          record={weigthMember}
          deleteMember={deleteMemberList}
          deleteMemberId={deleteMemberId}
          addMemberListAthlete={addMemberListAthlete}
          weights={competitionDetailDetail.data}
        />
      )}
    </div>
  );
}

export default CompetetionRegisterPage;
