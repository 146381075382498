import classes from "./style.module.css";
import { useState } from "react";
import { Pagination, Table } from "antd";
import noImage from "assets/images/notfound/nopic.jpg";
import { CButton, CDropdown, CLoader } from "components";
import PaginationNumber from "components/Pagination/PaginationNumber";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { ReactComponent as IcChevronDown } from "assets/images/icons/ic_Chevron_Down.svg";
import {
  useGetContributionsQuery,
  useGetRankingQuery,
  useGetWeightsQuery,
} from "hook/query";

function RankingPage() {
  const [page, setPage] = useState("");
  const [weight, setWeight] = useState("");
  const [level, setLevel] = useState("");
  const [gender, setGender] = useState("");

  const listWeights = useGetWeightsQuery({ enabled: true });
  const listContributions = useGetContributionsQuery({ enabled: true });
  const listRanking = useGetRankingQuery({
    enabled: true,
    level: level,
    gender: gender,
    weight_id: weight.id,
  });

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const columns = [
    {
      title: "#ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          <img src={record ? record.image : noImage} alt="" />
          {record.last_name}
        </div>
      ),
    },
    {
      title: "birth at",
      dataIndex: "birth_date",
    },
    {
      title: "#Id",
      dataIndex: "id",
    },
    {
      title: "Ranking",
      dataIndex: "ranking",
      render: (record) => <div>{record ? record : "-"}</div>,
    },
    {
      title: "Points",
      dataIndex: "points",
    },
    {
      title: "Region",
      dataIndex: "region",
    },
    {
      title: "",
      dataIndex: "status",
      render: (_, record) => (
        <div
          onClick={() => {
            hiddenWindow();
          }}
        >
          <IcChevronDown />
        </div>
      ),
    },
  ];

  const clearSearchParams = () => {
    setWeight("");
    setLevel("");
    setGender("");
  };

  if (listRanking.isLoading) return <CLoader />;

  return (
    <div className={classes.ranking}>
      <div className={classes.header}>
        <h4>Ranking</h4>
        <div className={classes.flex}>
          <CDropdown
            text={"Show:"}
            value={weight.weight}
            setState={setWeight}
            items={listWeights?.data}
            placeholder={"weight"}
          />
          <CDropdown
            margin={"0 15px"}
            text={"Show:"}
            value={level.type}
            setState={setLevel}
            items={listContributions?.data}
            placeholder={"level"}
          />
          <CDropdown
            text={"Show:"}
            value={gender.province}
            setState={setGender}
            items={[
              { province: "Male", id: "male" },
              { province: "Female", id: "female" },
            ]}
            placeholder={"gender"}
          />
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={clearSearchParams}
          >
            <IcTrash />
          </CButton>
        </div>
      </div>
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          dataSource={listRanking.data?.results}
          columns={columns}
          pagination={false}
        />
        {listRanking.data?.total_pages > 1 ? (
          <div className={classes.pagination}>
            <PaginationNumber
              count={page}
              counts={listRanking.data?.count}
              pages={listRanking.data?.total_pages}
            />
            <Pagination
              defaultCurrent={page}
              total={listRanking.data?.total_pages * 10}
              showSizeChanger={false}
              onChange={(e) => setPage(e)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RankingPage;
