import { useQuery } from "react-query";
import { request } from "services/api";

export const useRegisteredMemberQuery = ({
  enabled,
  competition_id,
  member_type,
  weight_id,
  page,
}) => {
  return useQuery(
    [
      "registered-members",
      enabled,
      competition_id,
      member_type,
      weight_id,
      page,
    ],
    async () => {
      const { data } = await request.get(
        `/registrsted/members/in/competition/`,
        {
          params: {
            competition_id,
            member_type,
            weight_id,
            page,
          },
        }
      );
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
