import { useQuery } from "react-query";
import { request } from "services/api";

export const useNationalTeamMembers = ({ enabled, page, gender }) => {
  return useQuery(
    ["national-team-members", page, gender],
    async () => {
      const { data } = await request.get(`/national/team/members`, {
        params: { page, gender },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
