import classes from "./style.module.css";

const CInputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  margin,
  width,
  disabled,
  required,
}) => (
  <div className={classes.input_field} style={{ margin, width }}>
    {label && <label htmlFor="input-field">{label}</label>}
    <br />
    <input
      type={type}
      value={value}
      name={name}
      className="form-control"
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      disabled={disabled}
      required={required}
    />
  </div>
);

export default CInputField;
