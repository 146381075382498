import { useState } from "react";
import classes from "./style.module.css";
import { ReactComponent as IcDropdown } from "../../assets/images/icons/ic_Dropdown.svg";

function CDropdown({
  items,
  value,
  text,
  setState,
  margin,
  border,
  placeholder,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const clickFunct = (e) => {
    setState(e);
    console.log(e);
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <div className={classes.dropdown} style={{ margin, border }}>
        <div
          className={classes.dropdownTitle}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {text}
          <span> {value}</span>
        </div>
        <div
          className={classes.dropdownIcon}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <IcDropdown />
        </div>
        <p className={classes.placeholder}>{value ? null : placeholder}</p>
        {!!items ? (
          <div
            style={{ display: !!showDropdown ? "block" : "none" }}
            className={classes.dropdownItems}
          >
            {items?.map((item, id) => (
              <div
                className={classes.dropdownItem}
                key={id + 100}
                onClick={() => clickFunct(item)}
              >
                {item?.province}
                {item?.weight}
                {item?.type}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {showDropdown ? (
        <div
          className={classes.closeBackDropdown}
          onClick={() => setShowDropdown(!showDropdown)}
        />
      ) : null}
    </>
  );
}

export default CDropdown;
