import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const getOrders = createAsyncThunk(
  "payment/getOrders",
  async function (params, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/main/order${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getMemberForPayment = createAsyncThunk(
  "payment/getMemberForPayment",
  async function (params, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(
        `${URLBASE}/memberlist-for-payment/${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createOrder = createAsyncThunk(
  "payment/createOrder",
  async function (data, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");
    try {
      const response = await fetch(`${URLBASE}/main/order/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          members: data.map((item) => item.id),
        }),
      });

      const res = await response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const orderPatch = createAsyncThunk(
  "payment/orderPatch",
  async function ({ id, statusDate }, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/main/order/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          status: statusDate,
        }),
      });

      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrderMember = createAsyncThunk(
  "payment/getOrderMember",
  async function (params, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/members/in/order/${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrderInfo = createAsyncThunk(
  "payment/getOrderInfo",
  async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/main/order/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFile = createAsyncThunk(
  "payment/getFile",
  async function (id, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
      const response = await fetch(`${URLBASE}/re/download/chek/${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = response.json();

      if (!response.ok) {
        localStorage.setItem("isLogin", "no access");
        localStorage.clear();
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
