import { Table } from "antd";
import React, { useState } from "react";
import classes from "./style.module.css";
import { useParams } from "react-router-dom";
import {
  CreateFlights,
  CreateVisa,
  EditFlights,
  EditVisa,
  NationalTeamUserInfo,
} from "container";
import {
  useFlightsDetails,
  useGetFlightsIdQuery,
  useGetVisaIdQuery,
  useNationalTeamMember,
} from "hook/query";

function VisaDatas() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [fopen, setFOpen] = useState(false);
  const [editOpenVisa, setEditOpenVisa] = useState(0);
  const [editOpenFlight, setEditOpenFlight] = useState(0);

  const dataUser = useNationalTeamMember({
    enabled: true,
    id: id,
    open: fopen,
  });

  const dataDetails = useFlightsDetails({
    enabled: true,
    athlete_id: id,
  });

  const useGetVisaId = useGetVisaIdQuery({
    enabled: editOpenVisa != 0,
    id: editOpenVisa,
  });

  const useGetFlightsId = useGetFlightsIdQuery({
    enabled: editOpenFlight != 0,
    id: editOpenFlight,
  });

  const columns = [
    {
      title: "Country",
      dataIndex: "name",
      render: (text) => text,
    },
    {
      title: "Given date",
      dataIndex: "given_date",
      render: (text) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
    },

    {
      title: "",
      dataIndex: "detail",
      render: (_, record) => (
        <div
          onClick={() => setEditOpenVisa(record?.id)}
          style={{
            color: "#3DD598",
            textAlign: "right",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Detail
        </div>
      ),
    },
  ];

  return (
    <div className={classes.vise__datas}>
      <NationalTeamUserInfo data={dataUser.data} />
      <div className={classes.visa__list}>
        <div className={classes.flex}>
          <h4>Visa list</h4>
          <button type="button" onClick={() => setOpen(true)}>
            + Add Visa
          </button>
        </div>
        <Table
          rowKey={(record) => record.id}
          rowSelection={false}
          columns={columns}
          dataSource={dataDetails.data?.visas_list}
          pagination={false}
        />
      </div>

      <div className={classes.flights}>
        <div className={classes.flex}>
          <h4>Flights</h4>
          <button type="button" onClick={() => setFOpen(true)}>
            + Add
          </button>
        </div>
        <div className={classes.table}>
          {dataDetails.data?.flights_list.map((d, index) => (
            <div className={classes.tr} key={index}>
              <h5 onClick={() => setEditOpenFlight(d?.id)}>{d?.flight}</h5>
              <h5>{d?.visa}</h5>
              <h5>{d?.date}</h5>
            </div>
          ))}
        </div>
      </div>

      {open ? (
        <CreateVisa
          title={"Add Visa"}
          setOpen={setOpen}
          id={id}
          dataDetails={dataDetails}
        />
      ) : null}

      {editOpenVisa ? (
        <EditVisa
          title={"Edit Visa"}
          setOpen={setEditOpenVisa}
          dataDetails={dataDetails}
          data={useGetVisaId}
        />
      ) : null}

      {fopen ? (
        <CreateFlights
          title={"Add Flight"}
          setOpen={setFOpen}
          dataDetails={dataDetails}
        />
      ) : null}

      {editOpenFlight ? (
        <EditFlights
          title={"Edit Flight"}
          setOpen={setEditOpenFlight}
          dataDetails={dataDetails}
          data={useGetFlightsId}
        />
      ) : null}
    </div>
  );
}

export default VisaDatas;
