import moment from "moment";
import "moment/locale/zh-cn";
import classes from "./style.module.css";
import { Col, Row } from "antd";
import { useRegionsQuery } from "hook/query";
import { usePostAdminMutation } from "hook/mutation";
import { useForm } from "react-hook-form";
import {
  CButton,
  CDatePicker,
  CInput,
  CPopup,
  CSelect,
  CUpload,
} from "components";

function CreateAdmin({ setOpen, dataDetails }) {
  const postAdminMutation = usePostAdminMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      phone_number: "+998",
      birth_date: "",
    },
  });

  const regionsList = useRegionsQuery({});

  const removeHiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.remove("mainHidden");
  };

  const onSubmit = (e) => {
    const formData = new FormData();
    formData.append("username", e.username);
    formData.append("password", e.password);
    formData.append("first_name", e.first_name);
    formData.append("last_name", e.last_name);
    formData.append("middle_name", e.middle_name);
    formData.append("phone_number", e.phone_number);
    formData.append("birth_date", e.birth_date);
    formData.append("region", e.region);
    formData.append("admin_type", e.admin_type);
    formData.append("image", e.image);
    formData.append("document_image", e.document_image);

    const postAdmin = postAdminMutation.mutateAsync(formData);
    postAdmin
      .then((res) => {
        reset();
        setOpen(false);
        dataDetails.refetch();
        removeHiddenWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPopup
      onClick={() => {
        setOpen(false);
        reset();
      }}
      title={"Create New Admin"}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.create__admin}>
        <Row gutter={[50, 8]}>
          <Col span={13}>
            <div className={classes.flex}>
              <CUpload
                control={control}
                name="image"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Photo"}
                title={"Name Image"}
                type={true}
                error={errors.image}
              />
              <CUpload
                control={control}
                name="document_image"
                required={true}
                showUploadList={false}
                maxCount={1}
                text={"Document"}
                title={"Name Document"}
                type={false}
                error={errors.document_image}
              />
            </div>
            <CInput
              type="text"
              label="Last name"
              name="last_name"
              register={register}
              errors={errors?.last_name}
              required
            />
            <CInput
              type="text"
              label="First name"
              name="first_name"
              register={register}
              errors={errors?.first_name}
              required
            />
            <CInput
              type="text"
              label="Middle name"
              name="middle_name"
              register={register}
              errors={errors?.middle_name}
              required
            />

            <CDatePicker
              label={"Birth date"}
              control={control}
              name="birth_date"
              placeholder=""
              required={true}
              errors={errors.birth_date}
              value={
                watch().birth_date != ""
                  ? moment(watch().birth_date, `YYYY-MM-DD`)
                  : ""
              }
            />
          </Col>
          <Col style={{ paddingTop: "9px" }} span={11}>
            <CSelect
              label="Region/City"
              name="region"
              items={regionsList.data}
              control={control}
              error={errors.region}
              required={watch().admin_type == "federationadmin" ? false : true}
            />
            <div style={{ margin: "30px 0" }}>
              <CSelect
                label={"Admin Type"}
                name="admin_type"
                items={[
                  { province: "Federation Admin", id: "federationadmin" },
                  { province: "Region Admin", id: "regionadmin" },
                  { province: "Club Admin", id: "clubadmin" },
                  { province: "Doctor", id: "doctor" },
                  { province: "Visa manager", id: "visa_manager" },
                  { province: "Trophies manager", id: "trophies_manager" },
                  { province: "Sport Manager", id: "sport_manager" },
                ]}
                control={control}
                error={errors.admin_type}
                required={true}
              />
            </div>
            <CInput
              type="text"
              label="Login"
              name="username"
              register={register}
              errors={errors?.username}
              required
            />
            <CInput
              type="text"
              label="Password"
              name="password"
              register={register}
              errors={errors?.password}
              required
            />
            <CInput
              type="tel"
              label="Contacts"
              name="phone_number"
              register={register}
              errors={errors?.phone_number}
              required
              pattern={"pattern: /^+?[9-9][9-9][8-8][0-9]{9,9}$/"}
              maxLength={13}
              minLength={13}
            />
            <div className={classes.flex}>
              <CButton
                type="button"
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"none"}
                fontSize={"14px"}
                lineHeight={"18px"}
                fontWeight={"600"}
                color={"#0062FF"}
                border={"1px solid #0062FF"}
                padding={"9px 35px"}
                borderRadius={"10px"}
                onClick={() => reset()}
              >
                Clear
              </CButton>
              <CButton
                type="submit"
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 35px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
                disabled={postAdminMutation.isLoading}
              >
                Create
              </CButton>
            </div>
          </Col>
        </Row>
      </form>
    </CPopup>
  );
}

export default CreateAdmin;
