import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetCoachesQuery = ({ enabled, coach }) => {
  return useQuery(
    ["get-coaches-qeury", coach],
    async () => {
      const { data } = await request.get(`/coach-search/`, {
        params: { coach },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
