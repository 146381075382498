import { useQuery } from "react-query";
import { request } from "services/api";

export const useCompetitionRegisterQuery = ({
  enabled,
  competition_id,
  page,
  member_type,
  member,
}) => {
  return useQuery(
    ["competition-register-query", competition_id, page, member_type, member],
    async () => {
      const { data } = await request.get(
        `/memberlist/for/registering/in/competition/`,
        {
          params: { competition_id, page, member_type, member },
        }
      );
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
