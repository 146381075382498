import { useState } from "react";
import { InfoClub, PaginationNumber } from "container";
import classes from "./style.module.css";
import { Table } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import noImage from "assets/images/notfound/nopic.jpg";
import { CDropdown, CLoader, CButton } from "components";
import { usePatchClubMutation, usePostClubMutation } from "hook/mutation";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import {
  useClubForAddQuery,
  useGetCitiesQuery,
  useRegionsQuery,
} from "hook/query";
import { getBase64File } from "utils/general";

function ClubCreate() {
  const whois = localStorage.getItem("isLogin");
  const [page, setPage] = useState(1);
  const [formImg, setFormImg] = useState();

  const [region, setRegion] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMembersId, setSelectedMembersId] = useState([]);

  const postClubMutation = usePostClubMutation();
  const patchClubMutation = usePatchClubMutation();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate();

  const listRegions = useRegionsQuery({
    enabled: true,
  });

  const listMembers = useClubForAddQuery({
    enabled: true,
    page: page,
    region_id: region?.id,
  });

  const listCities = useGetCitiesQuery({
    enabled:
      whois == "federationadmin"
        ? !!watch().region
        : !!JSON.parse(localStorage?.getItem("userInfo")).region_id,
    id:
      whois == "federationadmin"
        ? watch().region
        : JSON.parse(localStorage?.getItem("userInfo")).region_id,
  });

  const hiddenWindow = () => {
    const element = document.getElementById("main");
    element.classList.add("mainHidden");
  };

  const changeSelectedRow = (e) => {
    const findedId = selectedRow.find((item) => item?.id == e);
    if (!!findedId) {
      const deleteState = selectedRow.filter(
        (currentValue) => currentValue.id != e
      );
      setSelectedRow(deleteState);
    } else {
      setSelectedRow((prevArray) => [...prevArray, { id: parseInt(e) }]);
    }
  };

  const changeSelectedMembers = (e) => {
    const findMember = selectedMembers.find((item) => item.id == e.id);
    if (!!findMember) {
      const deleteMember = selectedMembers.filter(
        (member) => member?.id != findMember.id
      );
      setSelectedMembers(deleteMember);
    } else {
      setSelectedMembers((prevArray) => [...prevArray, e]);
    }
  };

  const changeSelectedMembersId = (e) => {
    const findedId = selectedMembersId.find((item) => item == e);
    if (!!findedId) {
      const deleteState = selectedMembersId.filter(
        (currentValue) => currentValue != e
      );
      setSelectedMembersId(deleteState);
    } else {
      setSelectedMembersId((prevArray) => [...prevArray, e]);
    }
  };

  const onSubmit = async (e) => {
    const data = {
      name: e.name,
      province: e.region,
      district: e.city,
      members: selectedRow,
    };

    const formData = new FormData();
    formData.append("logo", formImg);
    formData.append("name", e.name);

    const postClub = postClubMutation.mutateAsync(data);
    postClub
      .then((res) => {
        const patchClub = patchClubMutation.mutateAsync({
          data: formData,
          id: res.data.id,
        });
        patchClub
          .then((resp) => {
            reset();
            navigate("/clubs");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFileChange = (file) => {
    setFormImg(file);
    // getBase64File(file).then((res) => {
    //   setFile(res);
    // });
  };

  const columns = [
    {
      title: "Members",
      dataIndex: "name",
      width: "30%",
      render: (_, record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          <img src={record?.image ? record?.image : noImage} alt="" />
          {record.first_name} {record.last_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
    },
    {
      title: "#id",
      dataIndex: "id",
    },

    {
      title: "role",
      dataIndex: "role",
    },
    {
      title: "status",
      dataIndex: "status",

      render: (status) => (
        <div
          className={status}
          style={{ fontFamily: "Poppins", fontWeight: "500" }}
        >
          {status}
        </div>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "id",
    //   width: "10%",
    //   render: (_, record) => (
    //     <div
    //       onClick={() => {
    //         setOpen(record.role);
    //         dispatch(getMember(record?.id));
    //         hiddenWindow();
    //       }}
    //     >
    //       <IcShape />
    //     </div>
    //   ),
    // },
  ];

  if (listMembers.isLoading) return <CLoader />;

  return (
    <div className={classes.club}>
      <div className={classes.header}>
        <h4>Create Club</h4>
        {whois == "federationadmin" ? (
          <div className={classes.flex}>
            <CDropdown
              text={"Sort by:"}
              value={region?.province}
              setState={setRegion}
              items={listRegions.data}
              placeholder={"region"}
            />
            <CButton
              type="button"
              className={classes.trash}
              onClick={() => setRegion({})}
            >
              <IcTrash />
            </CButton>
          </div>
        ) : null}
      </div>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <InfoClub
          control={control}
          errors={errors}
          register={register}
          regions={listRegions.data}
          watch={watch}
          cities={listCities.data?.districts}
          onChange={(e) => onFileChange(e)}
        />
        <Table
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedMembersId,
            onSelect: (record) => {
              const textNumber = record.id.toString();
              changeSelectedRow(textNumber);
              changeSelectedMembers(record);
              changeSelectedMembersId(parseInt(textNumber));
            },
            hideSelectAll: true,
          }}
          scroll={{ y: 240 }}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={selectedMembers}
          pagination={false}
        />

        <div className={classes.pagination}>
          <p>
            Number of members: <span>{selectedMembers?.length}</span>
          </p>

          <CButton
            background={"#0062FF"}
            color={"#ffffff"}
            padding={"4px 18px"}
            fontSize={"14px"}
            fontWeight={"500"}
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            lineHeight={"24px"}
            borderRadius={"10px"}
            border={"1px solid #ffffff"}
            type={"submit"}
          >
            Create
          </CButton>
        </div>
      </form>
      <div className={classes.form}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedMembersId,
            onSelect: (record) => {
              const textNumber = record.id.toString();
              changeSelectedRow(textNumber);
              changeSelectedMembers(record);
              changeSelectedMembersId(parseInt(textNumber));
            },
            hideSelectAll: true,
          }}
          columns={columns}
          dataSource={listMembers.data?.results}
          pagination={false}
        />
        {listMembers.data?.count ? (
          <PaginationNumber
            count={page}
            counts={listMembers.data.count}
            pages={listMembers.data.total_pages}
            defaultCurrent={page}
            total={
              !!listMembers.data?.total_pages
                ? listMembers.data?.total_pages * 10
                : 10
            }
            showSizeChanger={false}
            onChange={setPage}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ClubCreate;
