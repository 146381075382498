import { useQuery } from "react-query";
import { request } from "services/api";

export const useGetDataClubQuery = ({ enabled, id }) => {
  return useQuery(
    ["get-data-club", id],
    async () => {
      const { data } = await request.get(`/main/member/?club_id=${id}`, {
        // params: { id },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
};
