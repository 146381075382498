import { useEffect, useState } from "react";
import classes from "./style.module.css";
import { message, Pagination, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import noImage from "assets/images/notfound/nopic.jpg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as IcShape } from "assets/images/icons/ic_Shape.svg";
import { createOrder, getMemberForPayment } from "store/slice/payment/fetcher";
import {
  setSelectMember,
  setSelectMembersId,
} from "store/slice/payment/payment";
import { CButton, CLoader } from "components";

function PaymentCreate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const payment = useSelector((state) => state.payment);

  useEffect(() => {
    dispatch(getMemberForPayment(location.search));
  }, [dispatch, location.search]);

  const columns = [
    {
      title: "Selected members",
      dataIndex: "name",
      render: (_, record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          <img src={record?.image ? record?.image : noImage} alt="" />
          {record?.last_name} {record?.first_name}
        </div>
      ),
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birth_date",
    },
    {
      title: "#id",
      dataIndex: "id",
    },
    {
      title: "region",
      dataIndex: "region",
      render: (weight) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>{weight}</div>
      ),
    },
    {
      title: "role",
      dataIndex: "role",
      render: (role) => (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {role}
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status}
          style={{ fontFamily: "Poppins", fontWeight: "500" }}
        >
          {status}
        </div>
      ),
    },
  ];

  const addPageInSearchParam = (e) => {
    const oldParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...oldParams, page: e });
  };

  const submitPayment = () => {
    if (members?.length > 0) {
      dispatch(createOrder(members));
      navigate("/payments", { replace: true });
    } else {
      message.warning({
        content: "No Member in List",
        style: {
          right: "50%",
          marginLeft: "80%",
        },
      });
    }
  };
  if (payment.status == "loading") return <CLoader />;

  return (
    <div className={classes.payment}>
      <div className={classes.top}>
        <h4>Payments</h4>
        <CButton
          type="button"
          fontFamily={"Poppins"}
          fontStyle={"normal"}
          background={"#3DD598"}
          fontSize={"14px"}
          lineHeight={"21px"}
          fontWeight={"500"}
          color={"#fff"}
          border={"1px solid #3DD598"}
          padding={"9px 25px"}
          borderRadius={"10px"}
          margin={"0 0 0 20px"}
          onClick={submitPayment}
        >
          Pay now
        </CButton>
      </div>
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: members.map((item) => item.id),
            onSelect: (record) => {
              setMembers((items) => {
                const exists = items.some((item) => item.id === record.id);
                if (!exists) {
                  return [...items, record];
                }
                return items;
              });
            },
            hideSelectAll: true,
          }}
          columns={columns}
          dataSource={payment.membersPayment?.results}
          pagination={false}
        />
        {payment.membersPayment?.count > 1 ? (
          <div className={classes.pagination_table}>
            <p className="paginationCount">
              1-{searchParams.get("page") ? searchParams.get("page") * 16 : 16}{" "}
              of {payment.membersPayment?.count} items
            </p>
            <Pagination
              defaultCurrent={
                searchParams.get("page") ? searchParams.get("page") : 1
              }
              total={payment.membersPayment?.total_pages * 10}
              showSizeChanger={false}
              onChange={(e) => addPageInSearchParam(e)}
            />
          </div>
        ) : null}
      </div>
      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: members.map((item) => item.id),
            onSelect: (record) => {
              setMembers((items) => {
                const exists = items.some((item) => item.id === record.id);
                if (exists) {
                  return items.filter((item) => item.id !== record.id);
                } else {
                  return [...items, record];
                }
              });
            },

            hideSelectAll: true,
          }}
          columns={columns}
          dataSource={members}
          pagination={false}
        />
        {members?.length ? (
          <div className={classes.info__btn}>
            <div className={classes.flex}>
              <p>
                Number of members:
                <span>{members?.length}</span>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PaymentCreate;
