import classes from "./style.module.css";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import CustomInputField from "components/CInputField/CInputField";
import { setVideo } from "store/slice/competition/competition";
import { useState } from "react";
import { CButton } from "components";

function Videos() {
  const [videoState, setVideoState] = useState([
    {
      link: "",
      competition: "",
    },
  ]);

  const dispatch = useDispatch();

  const handleInputChange = (e, index) => {
    const placeName = "competition";
    const { name, value } = e.target;
    const list = [...videoState];
    list[index][name] = value;
    list[index][placeName] = index + 1;

    setVideoState(list);
    dispatch(setVideo(JSON.stringify(list)));
  };

  const handleRemoveClick = () => {
    const list = [...videoState];
    list.pop();
    setVideoState(list);
    dispatch(setVideo(JSON.stringify(list)));
  };

  const handleAddClick = () => {
    setVideoState([
      ...videoState,
      {
        link: "",
        competition: "",
      },
    ]);

    dispatch(
      setVideo(
        JSON.stringify([
          ...videoState,
          {
            link: "",
            competition: "",
          },
        ])
      )
    );
  };

  return (
    <div className={classes.video}>
      <h5>Links</h5>
      <Row gutter={[32, 8]}>
        {videoState.map((point, index) => {
          <Col key={index} span={8}>
            <CustomInputField
              onChange={(e) => handleInputChange(e, index)}
              type="text"
              label={`Video ${index + 1}`}
              name={"link"}
              value={point.point}
              margin={"0"}
            />
          </Col>;
        })}

        <Col span={5}>
          <Row gutter={[8, 8]} style={{ height: "100%" }}>
            {videoState.length > 1 ? (
              <CButton
                type={"button"}
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#ffffff"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#babaca"}
                border={"1px dashed #babaca"}
                padding={"9px 25px 9px 16px"}
                borderRadius={"10px"}
                onClick={handleRemoveClick}
                margin={"10px 0"}
              >
                -Delete Link
              </CButton>
            ) : null}
            <CButton
              type={"button"}
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#ffffff"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#babaca"}
              border={"1px dashed #babaca"}
              padding={"9px 25px 9px 16px"}
              borderRadius={"10px"}
              onClick={handleAddClick}
              margin={"10px"}
            >
              +Add Link
            </CButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Videos;
