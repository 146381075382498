import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLBASE } from "../../../constants/applicationConstants";

export const getClub = createAsyncThunk('club/getClub', async function ({ id }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/club/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const getClubs = createAsyncThunk('club/getClubs', async function ({ params }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
        const response = await fetch(`${URLBASE}/club${params}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const createClub = createAsyncThunk('club/createClub', async function (resp, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");
    try {
        const response = await fetch(`${URLBASE}/club/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: resp.name,
                province: resp.region,
                district: resp.city,
                members: getState().clubs.selectedRow,
            })
        });

        const res = await response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const patchImgClub = createAsyncThunk('club/patchImgClub', async function (id, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    const formData = new FormData();
    formData.append("logo", getState().clubs?.image);

    try {
        const response = await fetch(`${URLBASE}/club/${id}/`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData,
        });

        const res = await response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const patchClub = createAsyncThunk('club/patchClub', async function (e, { rejectWithValue, getState }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/club/${e.id}/`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: e.name,
                province: typeof (e.region) == "string" ? e.region_id : e.region,
                district: typeof (e.city) == "string" ? e.city_id : e.city,
                members: getState().clubs.selectedRow,
            })
        });

        const res = await response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const getSearchClubs = createAsyncThunk('club/getSearchClubs', async function (title, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/club-search/?club=${title}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const getMemberClub = createAsyncThunk('club/getMemberClub', async function ({ id }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");

    try {
        const response = await fetch(`${URLBASE}/main/member/?club_id=${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
        localStorage.clear();
        }

        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);

export const getMembersAddClub = createAsyncThunk('club/getMembersAddClub', async function ({ params }, { rejectWithValue }) {
    const accessToken = localStorage.getItem("access");
    try {
        const response = await fetch(`${URLBASE}/memberlist/for/adding/club/${params}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const res = response.json()

        if (!response.ok) {
            localStorage.setItem("isLogin", "no access");
            localStorage.clear();
        }
        return res

    } catch (error) {
        return rejectWithValue(error.message)
    }
}
);
