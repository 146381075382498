import { useState } from "react";
import classes from "./style.module.css";
import { Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IcPlus } from "assets/images/icons/ic_Plus.svg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { CButton, CDropdown, CLoader } from "components";
import { useGetOrdersQuery } from "hook/query";
import { PaginationNumber } from "container";

function PaymentsPage() {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState({});
  const navigate = useNavigate();
  const listOrders = useGetOrdersQuery({
    enabled: true,
    page: page,
    status: status?.id,
  });

  const SubStr = (e) => {
    const newStr = e?.substr(0, 10);
    return newStr;
  };

  const columns = [
    {
      title: "#id",
      dataIndex: "id",
      render: (id) => <div style={{ color: "#171725" }}>{id}</div>,
    },
    {
      title: "created at",
      dataIndex: "created_at",
      render: (record) => <div>{SubStr(record)}</div>,
    },

    {
      title: "Type",
      dataIndex: "type",
      render: (record) => (
        <div style={{ fontFamily: "Poppins", fontWeight: "500" }}>
          {record ? record : "-"}
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "members",
      dataIndex: "count_member",
    },
    {
      title: "",
      dataIndex: "status",
      render: (status) => (
        <div
          className={status == "paid" ? classes.status : classes.pending}
          style={{ fontFamily: "Poppins", fontWeight: "500" }}
        >
          {status}
        </div>
      ),
    },
  ];

  if (listOrders.isLoading) return <CLoader />;

  return (
    <div className={classes.payment}>
      <div className={classes.header}>
        <h4>Payments</h4>
        <div className={classes.flex}>
          <CDropdown
            text={"Sort by:"}
            value={status?.province}
            setState={(e) => {
              setStatus(e);
              setPage(1);
            }}
            items={[
              { province: "pending", id: "pending" },
              { province: "paid", id: "paid" },
            ]}
            margin={"0 0 0 20px"}
            placeholder={"status"}
          />
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={() => setStatus({})}
          >
            <IcTrash />
          </CButton>
          <Link to="create">
            <CButton
              className={classes.create__btn}
              fontFamily={"Poppins"}
              fontStyle={"normal"}
              background={"#0062FF"}
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={"500"}
              color={"#fff"}
              border={"1px solid #0062FF"}
              padding={"9px 25px 9px 16px"}
              borderRadius={"10px"}
              margin={"0 0 0 20px"}
            >
              <IcPlus fill="#fff" />
              New Order
            </CButton>
          </Link>
        </div>
      </div>

      <div className={classes.table}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
          }}
          columns={columns}
          dataSource={listOrders.data?.results}
          rowClassName={"row"}
          onRow={({ id }) => {
            return {
              onClick: (event) => navigate(`/payments/${id}`), // click row
            };
          }}
          pagination={false}
        />
        {listOrders.data?.results?.length > 0 ? (
          <PaginationNumber
            count={page}
            counts={listOrders.data?.count}
            pages={listOrders.data?.total_pages}
            defaultCurrent={page}
            total={listOrders.data?.total_pages * 10}
            showSizeChanger={false}
            onChange={setPage}
          />
        ) : null}
      </div>
    </div>
  );
}

export default PaymentsPage;
