import { useState } from "react";
import classes from "./style.module.css";
import { Link } from "react-router-dom";
import { ReactComponent as IcPlus } from "assets/images/icons/ic_Plus.svg";
import { ReactComponent as IcTrash } from "assets/images/icons/ic_Trash.svg";
import { ReactComponent as IcClose } from "assets/images/icons/ic_Close.svg";
import { ReactComponent as IcStarred } from "assets/images/icons/ic_Starred.svg";
import { ReactComponent as IcLatest } from "assets/images/icons/ic_Latest Activity.svg";
import { ThunderboltOutlined } from "@ant-design/icons";
import { CButton, CDropdown, CLoader } from "components";
import { useCompetitionsQuery, useRegionsQuery } from "hook/query";
import { CompetitionsTable, PaginationNumber } from "container";
import { useDeleteCompetitionsMutation } from "hook/mutation/Competition/useDeleteCompetitionsMutation";
import toast from "react-hot-toast";

function CompetitionsPage() {
  const [region, setRegion] = useState({});
  const [year, setYear] = useState({});
  const [action, setAction] = useState("upcoming");
  const [page, setPage] = useState(1);
  const [ids, setIds] = useState([]);

  const { mutateAsync } = useDeleteCompetitionsMutation();
  const whois = localStorage.getItem("isLogin") === "federationadmin";

  const competitionsList = useCompetitionsQuery({
    region_id: region?.id,
    page: page,
    year: year?.id,
    action: action,
  });

  const regionsList = useRegionsQuery({});

  const clearSearchParams = () => {
    setYear({});
    setRegion({});
    setIds([]);
  };

  function deleteCompetitions() {
    const mutation = mutateAsync({ ids });
    toast
      .promise(mutation, {
        loading: "Loading...",
        success: "Successfully deleted",
        error: (err) => err?.response?.data?.message || "Something went wrong",
      })
      .then((res) => {
        setIds([]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (competitionsList.isLoading) return <CLoader />;

  return (
    <div className={classes.competition}>
      <div className={classes.top}>
        <h4>Competitions</h4>
        <div className={classes.flex}>
          {whois ? (
            <CDropdown
              text={"Sort by:"}
              value={region?.province}
              setState={setRegion}
              items={regionsList.data}
              margin={"0 0 0 20px"}
              placeholder={"region"}
            />
          ) : null}

          <CDropdown
            text={"Sort by:"}
            value={year?.province}
            setState={setYear}
            items={[
              { province: "2021", id: 2021 },
              { province: "2022", id: 2022 },
              { province: "2023", id: 2023 },
              { province: "2024", id: 2024 },
            ]}
            margin={"0 0 0 20px"}
            placeholder={"year"}
          />
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            background={"#ffffff"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={clearSearchParams}
          >
            <IcClose />
          </CButton>
          <CButton
            fontFamily={"Poppins"}
            fontStyle={"normal"}
            className={ids.length > 0 ? classes.btnRed : classes.btnWhite}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"500"}
            color={"#fff"}
            border={"none"}
            padding={"4px 9px 0px 10px"}
            borderRadius={"10px"}
            margin={"0 0 0 20px"}
            onClick={deleteCompetitions}
            disabled={!ids.length}
          >
            <IcTrash className={ids.length > 0 ? classes.svg1 : null} />
          </CButton>
          {whois ? (
            <Link to="create">
              <CButton
                className={classes.btn}
                fontFamily={"Poppins"}
                fontStyle={"normal"}
                background={"#0062FF"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={"500"}
                color={"#fff"}
                border={"1px solid #0062FF"}
                padding={"9px 25px 9px 16px"}
                borderRadius={"10px"}
                margin={"0 0 0 20px"}
              >
                <IcPlus />
                New Competition
              </CButton>
            </Link>
          ) : null}
        </div>
      </div>
      <div className={classes.btns}>
        <CButton
          fontFamily={"Poppins"}
          fontStyle={"normal"}
          color={action == "upcoming" ? "#fff" : "#696974"}
          fontSize={"14px"}
          lineHeight={"21px"}
          fontWeight={"500"}
          background={action == "upcoming" ? "#0062FF" : "#ffffff"}
          border={
            action == "upcoming" ? "1px solid #0062FF" : "1px solid #696974"
          }
          padding={"9px 18px"}
          borderRadius={"10px"}
          onClick={() => setAction("upcoming")}
        >
          <IcStarred
            className={
              action == "upcoming"
                ? `${classes.iconWhite}`
                : `${classes.iconGrey}`
            }
          />
          Upcoming competitions
        </CButton>

        <CButton
          fontFamily={"Poppins"}
          fontStyle={"normal"}
          background={action == "history" ? "#0062FF" : "#ffffff"}
          fontSize={"14px"}
          lineHeight={"21px"}
          fontWeight={"500"}
          color={action == "history" ? "#fff" : "#696974"}
          border={
            action == "history" ? "1px solid #0062FF" : "1px solid #696974"
          }
          padding={"9px 20px 9px 11px"}
          borderRadius={"10px"}
          margin={"0 0 0 20px"}
          className={classes.historyBtn}
          onClick={() => setAction("history")}
        >
          <IcLatest
            className={
              action == "history"
                ? `${classes.iconWhite}`
                : `${classes.iconGrey}`
            }
          />
          History
        </CButton>
        <CButton
          fontFamily={"Poppins"}
          fontStyle={"normal"}
          background={action == "live" ? "#0062FF" : "#ffffff"}
          fontSize={"14px"}
          lineHeight={"21px"}
          fontWeight={"500"}
          color={action == "live" ? "#fff" : "#696974"}
          border={action == "live" ? "1px solid #0062FF" : "1px solid #696974"}
          padding={"9px 20px 9px 11px"}
          borderRadius={"10px"}
          margin={"0 0 0 20px"}
          className={classes.historyBtn}
          onClick={() => setAction("live")}
        >
          <ThunderboltOutlined
            style={{ fontSize: "20px", marginBottom: "0" }}
          />
          Live now
        </CButton>
      </div>
      {competitionsList.data?.count > 0 ? (
        <div className={classes.competitions__list}>
          <CompetitionsTable
            text={action == "upcoming" ? "Register" : null}
            data={competitionsList.data?.results}
            setIds={setIds}
            ids={ids}
          />

          <PaginationNumber
            count={page}
            counts={competitionsList.data?.count}
            pages={competitionsList.data?.total_pages}
            defaultCurrent={page}
            total={competitionsList.data?.total_pages * 10}
            showSizeChanger={false}
            onChange={(e) => setPage(e)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CompetitionsPage;
